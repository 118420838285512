import { selector } from 'recoil';

import {
  type CompaniesKPIs,
  fetchCompaniesKPIs,
} from '@/api/v4/organization.api';

import { prepareCompanyApiFilters } from './filters/company-filters-helpers';
import { appliedCompaniesFiltersState } from './companies.state';

export const companiesKPIsState = selector<CompaniesKPIs>({
  key: 'companiesKPIsState',
  get: async ({ get }) => {
    const appliedFilters = get(appliedCompaniesFiltersState);

    return fetchCompaniesKPIs({
      ...prepareCompanyApiFilters(appliedFilters),
    });
  },
});
