import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import { deleteCompanies } from '@/api/v4/organization.api';
import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { companiesKPIsState } from '../companies/companies-kpi.state';
import { useCompanies } from '../companies/hooks/use-companies.query';

import { ConfirmDeleteModal } from './confirm-delete-modal';

export const DeleteCompanyModal = () => {
  const modal = useRecoilValue(modalState);
  const { t } = useTranslation('companies');
  const navigateTo = useNavigateTo();
  const refreshKPI = useRecoilRefresher_UNSTABLE(companiesKPIsState);
  const { refetch: refreshCompanies } = useCompanies();

  if (!modal || modal.state !== 'deleteCompany') return null;

  const handleDeleteCompanies = async () => {
    const result = await deleteCompanies({
      organizationIds: [modal?.company.id],
    });

    if (result.unableToDelete.length > 0) {
      notify({
        message: t`deleteCompanyModal.error`,
      });
    }

    if (result.deleted > 0) {
      refreshKPI();
      await refreshCompanies();
      navigateTo.companies();
    }
  };

  return (
    <Modal
      title={t`deleteCompanyModal.title`}
      description={t`deleteCompanyModal.description`}
      confirmLabel={t`deleteCompanyModal.confirm`}
      onConfirm={handleDeleteCompanies}
    />
  );
};

const Modal = styled(ConfirmDeleteModal)`
  max-width: 1024px;
`;
