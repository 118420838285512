import { useQuery } from '@tanstack/react-query';

import { fetchUserRecentSearchRecords } from '@/api/v4/search.api';
import { QueryKey } from '@/config/query-client';
import { useCurrentUser } from '@/user/use-current-user.query';

export const useRecentSearchRecords = () => {
  const { data: user } = useCurrentUser();

  const { data, isLoading } = useQuery(
    [QueryKey.UserRecentSearchRecords, user?.id],
    async () => await fetchUserRecentSearchRecords(),
    {
      enabled: !!user?.id,
      staleTime: 1000 * 60 * 60 * 24 * 60, // 60 days
      cacheTime: 1000 * 60 * 60 * 24 * 60, // 60 days
    },
  );
  const hasUserSomeRecentSearchRecords = !isLoading && !!data?.length;

  return {
    data,
    isLoading,
    hasUserSomeRecentSearchRecords,
  };
};
