import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import type { ListingStage } from '@/api/v4/project-listings.api';
import { updateProjectListingStage } from '@/api/v4/project-listings.api';
import type { ProjectListing } from '@/api/v4/projects.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { getListingStages } from '@/features/listing-stage/helpers/get-listing-stages';

import { notify } from '../../../ui/snackbar/notify';
import { getListingStageById } from '../../listing-stage/helpers/get-listing-stage-by-id';
import { isDealStage } from '../../listing-stage/types';
import { projectListingSortState } from '../project-listing.state';
import { projectIdState } from '../project.state';

import { useInvalidateProjectListings } from './use-invalidate-project-listings';

interface UpdateProjectListingsParams {
  projectListingId: string;
  newProjectListingStageId: string;
  onSuccess?: () => void;
  withNotification?: boolean;
}

export const useUpdateProjectListingStage = () => {
  const { t } = useTranslation('projects');
  const sort = useRecoilValue(projectListingSortState);
  const projectId = useRecoilValue(projectIdState);
  const invalidateProjectListings = useInvalidateProjectListings();

  const getProjectListingById = (
    listingId: string,
    projectListings: ProjectListing[],
  ) => {
    const listing = projectListings.find(listing => listing.id === listingId);
    if (!listing) {
      throw new Error(`Unknown project listing: ${listingId}`);
    }
    return listing;
  };

  const mutation = useMutation(
    async ({
      projectListingId,
      newProjectListingStageId,
    }: Omit<UpdateProjectListingsParams, 'onSuccess'>) =>
      await updateProjectListingStage(
        projectListingId,
        newProjectListingStageId,
      ),
  );

  const invalidateRelevantQueries = async (listing: ProjectListing) => {
    await invalidateProjectListings();
    await queryClient.invalidateQueries([QueryKey.Snapshot]);
    await queryClient.invalidateQueries([QueryKey.Funnel]);
    await queryClient.invalidateQueries([QueryKey.Projects], {
      refetchType: 'none',
    });
    await queryClient.invalidateQueries([QueryKey.ProjectOptions]);
    await queryClient.invalidateQueries([
      QueryKey.CompanyProjectsListings,
      listing.organization.id,
    ]);
  };

  const getListingData = () => {
    return queryClient.getQueryData<ProjectListing[]>([
      QueryKey.ProjectListings,
      projectId,
      sort,
    ]);
  };

  const notifyAboutChangedStage = (
    listing: ProjectListing,
    newStage: ListingStage,
  ) => {
    const companyName = listing?.organization.name ?? '';
    const newStageName = isDealStage(newStage.name)
      ? t`deal`
      : newStage.displayName ?? newStage.name;
    notify({
      message: t('listings.movedToStage', {
        companyName: companyName,
        statusName: newStageName,
      }),
    });
  };

  const editProjectListingStage = async ({
    projectListingId,
    newProjectListingStageId,
    onSuccess,
    withNotification = true,
  }: UpdateProjectListingsParams) =>
    await mutation.mutateAsync(
      {
        projectListingId,
        newProjectListingStageId,
      },
      {
        onSuccess: async (_data, variables) => {
          const { projectListingId, newProjectListingStageId } = variables;
          const projectListings = getListingData();
          const listing = getProjectListingById(
            projectListingId,
            projectListings ?? [],
          );
          const listingStages = getListingStages();
          const newStage = getListingStageById(
            newProjectListingStageId,
            listingStages,
          );

          await invalidateRelevantQueries(listing);

          if (withNotification) {
            notifyAboutChangedStage(listing, newStage);
          }

          onSuccess?.();
        },
      },
    );

  return editProjectListingStage;
};
