import type { RefObject } from 'react';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import type { Meeting } from '@/api/v4/meetings.api';
import { Loader } from '@/components/loader/loader';
import { CALENDAR_DATE_FORMAT } from '@/constants';
import { MeetingForm } from '@/features/action-panel/meetings/form/meeting-form';
import { formatDate } from '@/helpers/format-date';
import { APP_USER_ROLES } from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { useUserRole } from '@/user/use-user-role';

import type { MeetingFormFields } from './hooks/use-meeting-form';
import { useMeetingForm } from './hooks/use-meeting-form';
import { EmptyState } from './empty-state';
import { MeetingCollapsibleItem } from './meeting-collapsible-item';
import {
  activeMeetingFormState,
  scrollToMeetingIdState,
} from './meetings.state';

const LOG_MEETING_BUTTON_HEIGHT = '65px';

export type MeetingTabContext = 'project' | 'team' | 'company';

export interface MeetingsTabProps {
  meetingsData: Meeting[];
  newMeetingFormDefaultValues?: Pick<
    MeetingFormFields,
    'relatedCompanyId' | 'relatedProjectId' | 'relatedTeamId'
  >;
  isLoading: boolean;
  deleteMeetingHandler: (meetingId: string) => void;
  refreshHandler: () => void;
  withCompanyField?: boolean;
  withProjectField?: boolean;
  withTeamField?: boolean;
  showReadOnlyTeam?: boolean;
  context: MeetingTabContext;
  isCompanyRequired?: boolean;
}

type MeetingRefs = Record<string, RefObject<HTMLDivElement>>;

export const MeetingsTab = ({
  meetingsData,
  newMeetingFormDefaultValues,
  deleteMeetingHandler,
  refreshHandler,
  isLoading,
  withProjectField,
  withCompanyField,
  withTeamField,
  showReadOnlyTeam,
  context,
  isCompanyRequired,
}: MeetingsTabProps) => {
  const { t } = useTranslation('default', { keyPrefix: 'actionPanel' });
  const { methods, handleCreateMeetingFormSubmit } = useMeetingForm({
    defaultValues: {
      title: '',
      date: formatDate(new Date(), {
        format: CALENDAR_DATE_FORMAT,
      }),
      relatedCompanyId: undefined,
      relatedProjectId: undefined,
      relatedTeamId: undefined,
      attendees: [],
      summary: '',
      mentionedUsers: [],
      ...newMeetingFormDefaultValues,
    },
    isCompanyRequired,
  });
  const [isNewMeetingFormVisible, setIsNewMeetingFormVisible] = useState(false);
  const [activeMeetingForm, setActiveMeetingForm] = useRecoilState(
    activeMeetingFormState,
  );
  const [scrollToMeetingId, setScrollToMeetingId] = useRecoilState(
    scrollToMeetingIdState,
  );
  const role = useUserRole();

  const refs = useMemo(() => {
    const initialRefs: MeetingRefs = {};

    meetingsData.forEach(value => {
      initialRefs[value.id] = createRef();
    });

    return initialRefs;
  }, [meetingsData]);

  const scrollMeetingIntoView = useCallback(
    (meetingId: string) => {
      const ref = refs[meetingId]?.current;

      if (!ref) {
        return;
      }

      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
    [refs],
  );

  useEffect(() => {
    if (methods.formState.isSubmitted) {
      setIsNewMeetingFormVisible(false);
      methods.reset();
      refreshHandler();
      setActiveMeetingForm({
        ...activeMeetingForm,
        shouldScrollToSubmitted: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, methods.formState.isSubmitted, refreshHandler]);

  useEffect(() => {
    if (activeMeetingForm.shouldDiscard) {
      setIsNewMeetingFormVisible(false);
      setActiveMeetingForm({ ...activeMeetingForm, shouldDiscard: false });
    }

    if (activeMeetingForm.shouldScrollToSubmitted && activeMeetingForm.id) {
      setScrollToMeetingId(activeMeetingForm.id);
    }
  }, [activeMeetingForm, setActiveMeetingForm, setScrollToMeetingId]);

  useEffect(() => {
    if (!scrollToMeetingId || isLoading) {
      return;
    }

    scrollMeetingIntoView(scrollToMeetingId);

    setActiveMeetingForm({
      ...activeMeetingForm,
      id: null,
      shouldScrollToSubmitted: false,
    });

    setScrollToMeetingId(activeMeetingForm.id);

    setScrollToMeetingId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, scrollToMeetingId, isLoading]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (!meetingsData.length && !isNewMeetingFormVisible) {
    return (
      <EmptyState
        context={context}
        onButtonClick={() => setIsNewMeetingFormVisible(true)}
        isAddMeetingButtonShown={role !== APP_USER_ROLES.GUEST}
      />
    );
  }

  return (
    <MeetingsTabContent fullWidth gap="0">
      {role !== APP_USER_ROLES.GUEST && (
        <Flexbox
          name="log-meeting-button-container"
          padding="12px 30px"
          justify="flex-end"
        >
          <Button
            variant="ghost"
            startIcon="Plus"
            onClick={() => setIsNewMeetingFormVisible(true)}
          >
            {t`meetings.logMeeting`}
          </Button>
        </Flexbox>
      )}

      <Container fullWidth gap="0">
        {isNewMeetingFormVisible && (
          <FormContainer animationOffset={LOG_MEETING_BUTTON_HEIGHT}>
            <FormProvider {...methods}>
              <MeetingForm
                onCancel={() => setIsNewMeetingFormVisible(false)}
                onSubmit={handleCreateMeetingFormSubmit}
                withProjectField={withProjectField}
                withTeamField={withTeamField}
                withCompanyField={withCompanyField}
                submitButtonLabel={t`meetings.log`}
                isCompanyRequired={isCompanyRequired}
                context={context}
              />
            </FormProvider>
          </FormContainer>
        )}

        <ListContainer fullWidth gap="0px">
          {meetingsData.map(meeting => (
            <MeetingCollapsibleItem
              ref={refs[meeting.id]}
              isInitiallyOpen={scrollToMeetingId === meeting.id}
              key={meeting.id}
              meeting={meeting}
              withProjectField={withProjectField}
              withTeamField={withTeamField}
              showReadOnlyTeam={showReadOnlyTeam}
              withCompanyField={withCompanyField}
              deleteHandler={deleteMeetingHandler}
              refreshHandler={refreshHandler}
              isMenuShown={role !== APP_USER_ROLES.GUEST}
              isCompanyRequired={isCompanyRequired}
              context={context}
            />
          ))}
        </ListContainer>
      </Container>
    </MeetingsTabContent>
  );
};

const MeetingsTabContent = styled(Stack)<{ padding?: string }>`
  ${({ padding }) => padding && `padding: ${padding}`};
`;

const Container = styled(Stack)<{ padding?: string }>`
  padding: ${({ padding }) => padding ?? '0 12px'};
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const FormContainer = styled(Stack)<{ animationOffset?: string }>`
  padding: 36px 40px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.c3};
  ${({ theme, animationOffset }) =>
    theme.animations.slideInVertically('top', animationOffset)};
`;

const LoaderContainer = styled('div')`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.basics.white};
  padding-top: 30px;
`;

const ListContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;
