import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';

import { ConfirmDeleteModal } from '../confirm-delete-modal';

import { useDeleteCompanyFile } from './use-delete-company-file';

export const DeleteCompanyFileModal = () => {
  const modal = useRecoilValue(modalState);
  const { deleteCompanyFile } = useDeleteCompanyFile();

  if (!modal || modal.state !== 'deleteCompanyFile') return null;

  return (
    <ConfirmDeleteModal
      onConfirm={deleteCompanyFile.bind(null, modal.file.id)}
    />
  );
};
