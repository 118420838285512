import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { useBoolean } from '@/hooks/use-boolean';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';
import { Modal } from '@/ui/modal/modal';

import { useDeleteProject } from './use-delete-project';

export const DeleteProjectModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const navigateTo = useNavigateTo();

  const { t } = useTranslation('default');
  const { deleteProject } = useDeleteProject();
  const [inProgress, setInProgress] = useBoolean(false);

  if (!modal || modal.state !== 'deleteProject') return null;

  const close = () => {
    setModal(null);
  };

  const handleDeleteProject = async (projectId: string) => {
    setInProgress.on();
    await deleteProject(projectId);
    setInProgress.off();
    close();
    navigateTo.projects();
  };

  return (
    <Modal
      header={t`areYouSure`}
      onClose={close}
      mainAction={{
        label: t`delete`,
        onClick: handleDeleteProject.bind(null, modal.projectId),
      }}
      secondaryAction={{
        label: t`cancel`,
        onClick: close,
      }}
      variant="danger"
      loading={inProgress}
    >
      <Container>
        <Description>{t`cannotUndo`}</Description>
      </Container>
    </Modal>
  );
};

const Container = styled('div')`
  height: 100px;
`;

const Description = styled('p')`
  ${({ theme }) => theme.typography.acrossPlatform.small};
  color: ${({ theme }) => theme.colors.gray.c12};
`;
