import { ErrorBoundary } from 'react-error-boundary';
import { PageNotFound } from './page-not-found';
import { Loader } from '@/components/loader/loader';
import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { HtmlConfigProvider } from '@/html-config/html-config-provider';
import { ProtectedAppLayout } from './protect/protected-app-layout';
import { useResetRecoilState } from 'recoil';
import { projectIdState } from '@/features/projects/project.state';
import { teamIdState } from '@/features/teams/team.state';
import { modalState } from '@/state/modal.state';
import { actionPanelState } from '@/features/action-panel/action-panel.state';
import { AuthPageLayout } from '@/auth/ui/auth-page-layout';
import { useAuthorization } from '@/auth/hooks/use-authorization';
import protect from './protect/protect';
import { PERMISSION } from '@/user/permissions/permission.type';
import { PRODUCT } from '@/user/products/product.type';

const HomePage = lazy(() =>
  import('../features/home/home.page').then(module => ({
    default: module.HomePage,
  })),
);

const Companies = lazy(() =>
  import('./companies').then(module => ({
    default: module.Companies,
  })),
);

const Projects = lazy(() =>
  import('./projects').then(module => ({
    default: module.Projects,
  })),
);

const Teams = lazy(() =>
  import('./teams').then(module => ({ default: module.Teams })),
);

const ExploreResultsPage = lazy(() =>
  import('../features/explore/explore-results.page').then(module => ({
    default: module.ExploreResultsPage,
  })),
);

const ExploreFormPage = lazy(() =>
  import('../features/explore/explore-form.page').then(module => ({
    default: module.ExploreFormPage,
  })),
);

const SettingsPage = lazy(() =>
  import('../features/settings/settings.page').then(module => ({
    default: module.SettingsPage,
  })),
);

const SignInPage = lazy(() =>
  import('../auth/sign-in.page').then(module => ({
    default: module.SignInPage,
  })),
);
const SignUpPage = lazy(() =>
  import('../auth/sign-up.page').then(module => ({
    default: module.SignUpPage,
  })),
);
const SignUpInvitationExpiredPage = lazy(() =>
  import('../auth/sign-up-invitation-expired.page').then(module => ({
    default: module.SignUpInvitationExpiredPage,
  })),
);

export const AppRouter = () => {
  const { isUserAuthorized } = useAuthorization();
  const resetProjectId = useResetRecoilState(projectIdState);
  const resetTeamId = useResetRecoilState(teamIdState);
  const resetModal = useResetRecoilState(modalState);
  const resetActionSidebar = useResetRecoilState(actionPanelState);

  useEffect(() => {
    return () => {
      resetProjectId();
      resetTeamId();
      resetModal();
      resetActionSidebar();
    };
  }, [resetProjectId, resetTeamId, resetModal, resetActionSidebar]);

  return (
    <HtmlConfigProvider>
      <ErrorBoundary FallbackComponent={PageNotFound}>
        <Suspense fallback={<Loader isFullPage />}>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedAppLayout isUserAuthorized={isUserAuthorized} />
              }
            >
              <Route index element={<Navigate to="home" />} />
              <Route path="home" element={<HomePage />} />
              <Route path="companies/*" element={<Companies />} />
              <Route path="projects/*" element={<Projects />} />
              <Route path="teams/*" element={<Teams />} />
              <Route
                path="explore/results/:requestId?"
                element={protect(<ExploreResultsPage />, {
                  products: [PRODUCT.EXPLORE],
                })}
              />
              <Route path="settings" element={<SettingsPage />} />
            </Route>

            <Route
              element={
                <ProtectedAppLayout
                  withTopbar={false}
                  isUserAuthorized={isUserAuthorized}
                />
              }
            >
              <Route
                path="explore"
                element={protect(
                  <ExploreFormPage />,
                  {
                    permissions: [PERMISSION.ADD_COMPANY_TO_STREAM],
                    products: [PRODUCT.CRUNCHBASE, PRODUCT.EXPLORE],
                  },
                  true,
                )}
              />
            </Route>

            <Route
              path="auth"
              element={<AuthPageLayout isUserAuthorized={isUserAuthorized} />}
            >
              <Route path="signin" element={<SignInPage />} />
              <Route path="signup" element={<SignUpPage />} />
              <Route
                path="invitation-expired"
                element={<SignUpInvitationExpiredPage />}
              />
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </HtmlConfigProvider>
  );
};
