export const COMPANY_MAIN_CONTENT_WIDTH = 1235;
export const GRID_GAP = 30;

export const COLUMN_1_WIDTH = 274;
export const COLUMN_WIDTH = 439;

export const WIDGET_ROW_1_HEIGHT = 360;
export const WIDGET_ROW_2_HEIGHT = 457;
export const WIDGET_ROW_4_HEIGHT = 438;
export const WIDGET_ROW_4_EMPTY_STATE_HEIGHT = 293;
export const WIDGET_HEADER_MARGIN = '0 0 24px 0';
export const WIDGET_BOTTOM_CONTENT_PADDING = '10px 0 0 0';
export const COMPANY_NAME_HEIGHT = '60px';

export const WIDGET_INVESTORS_HEIGHT = 457;
export const WIDGET_INVESTORS_ITEMS_LIMIT = 7;

export const WIDGET_CLIENTS_HEIGHT = 457;
export const WIDGET_CLIENTS_ITEMS_LIMIT = 6;
