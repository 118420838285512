import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { ProjectOnList } from '@/api/v4/projects.api';
import { fetchProjectListings } from '@/api/v4/projects.api';
import { ListStatusTag } from '@/components/list-status-tag/list-status-tag';
import { QueryKey } from '@/config/query-client';
import { selectedCompaniesState } from '@/features/companies/companies.state';
import {
  addCompanyInProgressState,
  companyIdState,
} from '@/features/companies/overview/company.state';
import { useCreateCompany } from '@/hooks/queries/use-create-company.mutation';
import { paths } from '@/routes/helpers/paths';
import { notify } from '@/ui/snackbar/notify';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import {
  TablePrimaryCell,
  TableSecondaryCell,
} from '@/ui/table/infinite-table/table-cells';
import type { IRow } from '@/ui/table/table.types';

import { useCreateProjectListings } from '../project-listings/use-create-project-listings.mutation';
import { useListingsSectionSearchParams } from '../project-listings/use-listings-section-search-params';

import { projectsHeaders } from './projects-table-configuration';

export const useProjectsForCompanyTableConfiguration = (
  onSelect: (projectName: ProjectOnList['name'], successCount: number) => void,
  onLinkClick: () => void,
) => {
  const { t } = useTranslation('projects');
  const queryClient = useQueryClient();

  const { createCompanyFromCrunchbase } = useCreateCompany();
  const setIsAddingCompanyToProjectInProgress = useSetRecoilState(
    addCompanyInProgressState,
  );
  const header = useTranslateHeaders(projectsHeaders, 'projects', 'projects');
  const selectedCompanies = useRecoilValue(selectedCompaniesState);
  const companyId = useRecoilValue(companyIdState);
  const { createProjectListing } = useCreateProjectListings();
  const listingsSectionSearchParams = useListingsSectionSearchParams();

  const getAlreadyAdded = useCallback(
    async (projectId: string) => {
      const projectListings = await fetchProjectListings(projectId);
      return selectedCompanies.filter(selected =>
        projectListings.some(
          item => item.organization.id === selected.organizationId,
        ),
      ).length;
    },
    [selectedCompanies],
  );

  const addCompanyToProjects = useCallback(
    async (project: ProjectOnList) => {
      try {
        setIsAddingCompanyToProjectInProgress(true);

        if (!selectedCompanies.length) return;

        const alreadyAddedCount = await getAlreadyAdded(project.id);

        const existingSavvyCompanies = selectedCompanies
          .filter(company => company.organizationId)
          .map(company => company.organizationId as string);

        // create new savvy companies first
        const newCrunchbaseCompanies = selectedCompanies
          .filter(company => company.crunchbaseId && !company.organizationId)
          .map(company => company.crunchbaseId as string);
        if (newCrunchbaseCompanies.length) {
          const result = await Promise.all(
            newCrunchbaseCompanies.map(item =>
              createCompanyFromCrunchbase({ cbId: item, source: 'SOSA Q' }),
            ),
          );

          const newSavvyIds = result.map(item => item.id);
          existingSavvyCompanies.push(...newSavvyIds);
        }

        const result = await createProjectListing(project.id, {
          companyIds: existingSavvyCompanies,
        });
        await queryClient.invalidateQueries([QueryKey.Company, companyId]);
        const failed = result.filter(item => item.error);
        if (failed.length)
          console.warn('Some companies were not added', failed);
        onSelect(project.name, result.length - failed.length);

        if (alreadyAddedCount > 0) {
          notify({
            message: t('companies:companies.alreadyAddedToProject', {
              count: alreadyAddedCount,
            }),
          });
        }
      } finally {
        setIsAddingCompanyToProjectInProgress(false);
      }
    },
    [
      setIsAddingCompanyToProjectInProgress,
      selectedCompanies,
      getAlreadyAdded,
      createProjectListing,
      queryClient,
      companyId,
      onSelect,
      createCompanyFromCrunchbase,
      t,
    ],
  );

  const handleAddProject = useCallback(
    async (project: ProjectOnList) => {
      await addCompanyToProjects(project);
    },
    [addCompanyToProjects],
  );

  const mapProjectToRow = useCallback(
    (project: ProjectOnList): IRow => {
      return {
        id: project.id,
        rowHover: true,
        onClick: async event => {
          const target = event.target as HTMLTableRowElement;
          if (target.tagName.toLowerCase() === 'a') return;
          await handleAddProject(project);
        },
        cells: [
          {
            width: '450px',
            value: (
              <>
                <TablePrimaryCell maxWidth={460}>
                  <EllipsisTextTooltip
                    onClick={onLinkClick}
                    text={project.name}
                    Component={
                      <Link
                        target="_blank"
                        to={paths.project({ projectId: project.id })}
                      />
                    }
                  />
                </TablePrimaryCell>
                {project.listingCount > 0 ? (
                  <TableSecondaryCell>
                    <Link
                      to={{
                        pathname: paths.project({ projectId: project.id }),
                        search: listingsSectionSearchParams,
                      }}
                      onClick={onLinkClick}
                    >
                      {t('projects.company', {
                        count: project.listingCount,
                      })}
                    </Link>
                  </TableSecondaryCell>
                ) : (
                  <TableSecondaryCell>-</TableSecondaryCell>
                )}
              </>
            ),
          },
          {
            padding: '0',
            value: project.mostAdvancedListingStatus ? (
              <ListStatusTag text={project.mostAdvancedListingStatus} />
            ) : (
              <TableSecondaryCell>-</TableSecondaryCell>
            ),
          },
          {
            width: '200px',
            value: (
              <TableSecondaryCell maxWidth={160}>
                {project.createdForTeam?.id && (
                  <EllipsisTextTooltip
                    text={t('companies:companies.forTeam', {
                      teamName: project.createdForTeam.name,
                    })}
                    Component={
                      <Link
                        to={paths.team({ teamId: project.createdForTeam.id })}
                        target="_blank"
                      />
                    }
                  />
                )}
              </TableSecondaryCell>
            ),
          },
        ],
      };
    },
    [handleAddProject, listingsSectionSearchParams, onLinkClick, t],
  );

  return {
    mapProjectToRow,
    header,
  };
};
