import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import type { RecoilValue } from 'recoil';

import { api } from '@/api';
import { Loader } from '@/components/loader/loader';
import {
  isDefaultOptionArray,
  isPersonOption,
  isTeamOption,
} from '@/helpers/other';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { useLoadableData } from '@/hooks/use-loadable-results';
import { EMAIL_REGEX } from '@/regexes';
import type { TeamOption, TeamOptionV2 } from '@/types';
import { APP_USER_ROLES, type IndexParams, type PersonOption } from '@/types';
import type { AsyncSelectProps } from '@/ui/select/async/async-select-with-list';
import { AsyncSelectWithList } from '@/ui/select/async/async-select-with-list';
import type { InviteOption } from '@/ui/select/async/components/option/option-users-teams';
import { AsyncSelectOptionUsersTeams } from '@/ui/select/async/components/option/option-users-teams';
import { SelectedOptionsListUsersTeams } from '@/ui/select/async/components/selected-options-list/selected-options-list-users-teams';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';
import { useUserRole } from '@/user/use-user-role';

import { mapJSONToEntity } from '../../companies/overview/action-panel/company-action-panel.state';
import { PrivacyStatus as PrivacyStatusComponent } from '../../modals/share-modal/privacy-status';

type DraftOptions = (PersonOption | TeamOptionV2 | InviteOption)[] | null;

export interface FileShareWithData {
  draftOptions: DraftOptions;
  removedOptions: (PersonOption | TeamOptionV2)[] | null;
  isPublic: boolean | null;
}

interface FileShareWithProps extends Pick<AsyncSelectProps, 'searchInputSize'> {
  sharingParams: IndexParams;
  separateDefaultValue?: boolean;
  state: RecoilValue<unknown[]>;
  privacyDefaultValue?: boolean;
  privacyPublicLabel?: string | ReactNode;
  privacyPrivateLabel?: string | ReactNode;
  onChange: (options: FileShareWithData) => void;
  shareWithData: FileShareWithData;
}

export function FileShareWith({
  sharingParams,
  state,
  privacyDefaultValue,
  privacyPublicLabel,
  privacyPrivateLabel,
  searchInputSize,
  separateDefaultValue = true,
  onChange,
  shareWithData,
}: FileShareWithProps) {
  const { t } = useTranslation('default');

  const { data: appRoles } = useRoles();

  const managerRole = appRoles?.find(r => r.name === APP_USER_ROLES.MANAGER);

  const role = useUserRole();
  const isAppUserGuest = role?.includes(APP_USER_ROLES.GUEST);

  const { data, isLoading, refreshData: refreshState } = useLoadableData(state);

  useEffect(() => {
    refreshState();
  }, [refreshState]);

  const setOptions: AsyncSelectProps['setOptions'] = async (search, page) => {
    const params = { ...sharingParams, page, search };
    const response = await api.fetchUsersAndTeamsOptions(params);
    const options: Array<PersonOption | TeamOption | InviteOption> =
      mapJSONToEntity(response.data);

    if (
      options.every(item => item.label !== search) &&
      search.match(EMAIL_REGEX)
    ) {
      options.push({
        label: search,
        value: '',
        email: search,
        type: 'invite',
        role: managerRole
          ? { label: managerRole.name, value: managerRole.id }
          : undefined,
      });
    }

    return options;
  };

  const handleOnChange: CustomAsyncSelectProps['onChange'] = (
    newValue,
    actionMeta,
  ) => {
    if (actionMeta.action === 'select-option') {
      if (isDefaultOptionArray(newValue)) {
        onChange({
          ...shareWithData,
          draftOptions: newValue,
        });
      }
    }

    if (actionMeta.action === 'remove-value') {
      if (
        isPersonOption(actionMeta.removedValue) ||
        isTeamOption(actionMeta.removedValue)
      ) {
        onChange({
          ...shareWithData,
          removedOptions: [
            ...(shareWithData.removedOptions || []),
            actionMeta.removedValue,
          ],
        });
      }
    }
  };

  if (isLoading) return <Loader size="small" />;

  return (
    <>
      <ShareContainerList>
        <AsyncSelectWithList
          defaultValue={data}
          setOptions={setOptions}
          placeholder={t`share.placeholder`}
          shouldLoadMore={() => false}
          components={{ Option: AsyncSelectOptionUsersTeams }}
          SelectedOptionsListComponent={
            <SelectedOptionsListUsersTeams isDisabled={isAppUserGuest} />
          }
          onChange={handleOnChange}
          searchInputSize={searchInputSize}
          separateDefaultValue={separateDefaultValue}
          isDisabled={isAppUserGuest}
        />
      </ShareContainerList>

      <PrivacyStatus
        defaultValue={privacyDefaultValue}
        publicLabel={privacyPublicLabel}
        privateLabel={privacyPrivateLabel}
        onChange={isPublic => onChange({ ...shareWithData, isPublic })}
      />
    </>
  );
}

const ShareContainerList = styled('div')`
  padding-bottom: 12px;
`;

const PrivacyStatus = styled(PrivacyStatusComponent)`
  margin-bottom: -20px;
  z-index: 1;
`;
