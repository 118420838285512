import type {
  ProjectCreator,
  ProjectFiltersTeamOptionAPI,
} from '@/api/v4/project-filters.api';
import type { ListingStage } from '@/api/v4/project-listings.api';
import type {
  ProjectRequestParams,
  ProjectSortOptions,
} from '@/api/v4/projects.api';
import type { IProjectFilters } from '@/features/projects/project.state';
import { defaultAppliedProjectsFilters } from '@/features/projects/project.state';
import type { ExtractedFilters } from '@/helpers/filters-helpers';
import { stringifyFilter } from '@/helpers/filters-helpers';
import {
  extractArrayFilters,
  filterOutEmptyFilterValues,
} from '@/helpers/filters-helpers';
import { isValidDate } from '@/helpers/format-date';
import { stringifySort } from '@/helpers/stringify-sort';
import type { ISort } from '@/ui/table/table.types';

export const stringifyProjectFilters = ({
  sort,
  filters = defaultAppliedProjectsFilters,
}: {
  filters?: IProjectFilters;
  sort?: ISort<ProjectSortOptions>;
}): string => {
  // Keys of filters that have the same property name (keyof IProjectFilters) as apiKey
  const keys: Array<Partial<keyof IProjectFilters>> = [
    'createdFor',
    'createdBy',
    'status',
    'listingStatus',
    'tags',
    'search',
  ];

  const common = keys
    .map(key => stringifyFilter({ ...filters }, key))
    .filter(Boolean)
    .flat();

  const createdDate = filters.createdDate
    ? `createdDate[start]=${
        filters.createdDate.start?.value
      }&createdDate[end]=${
        filters.createdDate.end
          ? filters.createdDate.end?.value
          : filters.createdDate.start?.value
      }`
    : '';

  const lastEditedDate = filters.lastEditedDate
    ? `lastEditedDate[start]=${
        filters.lastEditedDate.start?.value
      }&lastEditedDate[end]=${
        filters.lastEditedDate.end
          ? filters.lastEditedDate.end?.value
          : filters.lastEditedDate.start?.value
      }`
    : '';

  const sortParams = sort ? stringifySort(sort) : '';

  return [...common, createdDate, lastEditedDate, sortParams]
    .filter(filterOutEmptyFilterValues)
    .join('&')
    .replace(/&$/, '');
};

export const prepareProjectApiFilters = (filters: IProjectFilters) => {
  let params: ProjectRequestParams = {};

  if (filters.createdBy) {
    params = {
      ...params,
      createdBy: filters.createdBy.map(option => option.value),
    };
  }

  if (filters.tags) {
    params = {
      ...params,
      tags: filters.tags.map(option => option.value),
    };
  }

  if (filters.createdFor) {
    params = {
      ...params,
      teams: filters.createdFor.map(option => option.value),
    };
  }

  if (filters.status) {
    params = {
      ...params,
      statuses: filters.status.map(status => status.value),
    };
  }

  if (filters.listingStatus) {
    params = {
      ...params,
      listingStatus: filters.listingStatus.map(
        listingStatus => listingStatus.value,
      ),
    };
  }

  if (filters.createdDate) {
    const { start, end } = filters.createdDate;

    params = {
      ...params,
      ...(start && { 'createdDate[startDate]': start.value }),
      ...(end && { 'createdDate[endDate]': end.value }),
    };
  }

  if (filters.lastEditedDate) {
    const { start, end } = filters.lastEditedDate;

    params = {
      ...params,
      ...(start && { 'lastEditedDate[startDate]': start.value }),
      ...(end && { 'lastEditedDate[endDate]': end.value }),
    };
  }

  if (filters.search) {
    params = { ...params, search: filters.search };
  }

  return params;
};

export const extractProjectSearchFilters = (url: string): ExtractedFilters => {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const filters: ExtractedFilters = {};

  // date filters consists of two parameters related with each other e.g. createdDate[start] & createdDate[end].
  // Both of them are extracted and validated outside "for" loop as they must be considered together
  const dateRangeKeys = ['createdDate', 'lastEditedDate'];

  dateRangeKeys.forEach(key => {
    const startDate = searchParams.get(`${key}[start]`);
    const endDate = searchParams.get(`${key}[end]`);

    const validStartDate =
      startDate && isValidDate(startDate) ? startDate : null;
    const validEndDate = endDate && isValidDate(endDate) ? endDate : null;

    if (validStartDate || validEndDate) {
      filters[key] = {
        start: validStartDate,
        end: validStartDate
          ? validEndDate !== validStartDate
            ? validEndDate
            : null
          : null,
      };
    }
  });

  for (const [key, value] of searchParams.entries()) {
    // Skip date filters as they are already handled
    if (dateRangeKeys.some(dateRangeKey => key.startsWith(dateRangeKey))) {
      continue;
    }

    // Handle special case for search
    if (key === 'search') {
      filters.search = value;
      continue;
    }

    // Handle default cases - arrays of strings or other filters
    if (key in defaultAppliedProjectsFilters) {
      filters[key] = extractArrayFilters(filters[key], value);
      continue;
    }
  }

  return filters;
};

export const mapListingStatusFilter = (listingStatus: ListingStage) => ({
  value: listingStatus.name,
  label: listingStatus.displayName ?? listingStatus.name,
});

export const mapProjectCreatedByFilter = (user: ProjectCreator) => ({
  value: user.id,
  label: `${user.firstName} ${user.lastName}`,
});

export const mapProjectCreatedForFilter = (
  team: ProjectFiltersTeamOptionAPI,
) => ({
  value: team.id,
  label: team.name,
});
