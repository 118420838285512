import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useLoadableData } from '@/hooks/use-loadable-results';

import type { SharedAssetItem } from './shared-assets.state';
import {
  sharedCompaniesPageState,
  sharedCompaniesState,
} from './shared-assets.state';

export const useSharedCompanies = () => {
  const { data, isLoading } = useLoadableData(sharedCompaniesState);
  const [pageState, setPageState] = useRecoilState(sharedCompaniesPageState);
  const [companies, setCompanies] = useState<SharedAssetItem[]>([]);
  const resetPageState = useResetRecoilState(sharedCompaniesPageState);
  const { pageSize } = pageState;
  const isInitialLoading = isLoading && companies.length === 0;
  const hasNextPage = data?.length >= pageSize;

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    setCompanies(old => [...old, ...data]);
  }, [data, isLoading]);

  const setNextPage = () =>
    setPageState(({ page, ...rest }) => ({
      ...rest,
      page: page + 1,
    }));

  return {
    companies,
    hasNextPage,
    setNextPage,
    isLoading,
    isInitialLoading,
    reset: resetPageState,
  };
};
