import type { ChangeEvent } from 'react';
import { useDeferredValue, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { SEARCH_INPUT_MAX_CHAR } from '@/constants';
import { useDebounce } from '@/hooks/use-debounce';
import { useFocusOnMount } from '@/hooks/use-focus-on-mount';
import { colors } from '@/theme/colors';
import { Input } from '@/ui/input/input';
import { Stack } from '@/ui/line/line';

import { useRecentSearchRecords } from './search-suggestions/user-recent-search/use-recent-search-records.query';
import { SearchContent } from './search-content';

export const Search = () => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });
  const { hasUserSomeRecentSearchRecords } = useRecentSearchRecords();
  const [isInitiallyEmpty, setIsInitiallyEmpty] = useState(false);

  const [searchPhrase, setSearchPhrase] = useState<string | null>(null);
  const phrase = useDeferredValue(searchPhrase);

  const searchRef = useRef<HTMLInputElement>(null);
  useFocusOnMount(searchRef);

  useEffect(() => {
    if (searchPhrase) {
      setSearchPhrase(null);
    }

    if (hasUserSomeRecentSearchRecords) {
      setIsInitiallyEmpty(false);
    } else {
      setIsInitiallyEmpty(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserSomeRecentSearchRecords]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setSearchPhrase(null);
    }

    if (e.target.value) {
      setSearchPhrase(e.target.value);
    }

    setIsInitiallyEmpty(false);
  };

  const debouncedHandleChange = useDebounce(handleChange);

  return (
    <SearchContainer>
      <Input
        name="search"
        ref={searchRef}
        leftIcon="Search"
        iconSize="26px"
        iconColor={colors.gray.c7}
        padding="3px 8px"
        fontSize="12px"
        gap="8px"
        placeholder={t`searchPlaceholder`}
        onChange={debouncedHandleChange}
        maxLength={SEARCH_INPUT_MAX_CHAR}
      />
      <SearchContent
        isInitiallyEmpty={isInitiallyEmpty}
        searchPhrase={phrase}
      />
    </SearchContainer>
  );
};

const SearchContainer = styled(Stack)`
  height: 100%;
  flex: 1;
`;
