import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { withPermission } from '@/hoc/with-permission';
import { colors } from '@/theme/colors';
import type { Variant } from '@/ui/button/button';
import { Button } from '@/ui/button/button';
import { PERMISSION } from '@/user/permissions/permission.type';

import { AVAILABLE_EXTENSIONS } from './constants';
import { useValidateUploadedFiles } from './use-validate-uploaded-files';

export interface UploadFileInputProps {
  onSubmit: (files: File[]) => void;
  variant?: Variant;
}

export const UploadFileInput = withPermission(
  ({ variant, onSubmit }: UploadFileInputProps) => {
    const { t } = useTranslation('default');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { validateUploadedFiles } = useValidateUploadedFiles();

    const clearInputValue = () => {
      if (!fileInputRef?.current) return;
      fileInputRef.current.value = '';
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = validateUploadedFiles(event.target.files || []);
      if (!files) return;

      onSubmit(files);
      clearInputValue();
    };

    return (
      <>
        <input
          name="new-file"
          type="file"
          onChange={onFileInputChange}
          multiple
          ref={fileInputRef}
          accept={AVAILABLE_EXTENSIONS.map(item => '.' + item).join(',')}
          hidden
        />
        <UploadButton
          variant={variant ?? 'text'}
          startIcon="ArrowDownFromBracket"
          color={!variant ? colors.blue.primaryA : undefined}
          onClick={() => fileInputRef.current?.click()}
        >
          {t`actionPanel.files.uploadFile`}
        </UploadButton>
      </>
    );
  },
  PERMISSION.ADD_FILE,
);

const UploadButton = styled(Button)`
  height: fit-content;
`;
