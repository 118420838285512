import type { APIRequestBody, APIResponse } from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export type ProjectComment =
  APIResponse<'/api/v4/project-comments/{projectId}'>[number];

export const fetchProjectComments = async (projectId: string) =>
  get<APIResponse<'/api/v4/project-comments/{projectId}'>>(
    `/v4/project-comments/${projectId}`,
  );

type CreateProjectCommentResponse =
  APIResponse<'/api/v4/project-comments/create'>;

type CreateProjectCommentPayload =
  APIRequestBody<'/api/v4/project-comments/create'>;

export const createProjectComment = async (data: CreateProjectCommentPayload) =>
  post<CreateProjectCommentResponse, CreateProjectCommentPayload>(
    '/v4/project-comments/create',
    data,
  );

type UpdateProjectCommentResponse =
  APIResponse<'/api/v4/project-comments/update'>;

type UpdateProjectCommentPayload =
  APIRequestBody<'/api/v4/project-comments/update'>;

export const updateProjectComment = async (data: UpdateProjectCommentPayload) =>
  put<UpdateProjectCommentResponse, UpdateProjectCommentPayload>(
    '/v4/project-comments/update',
    data,
  );

export const deleteProjectComment = async (commentId: string) =>
  remove<void>(`/v4/project-comments/delete/${commentId}`);
