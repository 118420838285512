import { useQuery } from '@tanstack/react-query';

import { useAuthorization } from '@/auth/hooks/use-authorization';
import { cookies } from '@/config/cookies';
import { QueryKey } from '@/config/query-client';
import { COOKIES } from '@/constants';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import { fetchWhoAmI } from '@/api/v4/auth.api';

export const useCurrentUser = () => {
  const { isUserAuthorized } = useAuthorization();
  const navigateTo = useNavigateTo();

  return useQuery([QueryKey.WhoAmI], fetchWhoAmI, {
    onSuccess: appUser => {
      if (!appUser) {
        return;
      }

      if (!cookies.get(COOKIES.INTERCOM_HASH)) {
        cookies.set(COOKIES.INTERCOM_HASH, appUser.intercomHash);
      }
    },
    onError: navigateTo.signIn,
    enabled: isUserAuthorized,
    staleTime: 1000 * 10 * 15,
    cacheTime: 1000 * 10 * 25,
  });
};
