import type { IHeaderRow, IRow } from '../table.types';

export const useVisibleTableData = ({
  header,
  rows,
}: {
  header: IHeaderRow | undefined;
  rows: IRow[];
}) => {
  const cells = header
    ? header.cells.map(cell =>
        cell.isVisible === undefined ? { ...cell, isVisible: true } : cell,
      )
    : [];

  const visibleColumns = cells.filter(cell => cell.isVisible) ?? [];

  const visibleRows = rows.map(row => ({
    ...row,
    cells: row.cells.filter(
      (_, cellIndex) => cells.at(cellIndex)?.isVisible ?? false,
    ),
  }));

  return {
    visibleColumns,
    visibleRows,
  };
};
