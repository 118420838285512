import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export type TeamsQueryParams = APIRequestQueryParams<'/api/v4/teams'>;
export type TeamsSortParams = NonNullable<TeamsQueryParams['sort']>;

export type TeamOnList = APIResponse<'/api/v4/teams'>[number];

export const fetchTeams = (params?: TeamsQueryParams) =>
  get<TeamOnList[]>(`/v4/teams`, { params });

export type TeamOptionsQueryParams =
  APIRequestQueryParams<'/api/v4/teams/options'>;
export type TeamOption = APIResponse<'/api/v4/teams/options'>[number];

export const fetchTeamOptions = (params?: TeamOptionsQueryParams) =>
  get<TeamOption[]>(`/v4/teams/options`, { params });

export const fetchTeamOptionById = (teamId: string) =>
  get<TeamOption>(`/v4/teams/options/${teamId}`);

type TeamMemberQueryParams =
  APIRequestQueryParams<'/api/v4/teams/{teamId}/team-members'>;

export type TeamMemberSortParams = NonNullable<TeamMemberQueryParams['sort']>;

export type TeamMember =
  APIResponse<'/api/v4/teams/{teamId}/team-members'>[number];

export type Team = APIResponse<'/api/v4/teams/{teamId}'>;

type CreateTeamPayload = APIRequestBody<'/api/v4/teams/create'>;
type CreateTeamResponse = APIResponse<'/api/v4/teams/create'>;

export const createTeam = (payload: CreateTeamPayload) =>
  post<CreateTeamResponse>(`/v4/teams/create`, payload);

export type EditTeamPayload = APIRequestBody<'/api/v4/teams/{teamId}/edit'>;
type EditTeamResponse = APIResponse<'/api/v4/teams/{teamId}/edit'>;

export const editTeam = (teamId: string, payload: EditTeamPayload) =>
  put<EditTeamResponse, EditTeamPayload>(`/v4/teams/${teamId}/edit`, payload);

export const deleteTeam = (teamId: string) =>
  remove(`/v4/teams/${teamId}/delete`);

export const fetchTeam = (teamId: string) => get<Team>(`/v4/teams/${teamId}`);

export const fetchTeamMembers = (
  teamId: string,
  params: TeamMemberQueryParams,
) => get<TeamMember[]>(`/v4/teams/${teamId}/team-members`, { params });

export type AddOrInviteTeamMemberPayload =
  APIRequestBody<'/api/v4/teams/add-or-invite-team-members'>;

export const addOrInviteTeamMember = (payload: AddOrInviteTeamMemberPayload) =>
  post<void>(`/v4/teams/add-or-invite-team-members`, payload);

export const removeTeamMember = (teamId: string, userId: number) =>
  remove<TeamMember[]>(`/v4/teams/${teamId}/team-member/${userId}`);
