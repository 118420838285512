import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import type { SearchSuggestion } from '@/api/v4/search.api';
import { Loader } from '@/components/loader/loader';
import { getCategoryIcon } from '@/features/modals/manage-tags-modal/helpers/get-category-icon';
import { openNewTab } from '@/helpers/open-new-tab';
import { paths } from '@/routes/helpers/paths';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Small } from '@/ui/typography/across-platform';

import { SuggestionContainer } from './suggestion-container';
import { useCountEntitiesByTag } from './use-count-entities-by-tag.query';
import { parseSearchParams } from '@/routes/helpers/parse-search-params';

export const TagSuggestion = ({
  suggestion,
}: {
  suggestion: SearchSuggestion;
}) => {
  return (
    <SuggestionContainer label={suggestion.name}>
      <Flexbox name="tag-suggestion-content" justify="space-between" fullWidth>
        <GeneralTag variant="blue">
          {suggestion.name}{' '}
          <Icon icon={getCategoryIcon(suggestion.category ?? '')} />
        </GeneralTag>

        <TagEntitiesCounts tagId={suggestion.id} />
      </Flexbox>
    </SuggestionContainer>
  );
};

const TagEntitiesCounts = ({ tagId }: { tagId: string }) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'globalSearch',
  });

  const { data, isLoading } = useCountEntitiesByTag(tagId);

  if (!data || isLoading) return <Loader size="small" />;

  const redirectToTagFilters = (basePath: string) => {
    openNewTab(
      basePath +
        parseSearchParams({
          tags: tagId,
        }),
    );
  };

  return (
    <Flexbox name="count-entities-by-tag-container" gap="12px">
      <Entity
        count={data.organizations}
        label={t`companies`}
        onClick={redirectToTagFilters.bind(null, paths.companies())}
      />
      <Entity
        count={data.projects}
        label={t`projects`}
        onClick={redirectToTagFilters.bind(null, paths.projects())}
      />
    </Flexbox>
  );
};

const Entity = ({
  label,
  count,
  onClick,
}: {
  label: string;
  count: number;
  onClick: () => void;
}) => {
  return (
    <Flexbox name="entity-link" gap="6px">
      <EntityCount>({count}) </EntityCount>
      <EntityLink onClick={onClick}>
        {label} &nbsp; <Icon icon="ArrowRight" />
      </EntityLink>
    </Flexbox>
  );
};

const EntityCount = styled(Small)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray.c12};
`;

const EntityLink = styled(Small)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue.primaryA};
  cursor: pointer;

  ${({ theme }) => colorIcon(theme.colors.blue.primaryA)}

  &:hover {
    text-decoration: underline;
  }
`;
