import type {
  APIRequestPathParams,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, remove } from './base-fetch';

export type TeamFile = APIResponse<'/api/v4/teams/{teamId}/files'>[number];

export const fetchTeamFiles = async (teamId: string) =>
  get<TeamFile[]>(`/v4/teams/${teamId}/files`);

type TeamFileUpload = APIResponse<'/api/v4/teams/{teamId}/files/upload'>;

export const uploadTeamFile = async ({
  teamId,
  files,
}: {
  teamId: string;
  files: File[];
}) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });

  return post<TeamFileUpload>(`/v4/teams/${teamId}/files/upload`, formData);
};

type TeamFileLink = APIResponse<'/api/v4/teams/{teamId}/files/{fileId}/link'>;

type TeamFileLinkParams =
  APIRequestPathParams<'/api/v4/teams/{teamId}/files/{fileId}/link'>;

type TeamFileLinkQuery =
  APIRequestQueryParams<'/api/v4/teams/{teamId}/files/{fileId}/link'>;

export type TeamFileLinkMode = TeamFileLinkQuery['mode'];

export const fetchTeamFileLink = async (
  pathParams: TeamFileLinkParams,
  queryParams: TeamFileLinkQuery,
) =>
  get<TeamFileLink>(
    `/v4/teams/${pathParams.teamId}/files/${pathParams.fileId}/link`,
    {
      params: queryParams,
    },
  );

type TeamFileScanningStatusParams =
  APIRequestPathParams<'/api/v4/teams/{teamId}/files/{fileId}/scanning-status'>;

export type TeamFileScanningStatus =
  APIResponse<'/api/v4/teams/{teamId}/files/{fileId}/scanning-status'>;

export const fetchTeamFileScanningStatus = async ({
  teamId,
  fileId,
}: TeamFileScanningStatusParams) =>
  get<TeamFileScanningStatus>(
    `/v4/teams/${teamId}/files/${fileId}/scanning-status`,
  );

type DeleteTeamFileParams =
  APIRequestPathParams<'/api/v4/teams/{teamId}/files/{fileId}/delete'>;

export const deleteTeamFile = async ({
  teamId,
  fileId,
}: DeleteTeamFileParams) =>
  remove<TeamFile[]>(`/v4/teams/${teamId}/files/${fileId}/delete`);
