import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Loader } from '@/components/loader/loader';
import { getCompanyCrunchbaseUrl } from '@/features/companies/helpers/get-company-crunchbase-url';
import type { ICompanyListed } from '@/features/companies/overview/company.state';
import { addCompanyInProgressState } from '@/features/companies/overview/company.state';
import { companiesAddedState } from '@/features/companies/overview/company.state';
import { RelatedProjectsCount } from '@/features/companies/related-projects/related-projects-count';
import { projectIdState } from '@/features/projects/project.state';
import { Rating } from '@/features/projects/project-listings/stage-management-table/table/ranking';
import { Location } from '@/features/projects/project-listings/stage-management-table/table/use-project-listings-table-configuration';
import { useCreateProjectListings } from '@/features/projects/project-listings/use-create-project-listings.mutation';
import { useProjectListings } from '@/features/projects/project-listings/use-project-listings.query';
import { formatEmpty } from '@/helpers/format-empty';
import { formatLocation } from '@/helpers/format-location';
import { openNewTab } from '@/helpers/open-new-tab';
import { useCreateCompany } from '@/hooks/queries/use-create-company.mutation';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { TablePrimaryCell } from '@/ui/table/infinite-table/table-cells';
import type { IRow } from '@/ui/table/table.types';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useCompanies } from '../../companies/hooks/use-companies.query';

type CreateFromCbPayload = {
  cb_id: string;
  source?: string;
};

export const useCompaniesTableConfiguration = (companies: ICompanyListed[]) => {
  const { t } = useTranslation('projects');
  const navigateTo = useNavigateTo();
  const setModalState = useSetRecoilState(modalState);
  const isModalCreate =
    useRecoilValue(modalState)?.state === 'createNewCompany';
  const projectId = useRecoilValue(projectIdState);
  const { data: projectListings } = useProjectListings(projectId);

  const { refetch: refreshCompanies, isLoading: isLoadingCompanies } =
    useCompanies();

  const { hasRequiredPermission } = useUserPermissions();
  const header = useTranslateHeaders([], 'projects', 'projects');

  const { createProjectListing } = useCreateProjectListings();
  const { createCompanyFromCrunchbase } = useCreateCompany();

  const [addCompanyInProgress, setAddCompanyInProgress] = useRecoilState(
    addCompanyInProgressState,
  );

  const [selectedToAdd, setSelectedToAdd] = useState<string | null>(null);
  const [selectedToCreate, setSelectedToCreate] = useState<string | null>(null);

  const [companiesAdded, setCompaniesAdded] =
    useRecoilState(companiesAddedState);

  // wait for both refreshers to finish
  useEffect(() => {
    if (!isLoadingCompanies) {
      setAddCompanyInProgress(false);
      setSelectedToAdd(null);
      setSelectedToCreate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCompanies]);

  const canGoToCompanyPage = hasRequiredPermission(
    PERMISSION.VIEW_ORGANIZATION_HOME_SCREEN,
  );

  const goToCompanyPage = useCallback(
    (id: string) => {
      if (!canGoToCompanyPage) return;
      navigateTo.company({ companyId: id });
      setModalState(null);
    },
    [canGoToCompanyPage, navigateTo, setModalState],
  );

  const addToProject = useCallback(
    async (companyId: string, cbId?: CreateFromCbPayload['cb_id']) => {
      if (!projectId) return;

      await createProjectListing(projectId, {
        companyIds: [companyId],
      });

      const newCompany = companies.find(
        company => company.id === (cbId ?? companyId),
      );

      newCompany &&
        setCompaniesAdded([
          ...companiesAdded,
          {
            id: newCompany.id,
            name: newCompany.name,
            domain: newCompany.domain,
            yearEstablished: newCompany.yearEstablished,
            logoUrl: newCompany.logoUrl ?? null,
          },
        ]);

      await refreshCompanies();
    },
    [
      projectId,
      createProjectListing,
      companies,
      setCompaniesAdded,
      companiesAdded,
      refreshCompanies,
    ],
  );

  const addCompanyFromCb = useCallback(
    async (id: CreateFromCbPayload['cb_id']) => {
      const companyResponse = await createCompanyFromCrunchbase({
        cbId: id,
        source: 'SOSA Q',
      });
      await addToProject(companyResponse.id, id);
    },
    [createCompanyFromCrunchbase, addToProject],
  );

  const handleAddCompany = useCallback(
    async (company: ICompanyListed) => {
      if (!projectId) return;

      setSelectedToAdd(company.id);
      setAddCompanyInProgress(true);

      if (company.fromCb) {
        await addCompanyFromCb(company.id);
      } else {
        await addToProject(company.id);
      }
    },
    [
      projectId,
      setSelectedToAdd,
      setAddCompanyInProgress,
      addCompanyFromCb,
      addToProject,
    ],
  );

  const handleCreateNewCompany = useCallback(
    async (company: ICompanyListed) => {
      setSelectedToAdd(company.id);
      setAddCompanyInProgress(true);

      const companyResponse = await createCompanyFromCrunchbase({
        cbId: company.id,
        source: 'SOSA Q',
      });
      await refreshCompanies();
      goToCompanyPage(companyResponse.id);
    },
    [
      setSelectedToAdd,
      setAddCompanyInProgress,
      createCompanyFromCrunchbase,
      refreshCompanies,
      goToCompanyPage,
    ],
  );

  const checkIfAdded = useCallback(
    (id: string) => {
      return (
        projectListings?.some(listing => listing.organization.id === id) ||
        companiesAdded.some(company => company.id === id)
      );
    },
    [companiesAdded, projectListings],
  );

  const rows: IRow[] = useMemo(
    () =>
      companies.map(company => {
        return {
          id: company.id,
          rowHover: true,
          rowPadding: {
            left: '20px',
            right: '20px',
          },
          cells: [
            {
              value: (
                <Flexbox
                  name="company-name-container"
                  gap="8px"
                  alignItems="center"
                  fullWidth
                >
                  <Logo
                    name={company.name || ''}
                    logoUrl={company.logoUrl}
                    onClick={goToCompanyPage.bind(null, company.id)}
                    singleLetter={true}
                    bgColor={
                      company.logoUrl ? 'transparent' : colors.accent.green.c3
                    }
                    border={
                      company.logoUrl
                        ? `2px solid ${colors.gray.c3}`
                        : undefined
                    }
                  />
                  <EllipsisTextTooltip
                    text={company.name}
                    Component={<TablePrimaryCell maxWidth={230} />}
                  />
                  {company.fromCb ? (
                    <Tooltip content={company.domain}>
                      <StyledIconButton
                        icon="Globe"
                        variant="ghost"
                        color={colors.basics.black}
                        onClick={() =>
                          company.domain &&
                          window.open(company.domain, '_blank')
                        }
                      />
                    </Tooltip>
                  ) : (
                    <StyledIconButton
                      icon="VectorUp"
                      variant="ghost"
                      color={colors.basics.black}
                      onClick={goToCompanyPage.bind(null, company.id)}
                    />
                  )}
                </Flexbox>
              ),
            },
            {
              width: '233px',
              value: (
                <Tooltip
                  disabled={!company.locations?.[0]?.city_name}
                  content={formatEmpty(
                    company.locations?.[0] &&
                      formatLocation({
                        cityName: company.locations?.[0].city_name,
                        regionName: company.locations?.[0].region_name,
                        countryName: company.locations?.[0].country_name,
                      }),
                  )}
                >
                  <Location>
                    {formatEmpty(
                      company.locations?.[0] &&
                        formatLocation({
                          cityName: company.locations?.[0].city_name,
                          countryName: company.locations?.[0].country_name,
                        }),
                    )}
                  </Location>
                </Tooltip>
              ),
            },
            {
              width: '90px',
              align: 'center',
              value: !company.fromCb ? (
                <Rating ranking={company.averageRating ?? null} />
              ) : (
                ''
              ),
            },
            {
              width: '64px',
              value: company.cbPermalink ? (
                <Icon
                  icon="FromCb"
                  onClick={() => {
                    // condition 3 lines above is not enough - func getCompanyCrunchbaseUrl still consider it as string | null value
                    if (!company.cbPermalink) {
                      return;
                    }

                    openNewTab(getCompanyCrunchbaseUrl(company.cbPermalink));
                  }}
                />
              ) : (
                ''
              ),
            },
            {
              width: '64px',
              value: (
                <RelatedProjectsCount
                  value={company.listingsCount}
                  companyId={company.id}
                />
              ),
            },
            {
              width: '108px',
              padding: '0 20px 0 0',
              value: (
                <CompanyActions>
                  {addCompanyInProgress &&
                  (selectedToAdd === company.id ||
                    selectedToCreate === company.id) ? (
                    <Loader size="small" />
                  ) : !isModalCreate && checkIfAdded(company.id) ? (
                    <ButtonAdded
                      startIcon="Check"
                      color={colors.accent.green.c3}
                    >{t`addCompany.added`}</ButtonAdded>
                  ) : (
                    <>
                      {isModalCreate && company.fromCb ? (
                        <ButtonAddCompany
                          onClick={() => handleCreateNewCompany(company)}
                        >{t`addCompany.create`}</ButtonAddCompany>
                      ) : (
                        <ButtonAddCompany
                          hidden={isModalCreate}
                          onClick={() => handleAddCompany(company)}
                        >
                          {t`addCompany.add`}
                        </ButtonAddCompany>
                      )}
                    </>
                  )}
                </CompanyActions>
              ),
            },
          ],
        };
      }),
    [
      companies,
      goToCompanyPage,
      addCompanyInProgress,
      selectedToAdd,
      selectedToCreate,
      isModalCreate,
      checkIfAdded,
      t,
      handleCreateNewCompany,
      handleAddCompany,
    ],
  );

  return { header, rows, selectedToAdd };
};

export const ButtonAddCompany = styled(Button)`
  opacity: 0;
  display: inline-flex;
  ${({ theme }) => theme.mixins.transition('opacity')}

  tr:hover &,
  &.always-on {
    opacity: 1;
  }

  ${({ hidden }) => hidden && 'display: none;'}
`;

const ButtonAdded = styled(Button)`
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.accent.green.c1};
`;

const CompanyActions = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIconButton = styled(IconButton)`
  &:hover {
    background-color: transparent;
  }
`;
