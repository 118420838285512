import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import {
  fetchProjectListings,
  type ProjectListing,
} from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import type { ISort } from '@/ui/table/table.types';

import { projectListingSortState } from '../project-listing.state';

export const useProjectListings = (
  projectId?: string | null,
  sort?: ISort<string>,
) => {
  const projectListingsSort = useRecoilValue(projectListingSortState);
  const sortParams = sort ?? projectListingsSort;

  const queryKey = useMemo(
    () => [
      QueryKey.ProjectListings,
      projectId,
      ...(sortParams ? [sortParams] : []),
    ],
    [projectId, sortParams],
  );

  const fetchListings = async () => {
    if (!projectId) return [];
    return fetchProjectListings(projectId, sortParams);
  };

  return useQuery<ProjectListing[]>({
    queryKey,
    queryFn: fetchListings,
    enabled: Boolean(projectId),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};
