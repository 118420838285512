import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilRefresher_UNSTABLE } from 'recoil';

import { deleteProject } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';

import { projectsKPIsState } from '../../projects/projects-kpi.state';

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const refreshKPIs = useRecoilRefresher_UNSTABLE(projectsKPIsState);

  const deleteProjectMutation = useMutation(deleteProject, {
    onSuccess: async () => {
      refreshKPIs();
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Projects]),
        queryClient.invalidateQueries([QueryKey.ProjectOptions]),
        queryClient.invalidateQueries([QueryKey.CompaniesStatistics]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  return {
    deleteProject: (projectId: string) =>
      deleteProjectMutation.mutateAsync(projectId),
    isError: deleteProjectMutation.isError,
  };
};
