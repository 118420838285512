import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { deleteMeeting as deleteMeetingAPI } from '@/api/v4/meetings.api';
import { notify } from '@/ui/snackbar/notify';

export const useDeleteMeeting = ({
  onRefreshData,
}: {
  onRefreshData: () => void;
}) => {
  const { t } = useTranslation('default');
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMeetingMutation = useMutation(
    async (meetingId: string) => {
      setIsDeleting(true);
      await deleteMeetingAPI(meetingId);
    },
    {
      onError: (error: Error) => {
        setIsDeleting(false);
        notify({ message: t`default:unknownError` });
        console.error(error.message);
      },
      onSuccess: () => {
        onRefreshData();
        setIsDeleting(false);
      },
    },
  );

  const deleteMeeting = async (meetingId: string) =>
    await deleteMeetingMutation.mutateAsync(meetingId);

  return {
    deleteMeeting,
    isDeleting,
    isError: deleteMeetingMutation.isError,
  };
};
