import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export type OrganizationContactList =
  APIResponse<'/api/v4/organizations/{organizationId}/contacts'>;

export type OrganizationContact =
  | APIResponse<'/api/v4/organizations/{organizationId}/contacts/create'>
  | APIResponse<'/api/v4/organizations/{organizationId}/contacts/{organizationContactId}/edit'>;

export type OrganizationContactQueryParams =
  APIRequestQueryParams<'/api/v4/organizations/{organizationId}/contacts'>;

export const fetchOrganizationContacts = async (
  organizationId: string,
  params: OrganizationContactQueryParams,
) =>
  get<OrganizationContactList>(`/v4/organizations/${organizationId}/contacts`, {
    params,
  });

export type CreateOrganizationContactPayload =
  APIRequestBody<'/api/v4/organizations/{organizationId}/contacts/create'>;

export const createOrganizationContact = async (
  organizationId: string,
  data: CreateOrganizationContactPayload,
) =>
  post<OrganizationContact>(
    `/v4/organizations/${organizationId}/contacts/create`,
    data,
  );

export type EditOrganizationContactPayload =
  APIRequestBody<'/api/v4/organizations/{organizationId}/contacts/{organizationContactId}/edit'>;

export const editOrganizationContact = async (
  organizationId: string,
  organizationContactId: string,
  data: EditOrganizationContactPayload,
) =>
  put<OrganizationContact, EditOrganizationContactPayload>(
    `/v4/organizations/${organizationId}/contacts/${organizationContactId}/edit`,
    data,
  );

export const deleteOrganizationContact = async (
  organizationId: string,
  organizationContactId: string,
) =>
  remove(
    `/v4/organizations/${organizationId}/contacts/${organizationContactId}/delete`,
  );
