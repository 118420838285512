import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import type { MeetingCreatePayload } from '@/api/v4/meetings.api';
import { createMeeting as createMeetingAPI } from '@/api/v4/meetings.api';
import { notify } from '@/ui/snackbar/notify';

export const useCreateMeeting = () => {
  const { t } = useTranslation('default');

  const createMeetingMutation = useMutation(createMeetingAPI, {
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
  });

  const createMeeting = (payload: MeetingCreatePayload) =>
    createMeetingMutation.mutateAsync(payload);

  return {
    createMeeting,
    isError: createMeetingMutation.isError,
  };
};
