import { IconButton } from '@/ui/button/icon-button';
import type { MouseEvent } from 'react';
import { colors } from '@/theme/colors';
import type {
  PinnedItemType,
  TogglePinnedItemPayload,
} from '@/api/v4/pinned-items.api';

export const PinItemButton = ({
  itemId: id,
  itemType: type,
  isPinned,
  onToggle,
  emptyUnpinnedIcon,
}: {
  itemId: string;
  itemType: PinnedItemType;
  isPinned: boolean;
  onToggle: (data: TogglePinnedItemPayload) => Promise<unknown>;
  emptyUnpinnedIcon?: boolean;
}) => {
  const getIcon = () => (emptyUnpinnedIcon && !isPinned ? 'PinEmpty' : 'Pin');

  const getIconColor = () =>
    emptyUnpinnedIcon || isPinned ? colors.blue.primaryA : colors.gray.c7;

  const handlePinClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    return onToggle({
      id,
      type,
    });
  };

  return (
    <IconButton
      dataTestId={`${isPinned ? 'unpin' : 'pin'}-item-button`}
      icon={getIcon()}
      variant="ghost"
      size="18px"
      color={getIconColor()}
      onClick={handlePinClick}
    />
  );
};
