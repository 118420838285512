import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { ReactComponent as SearchIllustration } from '@/assets/svg/search-illustration.svg';
import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Small } from '@/ui/typography/across-platform';

import { SearchContentContainer } from './content-container';

export const InitialState = () => {
  const { t } = useTranslation('default', { keyPrefix: 'globalSearch' });

  return (
    <SearchContentContainer fullWidth>
      <Flexbox
        name="search-initial-state"
        direction="column"
        justify="center"
        alignItems="center"
      >
        <SearchIllustration />
        <Small color={colors.gray.c12}>{t`searchInitialText`}</Small>
      </Flexbox>
      <BetaTagContainer>
        <GeneralTag variant="blue">{t`beta`}</GeneralTag>
      </BetaTagContainer>
    </SearchContentContainer>
  );
};

const BetaTagContainer = styled('div')`
  position: absolute;
  bottom: 0px;
  right: 0px;
`;
