import { get, post, type APIRequestBody, type APIResponse } from './base-fetch';

export type PinnedItem = APIResponse<'/api/v4/pinned-items'>[number];

export type PinnedItemType = PinnedItem['type'];

export type TogglePinnedItemPayload =
  APIRequestBody<'/api/v4/pinned-items/toggle'>;

export const fetchPinnedItems = async () =>
  get<PinnedItem[]>('v4/pinned-items');

export const togglePinnedItem = async (data: TogglePinnedItemPayload) =>
  post<PinnedItem, TogglePinnedItemPayload>('v4/pinned-items/toggle', data);
