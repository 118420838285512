import { styled } from 'goober';

import { prepareLink } from '@/utils/link';

import { Icon, type IconVariant } from '../icons/icon';
import { Tooltip } from '../tooltip/tooltip';

export const LinkIcon = ({
  url,
  icon,
  size,
  disableTooltip,
  dataTestId,
}: {
  url: string;
  icon: IconVariant;
  size?: string;
  disableTooltip?: boolean;
  dataTestId?: string;
}) => (
  <Link
    href={prepareLink(url)}
    target="_blank"
    size={size}
    data-testid={dataTestId}
  >
    <Tooltip disabled={disableTooltip} content={url} maxWidth="unset">
      <Icon icon={icon} />
    </Tooltip>
  </Link>
);

const Link = styled('a')<{ size?: string }>`
  width: ${({ size }) => size ?? '18px'};
  height: ${({ size }) => size ?? '18px'};

  svg {
    width: 100%;
    height: 100%;
  }
`;
