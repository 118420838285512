import { forwardRef, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { SuspenseFallback } from '@/components/suspense-fallback/suspense-fallback';
import type {
  ActionPanelContext,
  ActionPanelTab,
} from '@/features/action-panel/action-panel.state';
import { actionPanelActiveTabState } from '@/features/action-panel/action-panel.state';
import { activeMeetingFormState } from '@/features/action-panel/meetings/meetings.state';
import { modalState } from '@/state/modal.state';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { SlidingSidebar } from '@/ui/sliding-sidebar/sliding-sidebar';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import type { TabConfig } from '@/ui/tabs/tabs';
import { Tabs } from '@/ui/tabs/tabs';

import { useCompanyActionsInfo } from '../companies/overview/action-panel/use-company-actions-info.query';
import { useProjectActionsInfo } from '../projects/action-panel/use-project-actions-info.query';
import { useTeamActionsInfo } from '../teams/overview/action-panel/use-team-actions-info.query';

import {
  actionPanelConfig,
  actionPanelTabConfig,
  defaultActiveTab,
} from './action-panel.config';
import { ActionPanelTabControl } from './action-panel-tab-control';
import { useActionPanel } from './use-action-panel';

export const ActionPanel = () => {
  const { t } = useTranslation('default');
  const { closeActionPanel, isActionPanelOpen, actionPanel } = useActionPanel();
  const { data: companyActionsInfo } = useCompanyActionsInfo();
  const { data: projectActionsInfo } = useProjectActionsInfo();
  const { data: teamActionsInfo } = useTeamActionsInfo();

  const [actionPanelActiveTab, setActionPanelActiveTab] = useRecoilState(
    actionPanelActiveTabState,
  );
  const activeMeetingForm = useRecoilValue(activeMeetingFormState);
  const setModal = useSetRecoilState(modalState);

  useLayoutEffect(() => {
    if (!actionPanel || actionPanelActiveTab !== 'updates') return;

    setActionPanelActiveTab(defaultActiveTab[actionPanel.context]);
  }, [actionPanel, actionPanelActiveTab, setActionPanelActiveTab]);

  const handleTabChange = useCallback(
    (tab: ActionPanelTab) => {
      if (activeMeetingForm.isDirty) {
        setModal({
          state: 'discardMeetingFormChanges',
          mainAction: () => setActionPanelActiveTab(tab),
        });
      } else {
        setActionPanelActiveTab(tab);
      }
    },
    [activeMeetingForm, setActionPanelActiveTab, setModal],
  );

  const handleResetActionPanel = useCallback(() => {
    if (activeMeetingForm.isDirty) {
      setModal({
        state: 'discardMeetingFormChanges',
        mainAction: closeActionPanel,
      });
    } else {
      closeActionPanel();
    }
  }, [activeMeetingForm.isDirty, closeActionPanel, setModal]);

  if (!isActionPanelOpen || !actionPanel) return null;

  function getActionCount(context: ActionPanelContext, tab: string) {
    let count = 0;

    switch (context) {
      case 'company':
        count =
          companyActionsInfo?.[tab as keyof typeof companyActionsInfo] || 0;
        break;

      case 'project':
        count =
          projectActionsInfo?.[tab as keyof typeof projectActionsInfo] || 0;
        break;

      case 'team':
        count = teamActionsInfo?.[tab as keyof typeof teamActionsInfo] || 0;
        break;
    }
    return count > 99 ? '99+' : count.toString();
  }

  const tabsConfig: TabConfig<ActionPanelTab>[] = actionPanelTabConfig
    .filter(tab => actionPanelConfig[actionPanel.context].hasOwnProperty(tab))
    .map(tab => ({
      id: tab,
      title: (
        <ActionPanelTabControl
          name={t('actionPanel.tabs.' + tab, {
            total: getActionCount(actionPanel.context, tab),
          })}
        />
      ),
      content: (
        <MainContent name="action-panel-content" justify="center" grow={1}>
          <SuspenseFallback>
            {actionPanelConfig[actionPanel.context][actionPanelActiveTab]}
          </SuspenseFallback>
        </MainContent>
      ),
    }));

  return (
    <SlidingSidebar transition>
      <Container name="action-panel-container" direction="column">
        <Header
          name="action-panel-header"
          alignItems="center"
          justify="space-between"
          padding="16px 32px 0 32px"
        >
          <TitleContainer
            name="action-panel-title"
            alignItems="center"
            gap="24px"
            onClick={handleResetActionPanel}
          >
            <Icon icon="ArrowRight" />
            <EllipsisTextTooltip
              text={actionPanel.title}
              Component={<TitleValue />}
            />
          </TitleContainer>
        </Header>
        <TabsContainer>
          <ActionPanelTabs
            tabsConfig={tabsConfig}
            activeTab={actionPanelActiveTab}
            setActiveTab={handleTabChange}
            padding="0 30px"
            noShadow
            border
            centerTabs
          />
        </TabsContainer>
      </Container>
    </SlidingSidebar>
  );
};

const Container = styled(Flexbox)`
  --slidingSidebarHeight: calc(72px - 10px);
  background-color: ${({ theme }) => theme.colors.basics.canvas};
  box-shadow: ${({ theme }) => theme.shadow.actionsPanel};
  height: calc(100vh - var(--slidingSidebarHeight));
`;

const Header = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const TitleContainer = styled(Flexbox)`
  ${({ theme }) => theme.typography.actionsPanel.acrossPanel.inPlatformLocation}
  ${({ theme }) => colorIcon(theme.colors.basics.black)}
  cursor: pointer;
  height: 75px;
`;

const TitleValue = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 420px;
`;

const TabsContainer = styled('div')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  color: ${({ theme }) => theme.colors.gray.c12};
  height: 100%;
`;

const ActionPanelTabs = styled(Tabs<ActionPanelTab>)`
  height: 100%;
`;

export const ActionPanelItem = styled(Flexbox)<{ borderWidth?: string }>`
  ${({ theme }) => theme.typography.actionsPanel.updates.personOrCompanyAction}
  background-color: ${({ theme }) => theme.colors.basics.white};
  padding: 24px 23px;
  border-bottom: ${({ borderWidth = '1px', theme }) =>
    `${borderWidth} solid ${theme.colors.gray.c3}`};

  strong {
    ${({ theme }) => theme.typography.actionsPanel.updates.personOrCompanyName}
  }
`;

const MainContent = styled(Flexbox, forwardRef)`
  ${({ theme }) => theme.mixins.scrollbar}
  scrollbar-gutter: auto;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.basics.canvas};
  max-height: calc(100vh - 199px);
`;
