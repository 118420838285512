import { atom, selector } from 'recoil';

import type { SearchItemResponse } from '@/api';
import { api } from '@/api';
import type { IndexParams } from '@/types';
import type { IPageState } from '@/ui/table/table.types';

export interface SharedAssetItem {
  id: string;
  label: string;
  logoUrl?: string;
}

export const sharedAssetsUserIdState = atom<string | null>({
  key: 'sharedAssetsUserId',
  default: null,
});

export const sharedCompaniesPageState = atom<IPageState>({
  key: 'sharedCompaniesPageState',
  default: { page: 1, pageSize: 20 },
});

export const sharedProjectsPageState = atom<IPageState>({
  key: 'sharedProjectsPageState',
  default: { page: 1, pageSize: 20 },
});

export const sharedCompaniesState = selector<SharedAssetItem[]>({
  key: 'sharedCompaniesState',
  get: async ({ get }) => {
    const selectedUserId = get(sharedAssetsUserIdState);

    if (!selectedUserId) return [];

    const { page, pageSize } = get(sharedCompaniesPageState);

    const params: IndexParams = {
      page,
      items: pageSize,
      id: selectedUserId,
    };

    const { data } = await api.fetchSharedOrganizations(params);

    return data.data.map((company: SearchItemResponse) => ({
      id: company.id,
      label: company.attributes.name,
      logoUrl: company.attributes.logo_url,
    }));
  },
});

export const sharedProjectsState = selector<SharedAssetItem[]>({
  key: 'sharedProjectsState',
  get: async ({ get }) => {
    const selectedUserId = get(sharedAssetsUserIdState);

    if (!selectedUserId) return [];

    const { page, pageSize } = get(sharedProjectsPageState);

    const params: IndexParams = {
      page,
      items: pageSize,
      id: selectedUserId,
    };

    const { data } = await api.fetchSharedStreams(params);

    return data.data.map((project: SearchItemResponse) => ({
      label: project.attributes.name,
      id: project.id,
    }));
  },
});
