import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { uploadProjectFile as uploadProjectFileAPI } from '@/api/v4/project-files.api';
import { QueryKey } from '@/config/query-client';

import { projectIdState } from '../../project.state';

export const useUploadProjectFile = () => {
  const queryClient = useQueryClient();
  const projectId = useRecoilValue(projectIdState);

  const mutation = useMutation(uploadProjectFileAPI, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QueryKey.ProjectFiles, projectId]);
      await queryClient.invalidateQueries([
        QueryKey.ProjectActionsInfo,
        projectId,
      ]);
    },
    onError: async () => {
      await queryClient.invalidateQueries([QueryKey.ProjectFiles, projectId]);
      await queryClient.invalidateQueries([
        QueryKey.ProjectActionsInfo,
        projectId,
      ]);
    },
  });

  const uploadProjectFile = (files: File[]) => {
    if (!projectId) return;

    return mutation.mutateAsync({ projectId, files });
  };

  return {
    uploadProjectFile,
    isError: mutation.isError,
  };
};
