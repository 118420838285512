import { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { useLoadableData } from '@/hooks/use-loadable-results';

import type { SharedAssetItem } from './shared-assets.state';
import {
  sharedProjectsPageState,
  sharedProjectsState,
} from './shared-assets.state';

export const useSharedProjects = () => {
  const { data, isLoading } = useLoadableData(sharedProjectsState);
  const [projects, setProjects] = useState<SharedAssetItem[]>([]);
  const [pageState, setPageState] = useRecoilState(sharedProjectsPageState);
  const resetPageState = useResetRecoilState(sharedProjectsPageState);
  const { pageSize } = pageState;
  const isInitialLoading = isLoading && projects.length === 0;
  const hasNextPage = data?.length >= pageSize;

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    setProjects(old => [...old, ...data]);
  }, [data, isLoading]);

  const setNextPage = () =>
    setPageState(({ page, ...rest }) => ({
      ...rest,
      page: page + 1,
    }));

  return {
    projects,
    hasNextPage,
    setNextPage,
    isLoading,
    isInitialLoading,
    reset: resetPageState,
  };
};
