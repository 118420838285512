import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRecoilValue } from 'recoil';

import { isDefaultOption } from '@/helpers/other';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';

import { companyIdState } from '../../companies/overview/company.state';
import { UsersMentionTextField } from '../../inputs/users-mention-text-field';
import { projectIdState } from '../../projects/project.state';
import { teamIdState } from '../../teams/team.state';

import { CommentDropdown } from './dropdown/comment-dropdown';
import { commentValidationSchema } from './comment.schema';
import type { Comment, IEditCommentCallback } from './comment.type';

interface CommentFormFields {
  comment: string;
  projectListingId?: string | null;
  mentionedUsers: number[];
}

export const EditComment = ({
  comment,
  onSubmit,
  onCancel,
  dropdown,
}: {
  comment: Comment;
  onSubmit: IEditCommentCallback;
  onCancel: () => void;
  dropdown?: AsyncSelectProps;
}) => {
  const { t } = useTranslation('default');

  const companyId = useRecoilValue(companyIdState);
  const projectId = useRecoilValue(projectIdState);
  const teamId = useRecoilValue(teamIdState);

  const methods = useForm<CommentFormFields>({
    resolver: zodResolver(commentValidationSchema),
    defaultValues: {
      comment: comment.noteContent,
      projectListingId:
        'projectListing' in comment ? comment.projectListing?.id : undefined,
      mentionedUsers: [],
    },
  });

  const {
    setValue,
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const handleDropdownChange: CustomAsyncSelectProps['onChange'] = (
    newValue,
    actionMeta,
  ) => {
    if (actionMeta.action === 'clear') {
      setValue('projectListingId', null, {
        shouldDirty: true,
      });
    }

    if (isDefaultOption(newValue)) {
      setValue('projectListingId', newValue.value, { shouldDirty: true });
    }
  };

  const submitComment = (values: CommentFormFields) => {
    onSubmit(comment.id, {
      comment: values.comment,
      projectListingId: values.projectListingId ?? null,
      mentionedUsers: values.mentionedUsers,
    });
  };

  return (
    <FormProvider {...methods}>
      <Stack gap="24px">
        {dropdown ? (
          <CommentDropdown
            {...dropdown}
            onChange={handleDropdownChange}
            defaultValue={
              'projectListing' in comment && comment.projectListing
                ? {
                    value: comment.projectListing.id,
                    label: comment.projectListing.organization.name,
                  }
                : undefined
            }
          />
        ) : null}

        <Controller
          name="comment"
          render={({ field: { value, onChange } }) => (
            <UsersMentionTextField
              dataTestId="comments-tab-edit-comment-text-input"
              defaultValue={value}
              onChange={(value, mentionedUsers) => {
                setValue('mentionedUsers', mentionedUsers);
                onChange(value);
              }}
              organizationId={companyId ?? undefined}
              projectId={projectId ?? undefined}
              teamId={teamId ?? undefined}
            />
          )}
        />

        <Flexbox name="comment-edit-form-buttons" justify="flex-end">
          <Inline gap="10px">
            <Button variant="underlined" onClick={onCancel}>{t`cancel`}</Button>
            <Button
              onClick={handleSubmit(submitComment)}
              disabled={!isDirty || !isValid}
            >
              {t`save`}
            </Button>
          </Inline>
        </Flexbox>
      </Stack>
    </FormProvider>
  );
};
