import {
  cloneElement,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import type { Props } from 'react-select';
import type Select from 'react-select/dist/declarations/src/Select';
import { styled } from 'goober';

import { SEARCH_INPUT_MAX_CHAR } from '@/constants';
import {
  isDefaultOption,
  isNumberOption,
  type NonUndefined,
} from '@/helpers/other';
import type { IOption } from '@/types';
import { sharedComponents, sharedStyles } from '@/ui/select/select-shared';

import { Input } from '../../input/input';
import { SubHeaderBold } from '../../typography/widgets';

import { SelectedOptionsList } from './components/selected-options-list/selected-options-list';
import { OPTION_ITEM_HEIGHT } from './components/selected-options-list/selected-options-list-users-teams';
import { AsyncSelect } from './async-select';
import { AsyncSelectWithListContext } from './async-select-with-list-context';
import type { CustomAsyncSelectProps } from './use-select-type';

export type AsyncSelectProps = Props &
  CustomAsyncSelectProps & {
    showOptionsList?: boolean;
    searchInputSize?: 's' | 'm';
    SelectedOptionsListComponent?: React.ReactElement;
    separateDefaultValue?: boolean;
  };

export function AsyncSelectWithList({
  setOptions,
  onChange,
  placeholder,
  defaultValue = [],
  styles,
  searchInputSize,
  SelectedOptionsListComponent,
  separateDefaultValue = false,
  ...props
}: AsyncSelectProps) {
  const { t } = useTranslation('default');
  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [manualMenuIsOpen, setManualMenuIsOpen] = useState(false);
  const [selectedOptionsList, setSelectedOptionsList] = useState(defaultValue);
  const [search, setSearch] = useState('');
  const [selectRef, setSelectRef] = useState<Select<unknown, boolean> | null>(
    null,
  );

  const handleOnChange = useCallback<NonUndefined<Props['onChange']>>(
    (newOption, actionMeta) => {
      // newArray only needed to put newly added items on top of the list
      if (actionMeta.action === 'select-option') {
        const newArray = Array.isArray(selectedOptionsList)
          ? [actionMeta.option, ...selectedOptionsList]
          : newOption;

        setSelectedOptionsList(newArray);
        onChange?.(newOption, actionMeta);
        inputRef.current?.select();
        setManualMenuIsOpen(false);
      }

      if (actionMeta.action === 'remove-value') {
        setSelectedOptionsList((old: IOption[]) =>
          old.filter(
            item =>
              (isDefaultOption(actionMeta.removedValue) ||
                isNumberOption(actionMeta.removedValue)) &&
              item.value !== actionMeta.removedValue.value,
          ),
        );
        onChange?.(newOption, actionMeta);
      }
    },
    [selectedOptionsList, onChange],
  );

  const prepareList = useCallback(
    (includeDefaultList: boolean): IOption[] => {
      if (!Array.isArray(selectedOptionsList) || !Array.isArray(defaultValue)) {
        return [];
      }
      if (!separateDefaultValue) {
        return selectedOptionsList;
      }

      if (includeDefaultList) {
        return selectedOptionsList.filter(item =>
          defaultValue.some(obj => obj.value === item.value),
        );
      }

      return selectedOptionsList.filter(
        item => !defaultValue.some(obj => obj.value === item.value),
      );
    },
    [selectedOptionsList, separateDefaultValue, defaultValue],
  );

  const listOptions = useMemo(
    () => ({
      list: prepareList(false),
      listRef,
      onRemove: selectRef?.removeValue,
    }),
    [selectRef?.removeValue, prepareList],
  );

  const listOptionsDefault = useMemo(
    () => ({
      list: prepareList(true),
      listRef,
      onRemove: selectRef?.removeValue,
    }),
    [selectRef?.removeValue, prepareList],
  );

  return (
    <OutsideClickHandler onOutsideClick={() => setManualMenuIsOpen(false)}>
      <AsyncSelectWithListContext.Provider value={{ selectRef, setSelectRef }}>
        <SearchInput
          value={search}
          ref={inputRef}
          onChange={event => {
            selectRef?.handleInputChange(event);
            setManualMenuIsOpen(true);
            setSearch(event.target.value);
          }}
          placeholder={typeof placeholder === 'string' ? placeholder : ''}
          searchInputSize={searchInputSize}
          maxLength={SEARCH_INPUT_MAX_CHAR}
          {...(searchInputSize === 's' && { padding: '4px 8px' })}
          disabled={props.isDisabled}
        />
        <AsyncSelect
          components={sharedComponents}
          {...props}
          styles={{
            ...sharedStyles,
            ...styles,
            control: () => ({
              display: 'none',
            }),
            menuList: base => ({
              ...base,
              paddingTop: 0,
              paddingBottom: 0,
            }),
          }}
          setOptions={setOptions}
          defaultValue={defaultValue}
          onChange={handleOnChange}
          menuIsOpen={manualMenuIsOpen}
          isMulti
          isSearchable
          inputValue={search}
        />

        {SelectedOptionsListComponent ? (
          cloneElement(SelectedOptionsListComponent, { ...listOptions })
        ) : (
          <SelectedOptionsList {...listOptions} />
        )}

        {separateDefaultValue && prepareList(true).length ? (
          <>
            <DefaultListHeader>{t`share.peopleWithAccess`}</DefaultListHeader>
            {SelectedOptionsListComponent ? (
              cloneElement(SelectedOptionsListComponent, {
                ...listOptionsDefault,
              })
            ) : (
              <SelectedOptionsList {...listOptionsDefault} />
            )}
          </>
        ) : null}
      </AsyncSelectWithListContext.Provider>
    </OutsideClickHandler>
  );
}

const SearchInput = styled(Input, forwardRef)<{ searchInputSize?: 's' | 'm' }>`
  ${({ searchInputSize, theme }) =>
    searchInputSize === 's' &&
    theme.typography.actionsPanel.acrossPanel.smallText}
`;

const DefaultListHeader = styled(SubHeaderBold)`
  margin-top: 28px;

  [data-name='share-modal-container'] & + [data-name='options-list'] {
    max-height: calc(${OPTION_ITEM_HEIGHT}px * 4);
  }
`;
