export const getInitials = (name?: string, singleLetter = false) => {
  if (!name) return '';
  if (singleLetter) return name.charAt(0);
  if (!name.match(/ /g)) return name.substring(0, 2);

  return name
    .split(' ')
    .map(item => item.charAt(0))
    .slice(0, 2)
    .join('');
};
