import type { APIRequestBody } from './base-fetch';
import { type APIResponse, get, post } from './base-fetch';

type SignInPayload = APIRequestBody<'/api/v4/auth/sign-in'>;
type SignInResponse = APIResponse<'/api/v4/auth/sign-in'>;

export type SignUpPayload = APIRequestBody<'/api/v4/auth/sign-up'>;
type SignUpResponse = APIResponse<'/api/v4/auth/sign-up'>;

export const signIn = (payload: SignInPayload) =>
  post<SignInResponse>('/v4/auth/sign-in', payload);

export const signUp = (payload: SignUpPayload) =>
  post<SignUpResponse>('/v4/auth/sign-up', payload);

type InvitedUserPayload = APIRequestBody<'/api/v4/auth/user-details'>;
export type InvitedUserDetails = APIResponse<'/api/v4/auth/user-details'>;

export const fetchInvitedUserDetails = (payload: InvitedUserPayload) =>
  post<InvitedUserDetails>('/v4/auth/user-details', payload);

export type CurrentUser = APIResponse<'/api/v4/auth/who-am-i'>;
export type CurrentUserStatus = CurrentUser['status'];

export const fetchWhoAmI = () => get<CurrentUser>('/v4/auth/who-am-i');
