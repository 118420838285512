import type { RefObject } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import {
  isEllipsis,
  isInviteOption,
  isOfType,
  isPersonOption,
  isTeamOption,
} from '@/helpers/other';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { colors } from '@/theme/colors';
import type { IOption, PersonOption, TeamOptionV2 } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo, SquareLogo } from '@/ui/logo/logo';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

import { Select } from '../../../select';
import { getLabel, type InviteOption } from '../option/option-users-teams';

import {
  OptionsList,
  OptionsListItem,
  OptionsListItemLabel,
} from './selected-options-list';

interface SelectedOptionsListUsersTeamsProps {
  height?: string;
  list?: (PersonOption | TeamOptionV2 | InviteOption)[];
  listRef?: RefObject<HTMLDivElement>;
  isListExpanded?: boolean;
  onRemove?: (item: PersonOption | TeamOptionV2 | InviteOption) => void;
  isDisabled?: boolean;
}

export const OPTION_ITEM_HEIGHT = 60;

export function SelectedOptionsListUsersTeams({
  list,
  listRef,
  height,
  onRemove,
  isListExpanded = false,
  isDisabled,
}: SelectedOptionsListUsersTeamsProps) {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);
  const editMode = Boolean(onRemove);

  const { data: appRoles } = useRoles();

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  const changeRole = (role: unknown, option: InviteOption) => {
    if (!isOfType<IOption>(role, 'value')) return;

    option.role = { value: role.value, label: role.label };
  };

  const rolesOptions: IOption[] = appRoles
    ? appRoles.map(role => ({
        value: role.id,
        label: role.name,
      }))
    : [];

  return (
    <OptionsListUsersTeams
      height={height}
      name="options-list"
      direction="column"
      defaultRef={listRef}
      editMode={editMode}
      isScrollable={Boolean(isListExpanded || onRemove)}
      maxHeight="360px"
    >
      {list?.map((item, index) => (
        <OptionsListUsersTeamsItem
          key={item.value}
          name="options-list-item"
          justify="space-between"
          alignItems="center"
        >
          <Tooltip
            placement="left"
            content={
              item.label +
              (isPersonOption(item) && item.team ? ` (${item.team?.name})` : '')
            }
            visible={isReady && isEllipsis(refItemLabel.current[index])}
          >
            <Flexbox name="option-content" gap="8px" alignItems="center">
              {isTeamOption(item) ? (
                <SquareLogo
                  name={item.label ?? ''}
                  logoUrl={item.organization?.logoUrl}
                />
              ) : (
                <LogoUser
                  name={getLabel(item)}
                  singleLetter={
                    !Boolean(
                      isPersonOption(item) && item.firstName && item.lastName,
                    )
                  }
                  bgColor={colors.blue.primaryA}
                />
              )}
              <ItemUsersTeamsLabel
                ref={element => {
                  refItemLabel.current[index] = element;
                }}
                {...(isPersonOption(item) &&
                  item.team && { 'data-team': item.team.name })}
              >
                {item.label}
                <TeamName>{isPersonOption(item) && item.team?.name}</TeamName>
              </ItemUsersTeamsLabel>
            </Flexbox>
          </Tooltip>
          <Actions name="actions" gap="20px" alignItems="center">
            {isInviteOption(item) ? (
              <RoleDropdown
                options={rolesOptions}
                menuPosition="fixed"
                defaultValue={
                  rolesOptions.find(role => role.value === item.role?.value) ??
                  rolesOptions[0]
                }
                onChange={role => changeRole(role, item)}
              />
            ) : isPersonOption(item) ? (
              item.role?.label
            ) : null}
            {!isDisabled && onRemove && (
              <IconWrapper>
                <Icon
                  icon="Close"
                  width="10px"
                  height="10px"
                  color={colors.gray.c13}
                  onClick={() => onRemove?.(item)}
                />
              </IconWrapper>
            )}
          </Actions>
        </OptionsListUsersTeamsItem>
      ))}
    </OptionsListUsersTeams>
  );
}

const Actions = styled(Flexbox)`
  font-size: 14px;
`;

const IconWrapper = styled('div')`
  visibility: hidden;
`;

const OptionsListUsersTeams = styled(OptionsList)`
  ${({ editMode }) => editMode && 'margin-top: 12px;'}
  max-height: calc(${OPTION_ITEM_HEIGHT}px * 3);
`;

const OptionsListUsersTeamsItem = styled(OptionsListItem)`
  min-height: ${OPTION_ITEM_HEIGHT}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};

  [data-name='share-modal-container'] &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
    ${IconWrapper} {
      visibility: visible;
    }
  }
`;

const ItemUsersTeamsLabel = styled(OptionsListItemLabel, forwardRef)`
  ${({ theme }) => theme.typography.acrossPlatform.userName}

  [data-name='share-modal-container'] & {
    max-width: 560px;
  }

  &::after {
    content: attr(data-team);
    display: block;
    clear: left;
    margin-top: 2px;
    ${({ theme }) => theme.typography.widget.smallText}
    ${({ theme }) => theme.mixins.ellipsis}
  }
`;

const LogoUser = styled(Logo)`
  font-size: 12px;
`;

const TeamName = styled(SmallText)`
  height: 0;
  opacity: 0;
`;

const RoleDropdown = styled(Select)`
  width: 120px;
`;
