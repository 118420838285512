import analystsPaths from '../analysts-paths';
import { axiosInstance } from '../api';
import type { FileGrantAccessPayload, FileRemoveAccessPayload } from '../types';

// v2 organization files
export const shareOrganizationFile = async (payload: FileGrantAccessPayload) =>
  axiosInstance.post(analystsPaths.files.grantAccess(payload.id), payload);

export const removeAccessToCompanyFile = async (
  payload: FileRemoveAccessPayload,
) => axiosInstance.put(analystsPaths.files.removeAccess(payload.id), payload);

export const getFileGrantedUsers = async (fileId: string) =>
  axiosInstance.get(analystsPaths.files.grantedList(fileId));

// v2 team file
export const shareTeamFile = async (payload: FileGrantAccessPayload) =>
  axiosInstance.post(analystsPaths.teamFiles.grantAccess(payload.id), payload);

export const removeAccessToTeamFile = async (
  payload: FileRemoveAccessPayload,
) =>
  axiosInstance.put(analystsPaths.teamFiles.removeAccess(payload.id), payload);

export const getTeamFileGrantedUsers = async (fileId: string) =>
  axiosInstance.get(analystsPaths.teamFiles.grantedList(fileId));
