import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { useSidebars } from '@/hooks/use-sidebars';
import { paths } from '@/routes/helpers/paths';
import { modalState } from '@/state/modal.state';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';

import {
  BetaIcon,
  NavButton,
  NavMenuContainer,
  NavMenuItem,
} from './navbar.styles';

interface NavMenuItem {
  label: string;
  url?: string;
  onClick?: () => void;
  icon: IconVariant;
  iconActive?: IconVariant;
  iconActiveExpanded?: IconVariant;
  isVisible: boolean;
  endLabelItem?: ReactNode;
}

export function NavMenu() {
  const { t } = useTranslation('default');
  const { hasRequiredPermission } = useUserPermissions();
  const { hasRequiredProduct } = useUserProducts();
  const setModalState = useSetRecoilState(modalState);
  const { isNavbarOpen } = useSidebars();

  const items: NavMenuItem[] = [
    {
      label: t`Home`,
      url: paths.home(),
      icon: 'Home',
      iconActive: 'HomeActive',
      isVisible: true,
    },
    {
      label: t`search`,
      onClick: () => setModalState({ state: 'search' }),
      icon: 'Search',
      isVisible: true,
    },
    {
      label: t`Explore`,
      url: paths.explore(),
      icon: 'Explore',
      iconActive: 'ExploreBetaActive',
      iconActiveExpanded: 'ExploreActive',
      isVisible:
        hasRequiredProduct(PRODUCT.CRUNCHBASE) &&
        hasRequiredProduct(PRODUCT.EXPLORE) &&
        hasRequiredPermission(PERMISSION.ADD_COMPANY_TO_STREAM),
      endLabelItem: <BetaIcon />,
    },
    {
      label: t`Companies`,
      url: paths.companies(),
      icon: 'Companies',
      iconActive: 'CompaniesActive',
      isVisible: true,
    },
    {
      label: t`Projects`,
      url: paths.projects(),
      icon: 'Projects',
      iconActive: 'ProjectsActive',
      isVisible: true,
    },
    {
      label: t`Teams`,
      url: paths.teams(),
      icon: 'Teams',
      iconActive: 'TeamsActive',
      isVisible: hasRequiredPermission(PERMISSION.VIEW_ACCOUNT_DETAILS),
    },
  ];

  const getIcon = useCallback(
    (item: NavMenuItem, isActive: boolean) => {
      if (isActive && isNavbarOpen && item.iconActiveExpanded) {
        return item.iconActiveExpanded;
      }

      if (isActive && item.iconActive) {
        return item.iconActive;
      }

      return item.icon;
    },
    [isNavbarOpen],
  );

  return (
    <NavMenuContainer>
      {items
        .filter(item => item.isVisible)
        .map((item, idx) => {
          return (
            <Tooltip
              visible={!isNavbarOpen}
              key={idx}
              content={item.label}
              placement="right"
            >
              {item.url ? (
                <NavLink to={item.url}>
                  {({ isActive }) => (
                    <NavMenuItem isActive={isActive}>
                      <Icon icon={getIcon(item, isActive)} />
                      <span>{item.label}</span>
                      {item.endLabelItem && isActive ? (
                        <span>{item.endLabelItem}</span>
                      ) : null}
                    </NavMenuItem>
                  )}
                </NavLink>
              ) : (
                <NavButton
                  selected={false}
                  onClick={item.onClick ?? undefined}
                  data-testid={item.label}
                >
                  <Icon icon={item.icon} />
                  <span>{item.label}</span>
                </NavButton>
              )}
            </Tooltip>
          );
        })}
    </NavMenuContainer>
  );
}
