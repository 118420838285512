import { atom } from 'recoil';

import type { Meeting } from '@/api/v4/meetings.api';
import type { IOption } from '@/types';

interface ActiveMeetingForm {
  id: Meeting['id'] | null;
  isDirty: boolean;
  shouldDiscard: boolean;
  shouldScrollToSubmitted: boolean;
}

export interface ProjectOption extends IOption {
  createdFor: string | null;
}

export const activeMeetingFormState = atom<ActiveMeetingForm>({
  key: 'activeMeetingFormState',
  default: {
    id: null,
    isDirty: false,
    shouldDiscard: false,
    shouldScrollToSubmitted: false,
  },
});

export const editedMeetingIdState = atom<string | null>({
  key: 'editedMeetingId',
  default: null,
});

export const scrollToMeetingIdState = atom<string | null>({
  key: 'scrollToMeetingIdState',
  default: null,
});
