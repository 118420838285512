import type { DragObjectWithType } from 'react-dnd-cjs';
import { NativeTypes } from 'react-dnd-html5-backend-cjs';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { withPermission } from '@/hoc/with-permission';
import { Dropzone as DropzoneComponent } from '@/ui/dropzone/dropzone';
import { Stack } from '@/ui/line/line';
import { SmallText } from '@/ui/typography/action-panel';
import { PERMISSION } from '@/user/permissions/permission.type';

import { useValidateUploadedFiles } from '../use-validate-uploaded-files';

interface DraggableFilesItem extends DragObjectWithType {
  files: globalThis.File[];
}
interface FileDragDropProps {
  onDrop: (files: File[]) => void;
}

export const FilesDragDrop = withPermission(({ onDrop }: FileDragDropProps) => {
  const { t } = useTranslation('default');

  const { validateUploadedFiles } = useValidateUploadedFiles();

  const handleDropFiles = (item: DraggableFilesItem) => {
    const files = validateUploadedFiles(item.files);
    if (!files) return;

    onDrop(files);
  };

  return (
    <DropzoneContainer>
      <Dropzone
        onDrop={handleDropFiles}
        accept={NativeTypes.FILE}
        variant="secondary"
        borderRadius="0"
      />
      <UploadBox>
        <Stack centered>
          <DestinationName>{t`actionPanel.files.dropFilesToUpload`}</DestinationName>
          <SmallText>{t`actionPanel.files.drop`}</SmallText>
        </Stack>
      </UploadBox>
    </DropzoneContainer>
  );
}, PERMISSION.ADD_FILE);

const DropzoneContainer = styled('div')`
  position: absolute;
  z-index: 999;

  /* yes, it's intentional 🙈 
    100% values displays scrollbar, it's probably caused by pseudoBorder inside DropzoneComponent
    */
  width: 99.9%;
  height: 99.9%;
`;

const DestinationName = styled('p')`
  ${({ theme }) => theme.typography.atoms.dragDrop}
  font-family: ${({ theme }) => theme.fonts.wallop};
  font-weight: 600;
`;

const UploadBox = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const Dropzone = styled(DropzoneComponent<DraggableFilesItem>)`
  opacity: 0.9;
  width: 100%;
  height: 100%;
`;
