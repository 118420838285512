const analystsPaths = {
  baseUrl: window.runTimeEnvs.VITE_API_BASEURL,
  accounts: {
    edit: '/v2/accounts/',
  },
  search: {
    autocomplete: '/v2/search/suggest',
  },
  files: {
    grantedList: (fileId: string) =>
      `/v2/file_organizations/${fileId}/app_users_and_teams`,
    grantAccess: (fileId: string) => `/v2/file_organizations/${fileId}/link`,
    removeAccess: (fileId: string) => `/v2/file_organizations/${fileId}/unlink`,
  },
  clients: {
    index: '/v2/clients',
  },
  sharing: {
    organizations: '/v2/app_users/organizations',
    streams: '/v2/app_users/streams',
  },
  organizations: {
    verify: (id: string) => `v2/organizations/${id}/verify`,
    grantAccess: '/v2/organizations/link_for_user',
  },
  streams: {
    exportXLS: (id: string) => `/v2/streams/${id}/xls`,
    copy: (id: string) => `/v2/streams/${id}/duplicate`,
    grantAccess: '/v2/streams/link_for_user',
  },
  users: {
    changeRole: '/v2/app_users/change_role',
    edit: '/auth',
  },
  teamFiles: {
    grantedList: (fileId: string) =>
      `/v2/team_files/${fileId}/app_users_and_teams`,
    grantAccess: (fileId: string) => `/v2/team_files/${fileId}/link`,
    removeAccess: (fileId: string) => `/v2/team_files/${fileId}/unlink`,
  },
  teams: {
    usersAndTeams: '/v2/search/app_users_and_teams',
  },
} as const;

export default analystsPaths;
