import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { fetchTeamFileLink } from '@/api/v4/team-files.api';
import { QueryKey } from '@/config/query-client';
import type {
  DraftFile,
  File,
  FileLinkDownloadHandler,
  FileModeType,
} from '@/features/action-panel/files/file.interface';
import { FilesListContainer } from '@/features/action-panel/files/files-list-container';
import type { UploadFileInputProps } from '@/features/action-panel/files/upload-file-input';
import { teamIdState } from '@/features/teams/team.state';
import { downloadLinkAutomatically } from '@/helpers/download-link-automatically';
import { resolveSharingParamsTeamFile } from '@/helpers/share-access';
import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { useShareTeamFile } from './use-share-team-file.mutation';
import { useTeamFileScanningStatus } from './use-team-file-scanning-status.query';
import { useTeamFiles } from './use-team-files.query';
import { useUploadTeamFile } from './use-upload-team-file.mutation';

export const TeamFilesTab = () => {
  const { t } = useTranslation('default');
  const teamId = useRecoilValue(teamIdState);
  const queryClient = useQueryClient();

  const { uploadTeamFile } = useUploadTeamFile();
  const { shareTeamFile, unshareTeamFile } = useShareTeamFile();

  const { data } = useTeamFiles();
  const files = data ?? [];

  const setModal = useSetRecoilState(modalState);

  if (!teamId) return null;

  const openDeleteModal = async (id: string, name: string) => {
    setModal({
      state: 'deleteTeamFile',
      file: { id, name },
      teamId,
    });
  };

  const handleSubmitFiles: UploadFileInputProps['onSubmit'] = async file => {
    if (!teamId) return;

    setDraftFiles(file);

    try {
      await uploadTeamFile(file);
    } catch (error) {
      if (error instanceof Error) {
        notify({ message: error.message });
      }
    }
  };

  const handleFileLinkDownload: FileLinkDownloadHandler = async (
    file: File,
    mode: FileModeType,
  ) => {
    if (!teamId) return;

    const link = await fetchTeamFileLink({ teamId, fileId: file.id }, { mode });

    await downloadLinkAutomatically(link, file.name);
  };

  const setDraftFiles = (uploadingFiles: globalThis.File[]) => {
    queryClient.setQueryData(
      [QueryKey.TeamFiles, teamId],
      (files: Array<File | DraftFile> | undefined) => {
        if (!files) return;

        const draftFiles: DraftFile[] = uploadingFiles.map(file => ({
          id: file.name,
          name: file.name,
          createdDate: new Date().toISOString(),
          scanningStatus: null,
          creator: null,
          public: true,
          lastEditedDate: null,
        }));
        return [...draftFiles, ...files];
      },
    );
  };

  return (
    <FilesListContainer
      files={files}
      entityType="team_file"
      onFileLinkDownload={handleFileLinkDownload}
      emptyListLabel={t('actionPanel.files.empty', { context: 'team' })}
      onSubmit={handleSubmitFiles}
      onClickDelete={openDeleteModal}
      resolveSharingParams={resolveSharingParamsTeamFile}
      onShareFile={async payload => {
        await shareTeamFile(payload);
      }}
      onUnshareFile={async payload => {
        await unshareTeamFile(payload);
      }}
      useFileScanningStatus={useTeamFileScanningStatus}
    />
  );
};
