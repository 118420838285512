import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilRefresher_UNSTABLE } from 'recoil';

import type { FileGrantAccessPayload, FileRemoveAccessPayload } from '@/api';
import { ActionPanelDate } from '@/features/action-panel/action-panel-date';
import { WithLabel } from '@/features/companies/overview/header/edit-form/with-label';
import { getFileIcon } from '@/helpers/files';
import { isInviteOption, isPersonOption, isTeamOption } from '@/helpers/other';
import { useBoolean } from '@/hooks/use-boolean';
import { colors } from '@/theme/colors';
import type { IndexParams } from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Input } from '@/ui/input/input';
import { notify } from '@/ui/snackbar/notify';
import { useUserAccount } from '@/user/use-user-account';

import type { File } from './file.interface';
import type { FileShareWithData } from './file-share-with';
import { FileShareWith } from './file-share-with';
import type { UsersWithAccessToFileEntityType } from './user-with-access-to-file.state';
import { usersWithAccessToFileState } from './user-with-access-to-file.state';

interface EditFileProps {
  file: File;
  sharingParams: IndexParams;
  entityType: UsersWithAccessToFileEntityType;
  onCancel: () => void;
  onShareFile: (payload: FileGrantAccessPayload) => Promise<void>;
  onUnshareFile: (payload: FileRemoveAccessPayload) => Promise<void>;
}

export function EditFile({
  file,
  sharingParams,
  entityType,
  onCancel,
  onShareFile,
  onUnshareFile,
}: EditFileProps) {
  const { t } = useTranslation('default');
  const [disableUpdate, setDisableUpdate] = useBoolean(false);

  const refreshFileUsers = useRecoilRefresher_UNSTABLE(
    usersWithAccessToFileState({ fileId: file.id, entityType }),
  );
  const userAccount = useUserAccount();

  const [shareWithData, setShareWithData] = useState<FileShareWithData>({
    draftOptions: null,
    removedOptions: null,
    isPublic: null,
  });

  const handleSubmit = async () => {
    const { draftOptions, removedOptions, isPublic } = shareWithData;

    const payloadAdd = {
      id: file.id,
      is_public: Boolean(isPublic),
      to_new_people: [],
      user_ids: [],
      team_ids: [],
    };

    const payloadRemove = {
      id: file.id,
      user_ids: [],
      team_ids: [],
    };

    if (draftOptions) {
      Object.assign(payloadAdd, {
        to_new_people: draftOptions.filter(isInviteOption).map(person => ({
          email: person.email,
          role_id: person.role?.value,
        })),
        user_ids: draftOptions
          ?.filter(isPersonOption)
          .map(entity => entity.value),
        team_ids: draftOptions
          ?.filter(isTeamOption)
          .map(entity => entity.value),
      });
    }

    if (removedOptions) {
      Object.assign(payloadRemove, {
        user_ids: removedOptions
          ?.filter(isPersonOption)
          .map(entity => entity.value),
        team_ids: removedOptions
          ?.filter(isTeamOption)
          .map(entity => entity.value),
      });
    }

    const isPrivacyChanged = payloadAdd.is_public !== file.public;

    try {
      setDisableUpdate.on();
      (draftOptions || isPrivacyChanged) && (await onShareFile(payloadAdd));
      removedOptions && (await onUnshareFile(payloadRemove));
    } catch (error) {
      notify({ message: t`unknownError` });
    } finally {
      refreshFileUsers();
      setDisableUpdate.off();
      onCancel();
    }
  };

  return (
    <Flexbox name="edit-file-container" direction="column" grow={1} gap="39px">
      <WithLabel
        name="filename"
        labelText={t`actionPanel.files.fileInputLabel`}
        grow={1}
      >
        <FileNameInput
          value={file.name}
          padding="4px 8px"
          rightIcon={getFileIcon(file.name) || 'FileDoc'}
          iconColor={colors.basics.black}
          readOnly
        />
      </WithLabel>

      <ShareWithContainer>
        <PeopleHeader>{t`share.peopleWithAccess`}:</PeopleHeader>
        <FileShareWith
          shareWithData={shareWithData}
          sharingParams={sharingParams}
          privacyDefaultValue={file.public}
          searchInputSize="s"
          separateDefaultValue={false}
          state={usersWithAccessToFileState({ fileId: file.id, entityType })}
          onChange={setShareWithData}
          privacyPublicLabel={t('share.filePublicLabel', {
            accountName: userAccount?.name,
          })}
          privacyPrivateLabel={t`share.filePrivateLabel`}
        />
      </ShareWithContainer>

      <Flexbox
        name="edit-file-footer"
        alignItems="center"
        justify="space-between"
      >
        <ActionPanelDate date={file.createdDate} relative />
        <Flexbox name="edit-file-buttons" justify="flex-end" gap="20px">
          <Button
            variant="underlined"
            onClick={onCancel}
            disabled={disableUpdate}
          >{t`cancel`}</Button>
          <Button
            onClick={handleSubmit}
            disabled={disableUpdate}
          >{t`actionPanel.files.update`}</Button>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}

const FileNameInput = styled(Input, forwardRef)`
  ${({ theme }) => theme.typography.actionsPanel.acrossPanel.smallText}
  ${({ theme }) => colorIcon(theme.colors.basics.black)}
`;

const PeopleHeader = styled('div')`
  ${({ theme }) => theme.typography.actionsPanel.files.fileName}
  margin-bottom: 12px;
`;

const ShareWithContainer = styled('div')`
  padding: 0 10px;
`;
