import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { IEditCommentCallback } from '@/features/action-panel/comments/comment.type';
import { CommentsTab } from '@/features/action-panel/comments/comments-tab';
import { teamIdState } from '@/features/teams/team.state';
import { modalState } from '@/state/modal.state';

import { useCreateTeamComment } from './use-create-team-comment.mutation';
import { useDeleteTeamComment } from './use-delete-team-comment.mutation';
import { useTeamComments } from './use-team-comments.query';
import { useUpdateTeamComment } from './use-update-team-comment.mutation';

export const TeamCommentsTab = () => {
  const teamId = useRecoilValue(teamIdState);
  const [refCommentId, setRefCommentId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const setModal = useSetRecoilState(modalState);

  const { data: comments, refetch: refreshComments } = useTeamComments();
  const { mutateAsync: createTeamComment } = useCreateTeamComment();
  const { mutateAsync: updateTeamComment } = useUpdateTeamComment();
  const { mutateAsync: deleteTeamComment } = useDeleteTeamComment();

  if (!teamId || !comments) return null;

  const submitComment = async (comment: string) => {
    const createdComment = await createTeamComment({
      teamId,
      payload: {
        content: comment,
      },
    });

    await refreshComments();
    setRefCommentId(createdComment.id);
  };

  const openDeleteModal = async (commentId: string) => {
    setModal({
      state: 'confirmDelete',
      onConfirm: () => deleteComment(commentId),
    });
  };

  const deleteComment = async (commentId: string) => {
    setLoading(true);

    await deleteTeamComment({
      teamId,
      commentId,
    });

    await refreshComments();
    setLoading(false);
  };

  const editComment: IEditCommentCallback = async (commentId, data) => {
    setLoading(true);

    setRefCommentId(commentId);

    await updateTeamComment({
      teamId,
      commentId,
      payload: {
        content: data.comment,
      },
    });

    await refreshComments();
    setLoading(false);
  };

  return (
    <CommentsTab
      onSubmit={submitComment}
      comments={comments}
      onClickDelete={openDeleteModal}
      onEditSubmit={editComment}
      refCommentId={refCommentId}
      resetCommentId={() => setRefCommentId(null)}
      loading={loading}
    />
  );
};
