import type { ISort } from '@/ui/table/table.types';

import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, put, remove } from './base-fetch';

const ProjectListingSortFieldEnum = ['companyName', 'status'];
export type ProjectCompaniesSort = (typeof ProjectListingSortFieldEnum)[number];
export type ProjectListing =
  APIResponse<'/api/v4/projects/{projectId}/project-listings'>[number];

export type ProjectOnList = APIResponse<'/api/v4/projects'>[number];
export type ProjectRequestParams = Omit<
  APIRequestQueryParams<'/api/v4/projects'>,
  'startDate' | 'endDate'
> & {
  createdAt?: {
    startDate: string;
    endDate: string;
  };
};

export enum ProjectStatus {
  active = 'active',
  onHold = 'on_hold',
  stuck = 'stuck',
  done = 'done',
}

export type ProjectSortOptions = NonNullable<ProjectRequestParams['sort']>;
export type ProjectKPIs = APIResponse<'/api/v4/projects/kpi'>;

export type Project = APIResponse<'/api/v4/projects/{projectId}'>;

export const fetchProject = async (projectId: string) =>
  get<Project>(`v4/projects/${projectId}`);

type CreateProjectResponse = APIResponse<'/api/v4/projects/create'>;
export type CreateProjectPayload = APIRequestBody<'/api/v4/projects/create'>;

export const createProject = async (payload: CreateProjectPayload) =>
  post<CreateProjectResponse>(`v4/projects/create`, payload);

type EditProjectResponse = APIResponse<'/api/v4/projects/{projectId}/edit'>;
export type EditProjectPayload =
  APIRequestBody<'/api/v4/projects/{projectId}/edit'>;

export type ProjectCompanyListing =
  APIResponse<'/api/v4/projects/{projectId}/companies'>[number];

export type ProjectCompanyListingParams =
  APIRequestQueryParams<'/api/v4/projects/{projectId}/companies'>;

export const editProject = async (
  projectId: string,
  payload: EditProjectPayload,
) =>
  put<EditProjectResponse, EditProjectPayload>(
    `v4/projects/${projectId}/edit`,
    payload,
  );

export const deleteProject = async (projectId: string) =>
  remove<void>(`v4/projects/${projectId}/delete`);

export const fetchProjectListings = async (
  projectId: string,
  sort?: ISort<ProjectCompaniesSort>,
): Promise<ProjectListing[]> => {
  if (!projectId) return [];
  return get<ProjectListing[]>(`v4/projects/${projectId}/project-listings`, {
    params: sort,
  });
};

export const fetchProjectCompanies = async (
  projectId: string,
  params?: ProjectCompanyListingParams,
): Promise<ProjectCompanyListing[]> => {
  return get<ProjectCompanyListing[]>(`v4/projects/${projectId}/companies`, {
    params,
  });
};

export const fetchProjects = async (params: ProjectRequestParams) => {
  return get<ProjectOnList[]>(`v4/projects`, {
    params,
  });
};

export const fetchProjectKPIs = async (params: ProjectRequestParams) =>
  get<ProjectKPIs>(`v4/projects/kpi`, {
    params,
  });

export type ShareProjectBody =
  APIRequestBody<'/api/v4/projects/{projectId}/share'>;

export const shareProject = async (projectId: string, body: ShareProjectBody) =>
  post<void>(`v4/projects/${projectId}/share`, body);

export type ProjectOption = APIResponse<'/api/v4/projects/options'>[number];
export type ProjectOptionsQuery =
  APIRequestQueryParams<'/api/v4/projects/options'>;

export const fetchProjectOptions = (params: ProjectOptionsQuery) =>
  get<ProjectOption[]>('v4/projects/options', { params });

export const fetchProjectOptionById = (projectId: string) =>
  get<ProjectOption>(`v4/projects/options/${projectId}`);
