import type {
  APIRequestPathParams,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, remove } from './base-fetch';

export type ProjectFile =
  APIResponse<'/api/v4/projects/{projectId}/files'>[number];

export const fetchProjectFiles = async (projectId: string) =>
  get<ProjectFile[]>(`/v4/projects/${projectId}/files`);

type ProjectFileUpload =
  APIResponse<'/api/v4/projects/{projectId}/files/upload'>;

export const uploadProjectFile = async ({
  projectId,
  files,
}: {
  projectId: string;
  files: File[];
}) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });

  return post<ProjectFileUpload>(
    `/v4/projects/${projectId}/files/upload`,
    formData,
  );
};

type ProjectFileLink =
  APIResponse<'/api/v4/projects/{projectId}/files/{fileId}/link'>;

type ProjectFileLinkQueries =
  APIRequestQueryParams<'/api/v4/projects/{projectId}/files/{fileId}/link'>;

type ProjectFileLinkParams =
  APIRequestPathParams<'/api/v4/projects/{projectId}/files/{fileId}/link'>;

export type ProjectFileLinkMode = ProjectFileLinkQueries['mode'];

export const fetchProjectFileLink = async (
  pathParams: ProjectFileLinkParams,
  queryParams: ProjectFileLinkQueries,
) =>
  get<ProjectFileLink>(
    `/v4/projects/${pathParams.projectId}/files/${pathParams.fileId}/link`,
    {
      params: queryParams,
    },
  );

type ProjectFileScanningStatusParams =
  APIRequestPathParams<'/api/v4/projects/{projectId}/files/{fileId}/scanning-status'>;

export type ProjectFileScanningStatus =
  APIResponse<'/api/v4/projects/{projectId}/files/{fileId}/scanning-status'>;

export const fetchProjectFileScanningStatus = async ({
  projectId,
  fileId,
}: ProjectFileScanningStatusParams) =>
  get<ProjectFileScanningStatus>(
    `/v4/projects/${projectId}/files/${fileId}/scanning-status`,
  );

type DeleteProjectFileParams =
  APIRequestPathParams<'/api/v4/projects/{projectId}/files/{fileId}/delete'>;

export const deleteProjectFile = async ({
  projectId,
  fileId,
}: DeleteProjectFileParams) =>
  remove<ProjectFile[]>(`/v4/projects/${projectId}/files/${fileId}/delete`);
