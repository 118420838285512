import InfiniteScroll from 'react-infinite-scroll-component';
import { css, styled } from 'goober';
import type { RecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

import { theme } from '@/theme/setupTheme';
import { useVisibleTableData } from '@/ui/table/helpers/use-visible-data';
import type { SkeletonRowProps } from '@/ui/table/infinite-table/skeleton-row';
import { TableBody } from '@/ui/table/infinite-table/table-body';
import { TableHeaderCell } from '@/ui/table/infinite-table/table-header-cell';
import type { IHeaderRow, IRow, ISort } from '@/ui/table/table.types';
import { useCallback } from 'react';
import type { FetchNextPageOptions } from '@tanstack/react-query';

interface Props<T, Sort extends string | undefined = string> {
  id?: string;
  onSort?: (sort: ISort<Sort>) => void;
  isLoading: boolean;
  data: T[];
  rows: IRow[];
  hasMore: boolean;
  onLoadMore?: (options?: FetchNextPageOptions) => Promise<unknown>;
  headers: IHeaderRow;
  sortState: RecoilState<ISort<Sort>>;
  height: string;
  borderColor?: string;
  skeletonConfig?: Pick<SkeletonRowProps, 'rowPadding'>;
  dataTestId?: string;
}

export const InfiniteScrollTable = <
  T,
  Sort extends string | undefined = string,
>({
  id,
  onSort,
  isLoading,
  data,
  rows,
  hasMore,
  onLoadMore,
  headers,
  sortState,
  height,
  borderColor,
  skeletonConfig,
  dataTestId,
}: Props<T, Sort>) => {
  const [sortData, setSortData] = useRecoilState(sortState);
  const { visibleColumns, visibleRows } = useVisibleTableData({
    rows,
    header: headers,
  });

  const handleSort = (sort: ISort<Sort>) => {
    if (isLoading) return; // prevent multisorting while previously loading
    setSortData(sort);
    onSort?.(sort);
  };

  const handleLoadMore = useCallback(async () => {
    if (!hasMore || !onLoadMore) {
      return;
    }

    await onLoadMore();
  }, [hasMore, onLoadMore]);

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={handleLoadMore}
      hasMore={hasMore}
      loader={null}
      height={height}
      className={ScrollClassName}
    >
      <Table id={id} data-testid={dataTestId || `${id}-table`}>
        <thead data-testid={`${id}-thead`}>
          <tr>
            {visibleColumns.map(column => (
              <TableHeaderCell<Sort>
                key={column.columnKey}
                cell={column}
                sort={{ ...sortData, onSort: handleSort }}
              />
            ))}
          </tr>
        </thead>
        <TableBody
          id={id ? `${id}-tbody` : undefined}
          rows={visibleRows}
          isLoading={isLoading}
          borderColor={borderColor}
          skeletonConfig={{
            columns: headers.cells.length,
            rowConfig: headers.cells.map(({ align }) => ({ align })),
            ...skeletonConfig,
          }}
        />
      </Table>
    </InfiniteScroll>
  );
};

const Table = styled('table')`
  background-color: ${({ theme }) => theme.colors.basics.white};
  width: calc(100% - 15px);
  position: relative;
`;

const ScrollClassName = css`
  ${theme.mixins.scrollbar}
`;
