import type { APIRequestBody, APIResponse } from './base-fetch';
import { post } from './base-fetch';

export type CreateOrganizationSuggestionPayload =
  APIRequestBody<'/api/v4/organizations/suggestions'>;

type CreateOrganizationSuggestionResponse =
  APIResponse<'/api/v4/organizations/suggestions'>;

export type ExploreOrganizationSuggestion =
  APIResponse<'/api/v4/organizations/suggestions'>;

export const createOrganizationSuggestions = async (
  data: CreateOrganizationSuggestionPayload,
): Promise<CreateOrganizationSuggestionResponse> =>
  post<ExploreOrganizationSuggestion, CreateOrganizationSuggestionPayload>(
    `/v4/organizations/suggestions`,
    data,
  );

type CreateOrganizationDescriptionPayload =
  APIRequestBody<'/api/v4/organizations/suggestions/describe'>;

export type CreateOrganizationDescriptionResponse =
  APIResponse<'/api/v4/organizations/suggestions/describe'>;

export const createOrganizationDescription = async (
  data: CreateOrganizationDescriptionPayload,
): Promise<CreateOrganizationDescriptionResponse> =>
  post<CreateOrganizationDescriptionResponse>(
    `/v4/organizations/suggestions/describe`,
    data,
  );
