import type { ForwardedRef } from 'react';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilState, useResetRecoilState } from 'recoil';

import type { Meeting } from '@/api/v4/meetings.api';
import { formatDate } from '@/helpers/format-date';
import { openNewTab } from '@/helpers/open-new-tab';
import { paths } from '@/routes/helpers/paths';
import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { MenuButtonWithVisibilitySwitch } from '@/ui/menu-button/menu-button-with-visibility-switch';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { UsersMentionTextField } from '../../inputs/users-mention-text-field';

import { MeetingForm } from './form/meeting-form';
import { useMeetingForm } from './hooks/use-meeting-form';
import { MeetingFooter } from './meeting-footer';
import { activeMeetingFormState, editedMeetingIdState } from './meetings.state';
import type { MeetingTabContext } from './meetings-tab';

interface MeetingCollapsibleItemProps {
  meeting: Meeting;
  deleteHandler: (meetingId: string) => void;
  refreshHandler: () => void;
  withCompanyField?: boolean;
  withProjectField?: boolean;
  withTeamField?: boolean;
  showReadOnlyTeam?: boolean;
  isMenuShown?: boolean;
  isInitiallyOpen: boolean;
  isCompanyRequired?: boolean;
  context: MeetingTabContext;
}

export const MeetingCollapsibleItem = forwardRef(
  (
    {
      meeting,
      deleteHandler,
      refreshHandler,
      withCompanyField = false,
      withProjectField = false,
      withTeamField = false,
      showReadOnlyTeam = true,
      isMenuShown = false,
      isInitiallyOpen,
      isCompanyRequired,
      context,
    }: MeetingCollapsibleItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation('default');
    const [isOpen, setOpen] = useState(isInitiallyOpen);
    const [isHovered, setIsHovered] = useState(false);

    const [activeMeetingForm, setActiveMeetingForm] = useRecoilState(
      activeMeetingFormState,
    );
    const { hasRequiredPermission } = useUserPermissions();

    const [editedMeetingId, setEditedMeetingId] =
      useRecoilState(editedMeetingIdState);
    const resetEditedMeetingId = useResetRecoilState(editedMeetingIdState);
    const isEditMode = editedMeetingId === meeting.id;

    const setEditMode = useCallback(
      () => setEditedMeetingId(meeting.id),
      [meeting.id, setEditedMeetingId],
    );

    const cancelEditMode = useCallback(
      () => resetEditedMeetingId(),
      [resetEditedMeetingId],
    );

    const editFormMeetingDefaultValues = useMemo(() => {
      if (!meeting) {
        return null;
      }

      return {
        title: meeting.title ?? '',
        date: meeting.meetingDate ?? '',
        relatedCompanyId: meeting.organization?.id,
        relatedProjectId: meeting.project?.id,
        relatedTeamId: meeting.team?.id,
        attendees: meeting.attendees.map(attendee => ({
          label: `${attendee.firstName} ${attendee.lastName}`,
          value: attendee.id,
          entity: attendee.entity
            ? { id: attendee.entity.id, name: attendee.entity.name }
            : null,
          type: attendee.type,
        })),
        summary: meeting.summary ?? '',
      };
    }, [meeting]);
    const { methods, handleEditMeetingFormSubmit } = useMeetingForm({
      meetingId: meeting.id,
      defaultValues: editFormMeetingDefaultValues,
      isCompanyRequired,
    });
    const {
      formState: { isSubmitted },
      reset,
    } = methods;

    useEffect(() => {
      if (activeMeetingForm.shouldDiscard) {
        cancelEditMode();
      }
    }, [activeMeetingForm, cancelEditMode, setActiveMeetingForm]);

    useEffect(() => {
      if (isSubmitted) {
        cancelEditMode();
        reset();
        refreshHandler();
        setActiveMeetingForm({
          ...activeMeetingForm,
          shouldScrollToSubmitted: true,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitted, refreshHandler, reset]);

    const toggle = () => {
      setOpen(!isOpen);
    };

    const menu: DropdownOption[] = [
      {
        label: t`actionPanel.meetings.editMeeting`,
        isVisible: hasRequiredPermission(PERMISSION.EDIT_MEETING),
        onClick: setEditMode,
      },
      {
        label: t`delete`,
        isVisible: hasRequiredPermission(PERMISSION.DELETE_MEETING),
        onClick: () => deleteHandler(meeting.id),
      },
    ];

    if (!meeting) {
      return null;
    }

    if (isEditMode) {
      return (
        <Container
          name="meeting-collapsible-item"
          justify="space-between"
          direction="column"
          isClickable={false}
          padding="36px 40px"
          defaultRef={ref}
        >
          <FormProvider {...methods}>
            <MeetingForm
              onCancel={cancelEditMode}
              onSubmit={handleEditMeetingFormSubmit}
              withCompanyField={withCompanyField}
              withProjectField={withProjectField}
              withTeamField={withTeamField}
              submitButtonLabel={t`save`}
              isCompanyRequired={isCompanyRequired}
              context={context}
            />
          </FormProvider>
        </Container>
      );
    }

    return (
      <Container
        name="meeting-collapsible-item"
        data-testid={meeting.title}
        justify="space-between"
        direction="column"
        isClickable={!isOpen}
        padding="20px 5px 20px 20px"
        onMouseEnter={() => !isOpen && !isEditMode && setIsHovered(true)}
        onMouseLeave={() => !isOpen && !isEditMode && setIsHovered(false)}
        defaultRef={ref}
        onClick={() => !isOpen && !isEditMode && toggle()}
      >
        <Inline templateColumns="3fr 1fr" gap="10px">
          <Stack gap="2px" maxWidth="300px">
            <Title isClickable={isOpen} onClick={() => isOpen && toggle()}>
              {meeting.title}
            </Title>
            {withCompanyField && meeting.organization?.name && (
              <Inline gap="8px" alignItems="center" justifyContent="flex-start">
                <Logo
                  name={meeting.organization?.name}
                  logoUrl={meeting.organization.logoUrl}
                  singleLetter
                  bgColor={colors.accent.green.c3}
                  size="20px"
                  fontSize="9px"
                />
                <EllipsisTextTooltip
                  Component={<SubTitleLink />}
                  text={meeting.organization.name}
                  onClick={() =>
                    meeting.organization &&
                    openNewTab(
                      paths.company({ companyId: meeting.organization.id }),
                    )
                  }
                />
              </Inline>
            )}
            {withProjectField && meeting.project && (
              <EllipsisTextTooltip
                Component={<SubTitleLink />}
                text={meeting.project.name}
                onClick={() =>
                  meeting.project &&
                  openNewTab(paths.project({ projectId: meeting.project.id }))
                }
              />
            )}
            {showReadOnlyTeam && meeting.team && (
              <EllipsisTextTooltip
                Component={<SubTitleLink />}
                text={meeting.team.name}
                onClick={() =>
                  meeting.team &&
                  openNewTab(paths.team({ teamId: meeting.team.id }))
                }
              />
            )}
          </Stack>
          <Inline gap="4px" alignItems="center">
            {meeting.meetingDate && (
              <DateField>{formatDate(meeting.meetingDate)}</DateField>
            )}
            <ToggleIcon
              icon="NaviChevron"
              variant="ghost"
              color={colors.basics.black}
              isOpen={isOpen}
              onClick={toggle}
            />
            {isMenuShown && (
              <WithDropdown
                items={menu}
                options={{ placement: 'bottom-end' }}
                testId={meeting.title + '-dropdown-menu'}
              >
                <MenuButtonWithVisibilitySwitch
                  isVisible={isHovered || isOpen}
                />
              </WithDropdown>
            )}
          </Inline>
        </Inline>

        {isOpen && !isEditMode && (
          <Stack margin="36px 0 0 0" gap="36px">
            {Boolean(meeting.attendees.length) && (
              <Stack gap="8px">
                <Small>{t`actionPanel.meetings.attendees`}</Small>
                <Flexbox name="meeting-attendees" wrap="wrap" gap="8px">
                  {meeting.attendees.map(attendee => (
                    <Tooltip
                      key={attendee.id}
                      content={attendee.email}
                      visible={Boolean(attendee.email)}
                      maxWidth="500px"
                    >
                      <GeneralTag variant="blue" size="m">
                        {`${attendee.firstName} ${attendee.lastName}`}
                      </GeneralTag>
                    </Tooltip>
                  ))}
                </Flexbox>
              </Stack>
            )}

            {meeting.summary && (
              <UsersMentionTextField
                defaultValue={meeting.summary}
                readOnly
                projectId={meeting.project?.id}
                organizationId={meeting.organization?.id}
                teamId={meeting.team?.id}
                dataTestId="meetings-tab-edit-meeting-summary-input"
              />
            )}

            <MeetingFooter meeting={meeting} />
          </Stack>
        )}
      </Container>
    );
  },
);

MeetingCollapsibleItem.displayName = 'MeetingCollapsibleItem';

const Container = styled(Flexbox)<{ isClickable: boolean }>`
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray.c3};
  background-color: ${({ theme }) => theme.colors.basics.white};
  min-height: 85px;
  height: unset;
  ${({ isClickable, theme }) =>
    isClickable &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.blue.c9};
      }
  `}
`;

const Title = styled('h1')<{ isClickable: boolean }>`
  ${({ theme }) => theme.mixins.ellipsis}
  ${({ theme }) => theme.typography.actionsPanel.meetings.header}
  ${({ isClickable }) =>
    isClickable &&
    `
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
`}
`;

const SubTitleLink = styled(SmallText, forwardRef)`
  max-width: 300px;
  ${({ theme }) => theme.mixins.ellipsis}
  ${({ theme }) => theme.typography.actionsPanel.meetings.subTitle}
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const DateField = styled('h3')`
  ${({ theme }) => theme.typography.actionsPanel.meetings.date}
`;

export const Small = styled('h3')`
  ${({ theme }) => theme.typography.actionsPanel.meetings.small}
`;

const ToggleIcon = styled(IconButton)<{ isOpen: boolean }>`
  width: 20px;
  height: 20px;
  ${({ theme }) => theme.mixins.transition('transform')}
  transform: ${({ isOpen }) => (isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
`;
