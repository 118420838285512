import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { useSidebars } from '@/hooks/use-sidebars';

import type { ActionPanel } from './action-panel.state';
import { actionPanelState } from './action-panel.state';

export const useActionPanel = () => {
  const actionPanel = useRecoilValue(actionPanelState);
  const resetActionPanel = useResetRecoilState(actionPanelState);
  const setActionPanel = useSetRecoilState(actionPanelState);

  const { resetSidebar, toggleActionPanel, isActionPanelOpen } = useSidebars();

  const closeActionPanel = () => {
    resetActionPanel();
    resetSidebar();
  };

  const openActionPanel = (data: ActionPanel) => {
    toggleActionPanel();
    setActionPanel(data);
  };

  return {
    actionPanel,
    closeActionPanel,
    openActionPanel,
    isActionPanelOpen,
  };
};
