import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Modal } from '@/ui/modal/modal';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { SharedAssets } from '../search/shared-assets/shared-assets';
import { sharedAssetsUserIdState } from '../search/shared-assets/shared-assets.state';
import { useSharedCompanies } from '../search/shared-assets/use-shared-companies';
import { useSharedProjects } from '../search/shared-assets/use-shared-projects';

export const SharedAssetsModal = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'sharedAssets',
  });
  const modal = useRecoilValue(modalState);
  const setSharedAssetsUserId = useSetRecoilState(sharedAssetsUserIdState);
  const resetUserId = useResetRecoilState(sharedAssetsUserIdState);
  const { reset: resetSharedCompanies } = useSharedCompanies();
  const { reset: resetSharedProjects } = useSharedProjects();

  useEffect(() => {
    if (modal?.state === 'showSharedAssets' && modal?.userId) {
      setSharedAssetsUserId(modal.userId);
    }

    return () => {
      resetUserId();
      resetSharedProjects();
      resetSharedCompanies();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (modal?.state !== 'showSharedAssets') {
    return null;
  }

  return (
    <Modal
      CustomHeaderComponent={SubHeaderBold}
      header={t('assetsSharedWith', {
        user: modal.userDisplayName,
      })}
    >
      <SharedAssets />
    </Modal>
  );
};
