import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { FileGrantAccessPayload, FileRemoveAccessPayload } from '@/api';
import {
  removeAccessToTeamFile,
  shareTeamFile as shareTeamFileAPI,
} from '@/api/v3/files-api';
import { MutationKey, QueryKey } from '@/config/query-client';

export const useShareTeamFile = () => {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries([QueryKey.TeamFiles]);
  };

  const shareCompanyFileMutation = useMutation(shareTeamFileAPI, {
    mutationKey: [MutationKey.CompanyFileShare],
  });

  const shareTeamFile = (payload: FileGrantAccessPayload) =>
    shareCompanyFileMutation.mutateAsync(payload, {
      onSuccess,
    });

  const unshareCompanyFileMutation = useMutation(removeAccessToTeamFile, {
    mutationKey: [MutationKey.CompanyFileUnshare],
    onSuccess,
  });

  const unshareTeamFile = (payload: FileRemoveAccessPayload) =>
    unshareCompanyFileMutation.mutateAsync(payload);

  return {
    shareTeamFile,
    unshareTeamFile,
  };
};
