import type { RefObject } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { styled } from 'goober';

import {
  isEllipsis,
  isInvitedUserOption,
  isOfType,
  isUserOption,
} from '@/helpers/other';
import { useRoles } from '@/hooks/queries/use-roles.query';
import { colors } from '@/theme/colors';
import {
  APP_USER_ROLES,
  type InvitedUserOption,
  type IOption,
  type UserOption,
} from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import type { InviteOption } from '@/ui/select/async/components/option/option-users-teams';
import {
  OptionsList,
  OptionsListItem,
  OptionsListItemLabel,
} from '@/ui/select/async/components/selected-options-list/selected-options-list';
import { Select } from '@/ui/select/select';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { ResourceAccess } from '@/user/resource-access/resource-access.type';
import { useUserResourceAccess } from '@/user/resource-access/use-user-resource-access';

interface SelectedUserOptionProps {
  height?: string;
  list?: Array<UserOption | InviteOption>;
  listRef?: RefObject<HTMLDivElement>;
  isListExpanded?: boolean;
  onRemove?: (item: UserOption | InviteOption) => void;
}

const OPTION_ITEM_HEIGHT = 60;

export function SelectedUserOption({
  list,
  listRef,
  height,
  onRemove,
  isListExpanded = false,
}: SelectedUserOptionProps) {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);
  const { hasAccessToResource } = useUserResourceAccess();

  const { data: appRoles } = useRoles();

  const changeRole = (role: unknown, option: InvitedUserOption) => {
    if (!isOfType<IOption>(role, 'value')) return;

    option.role = { id: role.value, name: role.label };
  };

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  const rolesOptions = appRoles
    ? appRoles
        .filter(role => {
          if (
            role.name === APP_USER_ROLES.ADMIN &&
            !hasAccessToResource(ResourceAccess.USER_RESOURCES_INVITE_ADMIN)
          )
            return false;
          else if (
            role.name === APP_USER_ROLES.MANAGER &&
            !hasAccessToResource(ResourceAccess.USER_RESOURCES_INVITE_MANAGER)
          )
            return false;
          return true;
        })
        .map(role => ({
          value: role.id,
          label: role.name,
        }))
    : [];

  return (
    <OptionsListUsersTeams
      height={height}
      name="options-list"
      direction="column"
      defaultRef={listRef}
      isScrollable={Boolean(isListExpanded || onRemove)}
      maxHeight="360px"
    >
      {list?.map((item, index) => {
        return (
          <OptionsListUsersTeamsItem
            key={item.value}
            name="options-list-item"
            justify="space-between"
            alignItems="center"
          >
            <Tooltip
              placement="left"
              content={item.label}
              visible={isReady && isEllipsis(refItemLabel.current[index])}
            >
              <Flexbox name="option-content" gap="8px" alignItems="center">
                <LogoUser
                  name={item.label}
                  singleLetter={
                    isUserOption(item) &&
                    !Boolean(item.firstName && item.lastName)
                  }
                  bgColor={
                    isUserOption(item) && item.status !== 'invited'
                      ? colors.blue.primaryA
                      : colors.blue.primaryB
                  }
                />
                <ItemUsersTeamsLabel
                  ref={element => {
                    refItemLabel.current[index] = element;
                  }}
                >
                  {item.label}
                </ItemUsersTeamsLabel>
              </Flexbox>
            </Tooltip>
            <Flexbox name="actions" gap="20px" alignItems="center">
              {isInvitedUserOption(item) ? (
                <RoleDropdown
                  options={rolesOptions}
                  menuPosition="fixed"
                  defaultValue={
                    rolesOptions.find(role => role.value === item.role.id) ??
                    rolesOptions[0]
                  }
                  onChange={role => changeRole(role, item)}
                />
              ) : isUserOption(item) ? (
                item.role.name
              ) : null}

              {onRemove && (
                <Icon
                  icon="Close"
                  width="10px"
                  height="10px"
                  color={colors.gray.c13}
                  onClick={() => onRemove?.(item)}
                />
              )}
            </Flexbox>
          </OptionsListUsersTeamsItem>
        );
      })}
    </OptionsListUsersTeams>
  );
}

const OptionsListUsersTeams = styled(OptionsList)`
  max-height: 460px;

  &:not(:empty) {
    margin-top: 12px;
  }
`;

const OptionsListUsersTeamsItem = styled(OptionsListItem)`
  min-height: ${OPTION_ITEM_HEIGHT}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};
  ${({ theme }) => theme.typography.widget.paragraphSmall}

  [data-name='share-modal-container'] &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
  }
`;

const ItemUsersTeamsLabel = styled(OptionsListItemLabel, forwardRef)`
  ${({ theme }) => theme.typography.acrossPlatform.userName}

  [data-name='share-modal-container'] & {
    max-width: 560px;
  }

  &::after {
    content: attr(data-team);
    display: block;
    clear: left;
    margin-top: 2px;
    ${({ theme }) => theme.typography.widget.smallText}
    ${({ theme }) => theme.mixins.ellipsis}
  }
`;

const LogoUser = styled(Logo)`
  font-size: 12px;
`;

const RoleDropdown = styled(Select)`
  width: 120px;
`;
