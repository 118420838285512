import { EmptyState } from './components/empty-state';
import { InitialState } from './components/initial-state';
import { SearchLoadingSkeleton } from './components/search-loading-skeleton';
import { RecentSearchRecords } from './search-suggestions/user-recent-search/recent-search-records';
import { useRecentSearchRecords } from './search-suggestions/user-recent-search/use-recent-search-records.query';
import { SearchResults } from './search-results';
import { useSearchResults } from './use-search-results.query';

import 'react-loading-skeleton/dist/skeleton.css';

export const SearchContent = ({
  isInitiallyEmpty,
  searchPhrase,
}: {
  isInitiallyEmpty: boolean;
  searchPhrase: string | null;
}) => {
  const { data: searchResults, isFetching: isFetchingSearchResults } =
    useSearchResults({ searchPhrase });
  const {
    hasUserSomeRecentSearchRecords,
    data: recentSearchRecords,
    isLoading: isLoadingRecentSearchRecords,
  } = useRecentSearchRecords();
  const isLoading = isFetchingSearchResults || isLoadingRecentSearchRecords;

  const results = searchResults ?? [];
  const hasNoResults = results.length === 0;

  const recentRecords = recentSearchRecords ?? [];

  if (hasUserSomeRecentSearchRecords && !searchPhrase) {
    return <RecentSearchRecords records={recentRecords} />;
  }

  if ((isInitiallyEmpty || !searchPhrase) && !isLoading) {
    return <InitialState />;
  }

  if (isLoading) {
    return <SearchLoadingSkeleton columns={6} />;
  }

  if (hasNoResults && searchPhrase) {
    return <EmptyState searchPhrase={searchPhrase} />;
  }

  return <SearchResults results={results} />;
};
