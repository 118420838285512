import { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseQueryResult } from '@tanstack/react-query';
import { styled } from 'goober';

import type { FileGrantAccessPayload, FileRemoveAccessPayload } from '@/api';
import { ActionPanelDate } from '@/features/action-panel/action-panel-date';
import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { IndexParams } from '@/types';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

import { EditFile } from './edit-file';
import type {
  File,
  FileLinkDownloadHandler,
  FileScanning,
} from './file.interface';
import { FileIcon } from './file-icon';
import { UploadingFileCard } from './uploading-file-card';
import type { UsersWithAccessToFileEntityType } from './user-with-access-to-file.state';

interface FileProps {
  file: File;
  entityType: UsersWithAccessToFileEntityType;
  allowToChangeFilePrivacy: boolean;
  editedFileId: string | null;
  setEditedFileId: (fileId: string | null) => void;
  onClickDelete: () => void;
  sharingParams: IndexParams;
  onShareFile: (payload: FileGrantAccessPayload) => Promise<void>;
  onUnshareFile: (payload: FileRemoveAccessPayload) => Promise<void>;
  onFileLinkDownload: FileLinkDownloadHandler;
  useFileScanningStatus: (
    file: File,
  ) => UseQueryResult<FileScanning | undefined>;
}

export function FileCard({
  file,
  entityType,
  allowToChangeFilePrivacy,
  editedFileId,
  setEditedFileId,
  sharingParams,
  onClickDelete,
  onShareFile,
  onUnshareFile,
  onFileLinkDownload,
  useFileScanningStatus,
}: FileProps) {
  const { t } = useTranslation('default');
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useFileScanningStatus(file);

  useLayoutEffect(() => {
    setShowTooltip(isEllipsis(ref.current));
  }, [ref]);

  const menu: DropdownOption[] = [
    {
      label: t`actionPanel.files.editProperties`,
      onClick: () => setEditedFileId(file.id),
      icon: 'Edit',
      isVisible: allowToChangeFilePrivacy,
    },
    {
      label: t`actionPanel.files.delete`,
      onClick: onClickDelete,
      icon: 'Bin',
    },
  ];

  if (file.id === editedFileId) {
    return (
      <EditFile
        file={file}
        sharingParams={sharingParams}
        onCancel={() => setEditedFileId(null)}
        onShareFile={onShareFile}
        onUnshareFile={onUnshareFile}
        entityType={entityType}
      />
    );
  }

  if (!file.scanningStatus || file.scanningStatus === 'unscanned') {
    return <UploadingFileCard file={file} />;
  }

  const downloadDisabled = file.scanningStatus === 'error';

  return (
    <Flexbox name="file-item-container" justify="space-between" grow={1}>
      <Flexbox name="file-name" direction="column" gap="8px">
        <Tooltip
          placement="left"
          content={file.name}
          visible={showTooltip}
          maxWidth={'none'}
        >
          <FileNameLink
            onClick={
              downloadDisabled
                ? undefined
                : () => onFileLinkDownload(file, 'preview')
            }
            disabled={downloadDisabled}
          >
            <FileIcon
              filename={file.name}
              color={downloadDisabled ? colors.gray.c8 : undefined}
            />
            <FileName ref={ref}>{file.name}</FileName>
          </FileNameLink>
        </Tooltip>
        <ActionPanelDate date={file.createdDate} relative />
      </Flexbox>

      <Flexbox
        name="file-actions-container"
        direction="column"
        justify="space-between"
      >
        <FileActions
          name="file-actions"
          justify="space-between"
          alignItems="center"
        >
          <FileIconLink
            onClick={
              downloadDisabled
                ? undefined
                : () => onFileLinkDownload(file, 'download')
            }
            disabled={downloadDisabled}
          >
            <Icon icon="ArrowDownFromBracket" color={colors.basics.black} />
          </FileIconLink>

          <WithDropdown items={menu} options={{ placement: 'bottom-end' }}>
            <MenuButton />
          </WithDropdown>
        </FileActions>
        <PrivacyLabelContainer
          name="file-status"
          alignItems="center"
          gap="4px"
          allowToChangeFilePrivacy={allowToChangeFilePrivacy}
        >
          <Icon icon={file.public ? 'StatusPublic' : 'StatusPrivate'} />
          <SmallText color={colors.blue.primaryA}>
            {t(file.public ? 'share.public' : 'share.private')}
          </SmallText>
        </PrivacyLabelContainer>
      </Flexbox>
    </Flexbox>
  );
}

const PrivacyLabelContainer = styled(Flexbox)<{
  allowToChangeFilePrivacy: boolean;
}>`
  visibility: ${({ allowToChangeFilePrivacy }) =>
    allowToChangeFilePrivacy ? 'visible' : 'hidden'};
`;

const FileName = styled('p', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 380px;
`;

const FileLink = styled('div')`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const FileNameLink = styled(FileLink)<{ disabled: boolean }>`
  ${({ theme }) => theme.typography.actionsPanel.files.fileName}
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ disabled, theme }) =>
    disabled &&
    `
        color: ${theme.colors.gray.c8};
        pointer-events: none;
        cursor: default;
        &:hover {
          text-decoration: none;
        }
      `};
`;

const FileIconLink = styled(FileLink)<{ disabled: boolean }>`
  visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
`;

const FileActions = styled(Flexbox)`
  height: 15px;
  [data-name='action-panel-item']:not(:hover)
    &
    .is-closed
    [data-name='menu-button'] {
    position: absolute;
    height: 0;
    width: 0;
  }
`;
