import { useRecoilState, useResetRecoilState } from 'recoil';

import type { Sidebar } from '../state/sidebar.state';
import { sidebarState } from '../state/sidebar.state';

export const useSidebars = () => {
  const [sidebar, setCurrentSidebar] = useRecoilState(sidebarState);
  const resetSidebar = useResetRecoilState(sidebarState);

  const toggleSelectedSidebar = (selectedSidebar: Sidebar) => {
    if (sidebar === selectedSidebar) {
      return setCurrentSidebar(null);
    }
    setCurrentSidebar(selectedSidebar);
  };

  const toggleNavbar = () => toggleSelectedSidebar('Navbar');
  const toggleSectionsPane = () => toggleSelectedSidebar('SectionsPane');
  const toggleActionPanel = () => toggleSelectedSidebar('ActionsPanel');

  return {
    resetSidebar,
    toggleNavbar,
    toggleSectionsPane,
    toggleActionPanel,
    isNavbarOpen: sidebar === 'Navbar',
    isSectionsPaneOpen: sidebar === 'SectionsPane',
    isActionPanelOpen: sidebar === 'ActionsPanel',
  };
};
