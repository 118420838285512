import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteTeam } from '@/api/v4/team.api';
import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { notify } from '@/ui/snackbar/notify';

export const useDeleteTeam = () => {
  const { t } = useTranslation(['teams, default']);
  const queryClient = useQueryClient();
  const navigateTo = useNavigateTo();

  const deleteProjectMutation = useMutation(deleteTeam, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Teams]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);

      navigateTo.teams();
    },
    onError: () => {
      notify({
        message: t`default:unknownError`,
      });
    },
  });

  return {
    ...deleteProjectMutation,
    isError: deleteProjectMutation.isError,
  };
};
