import InfiniteScroll from 'react-infinite-scroll-component';
import { css } from 'goober';

import { theme } from '@/theme/setupTheme';
import type { IconVariant } from '@/ui/icons/icon';

import type { SharedAssetItem } from './shared-assets/shared-assets.state';
import { SEARCH_ITEM_HEIGHT, SearchItem } from './search-item';
import { SkeletonSearchItem } from './skeleton-search-item';

export const InfiniteAssetsList = ({
  items,
  hasMore,
  next,
  isLoading,
  defaultIcon,
  onClickHandler,
}: {
  items: SharedAssetItem[];
  hasMore: boolean;
  isLoading: boolean;
  next: () => void;
  defaultIcon?: IconVariant;
  onClickHandler?: (id: string) => void;
}) => {
  if (!items.length) {
    return null;
  }

  return (
    <InfiniteScroll
      dataLength={items.length}
      hasMore={hasMore}
      next={() => (hasMore ? next() : undefined)}
      loader={isLoading ? <SkeletonSearchItem /> : null}
      height={items.length >= 5 ? `${SEARCH_ITEM_HEIGHT * 5}px` : 'auto'}
      className={ScrollClassName}
    >
      {items.map(item => {
        return (
          <SearchItem
            key={item.id}
            label={item.label}
            defaultIcon={defaultIcon}
            onClick={onClickHandler ? () => onClickHandler(item.id) : undefined}
            logoUrl={item.logoUrl}
          />
        );
      })}
    </InfiniteScroll>
  );
};

const ScrollClassName = css`
  ${theme.mixins.scrollbar}
`;
