import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import type { MeetingEditPayload } from '@/api/v4/meetings.api';
import { editMeeting as editMeetingAPI } from '@/api/v4/meetings.api';
import { notify } from '@/ui/snackbar/notify';

export const useEditMeeting = () => {
  const { t } = useTranslation('default');

  const editMeetingMutation = useMutation(editMeetingAPI, {
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
  });

  const editMeeting = (payload: MeetingEditPayload) =>
    editMeetingMutation.mutateAsync(payload);

  return {
    editMeeting,
    isError: editMeetingMutation.isError,
  };
};
