import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { Loader } from '@/components/loader/loader';
import { Stack } from '@/ui/line/line';

import { InfiniteAssetsList } from '../infinite-assets-list';
import { useNavigateToSearchItemPage } from '../use-navigate-to-search-item-page';

import { EmptyState } from './empty-state';
import { useSharedCompanies } from './use-shared-companies';
import { useSharedProjects } from './use-shared-projects';

export const SharedAssets = () => {
  const { t } = useTranslation('settings', {
    keyPrefix: 'sharedAssets',
  });
  const {
    companies,
    hasNextPage: hasCompaniesNextPage,
    setNextPage: setCompaniesNextPage,
    isInitialLoading: isInitialLoadingCompanies,
    isLoading: isLoadingCompanies,
  } = useSharedCompanies();
  const {
    projects,
    hasNextPage: hasProjectsNextPage,
    setNextPage: setProjectsNextPage,
    isInitialLoading: isInitialLoadingProjects,
    isLoading: isLoadingProjects,
  } = useSharedProjects();
  const navigateToSearchItemPage = useNavigateToSearchItemPage();

  const isInitialLoading =
    isInitialLoadingCompanies || isInitialLoadingProjects;
  const hasNoSharedAssets =
    !isInitialLoading && !companies.length && !projects.length;

  if (hasNoSharedAssets) {
    return <EmptyState />;
  }

  return (
    <Container gap="28px" isLoading={isInitialLoading}>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <>
          {Boolean(projects.length) && (
            <div>
              <ListTitle>{t`projects`}</ListTitle>
              <InfiniteAssetsList
                items={projects}
                hasMore={hasProjectsNextPage}
                next={setProjectsNextPage}
                defaultIcon="ProjectLogoDefault"
                onClickHandler={id =>
                  navigateToSearchItemPage({ type: 'project', id })
                }
                isLoading={isLoadingProjects}
              />
            </div>
          )}
          {Boolean(companies.length) && (
            <div>
              <ListTitle>{t`companies`}</ListTitle>
              <InfiniteAssetsList
                items={companies}
                hasMore={hasCompaniesNextPage}
                next={setCompaniesNextPage}
                onClickHandler={id =>
                  navigateToSearchItemPage({ type: 'company', id })
                }
                isLoading={isLoadingCompanies}
              />
            </div>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled(Stack)<{ isLoading?: boolean }>`
  margin-top: 12px;
  ${({ isLoading }) => isLoading && `height: 300px;`}
`;

const ListTitle = styled('h5')`
  ${({ theme }) => theme.typography.companyInfo.tableHeader}
`;
