import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilRefresher_UNSTABLE } from 'recoil';

import type { DeleteCompaniesPayload } from '@/api/v4/organization.api';
import { deleteCompanies as deleteCompaniesAPI } from '@/api/v4/organization.api';
import { QueryKey } from '@/config/query-client';

import { companiesKPIsState } from '../../companies/companies-kpi.state';

export const useDeleteCompanies = () => {
  const queryClient = useQueryClient();
  const refreshKPI = useRecoilRefresher_UNSTABLE(companiesKPIsState);

  const deleteCompaniesMutation = useMutation(deleteCompaniesAPI, {
    onSuccess: async () => {
      refreshKPI();
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.Companies]),
        queryClient.invalidateQueries([QueryKey.CompanyOptionsCount]),
        queryClient.invalidateQueries([QueryKey.CompaniesStatistics]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const deleteCompanies = (payload: DeleteCompaniesPayload) =>
    deleteCompaniesMutation.mutateAsync(payload);

  return {
    deleteCompanies,
    isLoading: deleteCompaniesMutation.isLoading,
    isError: deleteCompaniesMutation.isError,
  };
};
