import { selectorFamily } from 'recoil';

import {
  getFileGrantedUsers,
  getTeamFileGrantedUsers,
} from '@/api/v3/files-api';
import { mapJSONToEntity } from '@/features/companies/overview/action-panel/company-action-panel.state';
import type { PersonOption, TeamOptionV2 } from '@/types';

export type UsersWithAccessToFileEntityType =
  | 'file_organization'
  | 'team_file'
  | 'project_file';

export const usersWithAccessToFileState = selectorFamily<
  (PersonOption | TeamOptionV2)[],
  { fileId: string; entityType: UsersWithAccessToFileEntityType }
>({
  key: 'usersWithAccessToFileState',
  get:
    ({ fileId, entityType }) =>
    async () => {
      let request;
      if (entityType === 'file_organization') request = getFileGrantedUsers;
      if (entityType === 'team_file') request = getTeamFileGrantedUsers;

      if (!request) return [];

      const response = await request?.(fileId);
      return mapJSONToEntity(response?.data) || [];
    },
});
