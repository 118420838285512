import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import type { TeamCommentPayload } from '@/api/v4/team-comments.api';
import { createTeamComment } from '@/api/v4/team-comments.api';
import { notify } from '@/ui/snackbar/notify';

type CreateTeamCommentMutationParams = {
  teamId: string;
  payload: TeamCommentPayload;
};

export const useCreateTeamComment = () => {
  const { t } = useTranslation('default');

  return useMutation(
    async ({ teamId, payload }: CreateTeamCommentMutationParams) => {
      return createTeamComment(teamId, payload);
    },
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
