import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import { teamIdState } from '@/features/teams/team.state';
import type { InvitedUserOption, UserOption } from '@/types';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Modal as ModalComponent } from '@/ui/modal/modal';
import { getLabel } from '@/ui/select/async/components/option/option-users-teams';
import { notify } from '@/ui/snackbar/notify';
import { SubHeaderBold } from '@/ui/typography/widgets';

import { InvitedUsersSelect } from './invited-users-select';
import { useAddOrInviteTeamMember } from './use-add-or-invite-team-member.mutation';

export function AddTeamMemberModal() {
  const { t } = useTranslation('default');
  const [invitedList, setInvitedList] = useState<
    Array<UserOption | InvitedUserOption>
  >([]);
  const teamId = useRecoilValue(teamIdState);

  const { addOrInviteTeamMember } = useAddOrInviteTeamMember();

  const handleSubmit = useCallback(async () => {
    if (!teamId) return;

    await addOrInviteTeamMember({
      users: invitedList.flatMap(item =>
        item.role && item.email
          ? {
              email: item.email,
              roleId: item.role.id,
              teamId,
            }
          : [],
      ),
    });

    const message =
      invitedList.length === 1
        ? t('invite.wasAddedToTeam', { user: getLabel(invitedList[0]) })
        : t('invite.wereAddedToTeam', { count: invitedList.length });

    notify({ message, withIcon: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedList, teamId, t]);

  return (
    <Modal
      CustomHeaderComponent={SubHeaderBold}
      header={t`invite.addTeamMember`}
    >
      <ContentContainer
        name="invite-modal-container"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        gap="28px"
        width="824px"
      >
        <InvitedUsersSelect
          onChange={setInvitedList}
          placeholder={t`invite.placeholderTeam`}
          invitedList={invitedList}
        />

        <Flexbox
          name="modal-footer"
          justify="flex-end"
          alignItems="flex-end"
          gap="20px"
        >
          <Button
            onClick={handleSubmit}
            disabled={!Boolean(invitedList.length)}
          >
            {t`add`}
          </Button>
        </Flexbox>
      </ContentContainer>
    </Modal>
  );
}

const Modal = styled(ModalComponent)`
  max-height: 708px;
`;

const ContentContainer = styled(Flexbox)<{ width: string }>`
  margin-top: 16px;
  width: ${({ width }) => width};
`;
