import type { IRow } from '@/ui/table/table.types';

import type { SkeletonRowProps } from './skeleton-row';
import { SkeletonRow } from './skeleton-row';
import { TableRow } from './table-row';

export const INFINITE_TABLE_ROW_HEIGHT = 76;

interface Props {
  id?: string;
  rows: IRow[];
  skeletonConfig?: SkeletonRowProps;
  isLoading?: boolean;
  borderColor?: string;
  rowCursor?: string;
}

export const TableBody = ({
  id,
  rows,
  isLoading,
  skeletonConfig,
  borderColor,
  rowCursor,
}: Props) => {
  return (
    <tbody id={id} data-testid={id}>
      {rows.map(row => (
        <TableRow
          id={id ? `${id}-row-${row.id}` : undefined}
          key={row.id}
          row={row}
          borderColor={borderColor}
          bgColor={row.rowBackgroundColor}
          rowCursor={rowCursor}
        />
      ))}
      {isLoading && skeletonConfig && <SkeletonRow {...skeletonConfig} />}
    </tbody>
  );
};
