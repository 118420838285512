import type { ProjectRequestParams } from '@/api/v4/projects.api';
import type { IndexParams } from '@/types';

export const resolveSharingParamsCompanyFile = (
  entityToShareId: string,
): IndexParams => ({
  filter: ['without_file'],
  without_file: entityToShareId,
});

export const resolveSharingParamsTeamFile = (
  entityToShareId: string,
): IndexParams => ({
  filter: ['team', 'without_team_file'],
  team: [entityToShareId],
  without_team_file: entityToShareId,
});

export const resolveParamAvailableProjects = (
  entityToShareId: string,
): ProjectRequestParams => ({
  excludeOrganizations: [entityToShareId],
});
