import { atom, atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import type { ISort } from '@/ui/table/table.types';

import { getListingStageByDefaultName } from '../listing-stage/helpers/get-listing-stage-by-default-name';
import { getListingStages } from '../listing-stage/helpers/get-listing-stages';
import { ListingStageName } from '../listing-stage/types';

import type { ProjectListingsDisplayMode } from './project-listings/project-listings';
import type { TabStage } from './project-listings/stage-management-table/use-stage-tabs-config';

const { persistAtom } = recoilPersist();

const listingStages = getListingStages();

export const projectListingSortState = atom<ISort>({
  key: 'projectListingSortState',
  default: {
    sort: 'createdDate',
    direction: 'desc',
  },
});

export const projectWorkmodeActiveTabState = atom<TabStage['id']>({
  key: 'projectWorkmodeActiveTabState',
  default: getListingStageByDefaultName(
    ListingStageName.discovery,
    listingStages,
  )?.id,
});

export const projectListingsDisplayModeState = atomFamily<
  ProjectListingsDisplayMode,
  string[]
>({
  key: 'projectListingsDisplayModeState',
  default: 'table',
  effects_UNSTABLE: [persistAtom],
});
