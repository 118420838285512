import type { MouseEvent } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { styled } from 'goober';

import {
  isEllipsis,
  isInviteOption,
  isOption,
  isPersonOption,
  isTeamOption,
  isUserOption,
} from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { TeamOption, UserOption } from '@/types';
import { type IOption, type PersonOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo, SquareLogo } from '@/ui/logo/logo';
import {
  OptionContainer,
  OptionLabel,
} from '@/ui/select/async/async-select.styles';
import { SLIDING_SIDEBAR_WIDTH } from '@/ui/sliding-sidebar/sliding-sidebar';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

import type { AsyncSelectOptionProps } from './option';

export interface InviteOption extends IOption {
  email?: string;
  role?: IOption;
  type: 'invite';
}

export const getLabel = (
  data: UserOption | PersonOption | TeamOption | unknown,
) => {
  if (isUserOption(data) && !Boolean(data.firstName) && !Boolean(data.lastName))
    return data.email;
  if (
    isUserOption(data) ||
    isPersonOption(data) ||
    isTeamOption(data) ||
    isInviteOption(data)
  )
    return data.label ?? '';
  return '';
};

export function AsyncSelectOptionUsersTeams({
  ...props
}: AsyncSelectOptionProps) {
  const { t } = useTranslation('default');
  const { withCheckIconOnSelect = true } = props;
  const ref = useRef<HTMLDivElement>(null);

  const handleInviteActions = (event: MouseEvent<HTMLElement>) => {
    if ((event.target as HTMLElement).tagName.toLowerCase() === 'div') {
      event.stopPropagation();
    }
  };

  return (
    <components.Option {...props}>
      <Tooltip
        placement="left"
        maxWidth={`calc(100vw - ${SLIDING_SIDEBAR_WIDTH})`}
        content={
          isOption(props.data) &&
          (isPersonOption(props.data)
            ? `${props.data.label}${
                props.data.team ? ` (${props.data.team.name})` : ''
              }`
            : props.data.label)
        }
        visible={isEllipsis(ref.current)}
      >
        <OptionContainer
          isSelected={props.isSelected}
          style={{ height: '60px' }}
        >
          {isPersonOption(props.data) && (
            <LogoUser
              name={getLabel(props.data)}
              singleLetter={
                !Boolean(props.data.firstName && props.data.lastName)
              }
              bgColor={
                props.data.status === 'invited'
                  ? colors.blue.primaryB
                  : colors.blue.primaryA
              }
            />
          )}
          {isTeamOption(props.data) && (
            <SquareLogo
              name={props.data.label ?? ''}
              logoUrl={props.data.organization?.logoUrl}
            />
          )}
          {isInviteOption(props.data) && props.data.email && (
            <LogoUser
              name={getLabel(props.data)}
              bgColor={colors.blue.primaryB}
            />
          )}
          <OptionLabel
            ref={ref}
            {...(isPersonOption(props.data) &&
              props.data.team && { 'data-team': props.data.team.name })}
            {...(isInviteOption(props.data) &&
              !props.data.email && { color: colors.system.disable })}
          >
            {getLabel(props.data)}
            <TeamName>
              {isPersonOption(props.data) && props.data.team?.name}
            </TeamName>
          </OptionLabel>
          {isPersonOption(props.data) && props.data.role?.label && (
            <SmallText>{props.data.role.label}</SmallText>
          )}
          {withCheckIconOnSelect && props.isSelected && (
            <Icon icon="Check" width="18px" />
          )}
          {isInviteOption(props.data) && Boolean(props.data.email) && (
            <Flexbox
              name="invite-actions"
              alignItems="center"
              gap="10px"
              onClick={handleInviteActions}
            >
              <SmallText
                color={colors.blue.primaryA}
              >{t`invite.invite`}</SmallText>
            </Flexbox>
          )}
        </OptionContainer>
      </Tooltip>
    </components.Option>
  );
}

const LogoUser = styled(Logo)`
  font-size: 12px;
`;

const TeamName = styled(SmallText)`
  height: 0;
  opacity: 0;
`;
