import type { IGetRefs } from '@/hooks/use-multi-refs';
import { Flexbox } from '@/ui/flexbox/flexbox';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';

import type { Comment, IEditCommentCallback } from './comment.type';
import { CommentsList } from './comments-list';
import { InsertCommentSection } from './insert-comment-section';

export const CommentsTab = ({
  onSubmit,
  onClickDelete,
  onEditSubmit,
  refCommentId,
  resetCommentId,
  comments,
  loading,
  dropdown,
}: {
  onSubmit: (comment: string, mentionedUsers: number[]) => void;
  onClickDelete: (commentId: string) => void;
  onEditSubmit: IEditCommentCallback;
  comments: Comment[];
  refCommentId: string | null;
  resetCommentId: () => void;
  loading: boolean;
  dropdown?: AsyncSelectProps;
}) => {
  const scrollToLastModifiedTag: IGetRefs = refs => {
    if (!refCommentId) return;

    const commentNode = refs[refCommentId];

    if (!commentNode) return;

    commentNode.focus();
    commentNode.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
      inline: 'nearest',
    });
    resetCommentId();
  };

  return (
    <Flexbox name="comments-container" direction="column" grow={1}>
      <CommentsList
        comments={comments}
        onClickDelete={onClickDelete}
        onEditSubmit={onEditSubmit}
        getRefs={scrollToLastModifiedTag}
        loading={loading}
        dropdown={dropdown}
      />
      <InsertCommentSection onSubmit={onSubmit} />
    </Flexbox>
  );
};
