import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { fetchSearchSuggestions } from '@/api/v4/search.api';
import { QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

export const useSearchResults = ({
  searchPhrase,
}: {
  searchPhrase: string | null;
}) => {
  const { t } = useTranslation('default');

  const fetch = () => {
    if (!searchPhrase) return [];
    return fetchSearchSuggestions(searchPhrase);
  };

  return useQuery([QueryKey.Search, searchPhrase], fetch, {
    onError: () =>
      notify({
        message: t`unknownError`,
      }),
    staleTime: 1000 * 10,
    enabled: !!searchPhrase,
  });
};
