import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { IconButton } from '@/ui/button/icon-button';

import { Tooltip } from '../tooltip/tooltip';

type SavvyIconButtonWebURLProps = {
  hideTooltip?: boolean;
  url: string;
};

export const SavvyIconButtonWebURL = ({
  hideTooltip,
  url,
}: SavvyIconButtonWebURLProps) => {
  return (
    <Tooltip content={url} disabled={hideTooltip}>
      <StyledIconButton
        icon="Globe"
        variant="ghost"
        color={colors.basics.black}
        onClick={() => window.open(url, '_blank')}
      />
    </Tooltip>
  );
};

const StyledIconButton = styled(IconButton)`
  padding: 0px;
  &:hover {
    background-color: transparent;
  }
`;
