import type {
  IResponse,
  IResponseBase,
  IResponseObjectRelationship,
} from '@/api';
import { getIncludedByRelationship } from '@/lib/api-object-merger';
import type { PersonOption, TeamOption } from '@/types';

// todo: move it somewhere
export const mapJSONToEntity = (response: {
  app_users: IResponse;
  teams: IResponse;
}): (PersonOption | TeamOption)[] => {
  const { app_users: appUsers, teams } = response;
  let usersOptions: PersonOption[] = [];
  let teamsOptions: TeamOption[] = [];

  const { included: usersIncluded, data: usersData } = appUsers;

  if (Array.isArray(usersData)) {
    usersOptions = usersData.map(user => {
      const {
        id,
        attributes: {
          first_name: firstName,
          last_name: lastName,
          email,
          role_name: roleName,
          role_id: roleId,
        },
      } = user;
      const [team] = getIncludedByRelationship(user, usersIncluded, 'team');
      return {
        value: id.toString(),
        label: firstName && lastName ? `${firstName} ${lastName}` : email || '',
        type: 'person',
        firstName,
        lastName,
        email,
        role: { label: roleName, value: roleId },
        name: `${firstName || ''} ${lastName || ''}`,
        team: team
          ? {
              id: team.id,
              name: team.attributes.name,
            }
          : undefined,
      };
    });
  }

  const { included: teamsIncluded, data: teamsData } = teams;

  if (Array.isArray(teamsData)) {
    teamsOptions = teamsData.map(team => {
      const {
        id,
        attributes: { name, app_users_count: usersCount },
      } = team;
      const [organization] = getIncludedByRelationship(
        team,
        teamsIncluded,
        'organization',
      );
      const users: TeamOption['users'] = teamsIncluded.filter(item =>
        (
          (team.relationships.app_users as IResponseObjectRelationship)
            .data as IResponseBase[]
        ).some(user => user.id === item.id),
      );
      return {
        value: id,
        label: name,
        usersCount,
        users,
        organization: organization
          ? {
              id: organization.id,
              domain: organization.attributes.domain,
              name: organization.attributes.name,
              logoUrl: organization.attributes.logo_url,
            }
          : undefined,
        type: 'team',
      };
    });
  }

  return [...usersOptions, ...teamsOptions];
};
