import type { OrganizationContact } from '@/api/v4/organization-contacts.api';

export const sortContactsByPrimaryAndFirstName = (
  contacts: OrganizationContact[],
) => {
  return contacts.sort((a, b) => {
    // Sort by is_primary (true first)
    if (a.isPrimary && !b.isPrimary) {
      return -1;
    } else if (!a.isPrimary && b.isPrimary) {
      return 1;
    }

    // If is_primary is the same, sort by first_name
    return a.firstName.localeCompare(b.firstName);
  });
};
