import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  createProjectComment,
  deleteProjectComment,
  updateProjectComment,
} from '@/api/v4/project-comments.api';
import { fetchProjectCompanies } from '@/api/v4/projects.api';
import { CommentsTab } from '@/features/action-panel/comments/comments-tab';
import { modalState } from '@/state/modal.state';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';
import { notify } from '@/ui/snackbar/notify';

import { projectIdState } from '../../project.state';

import { useProjectComments } from './use-project-comments.mutation';
import { useProjectCompanies } from '../../use-project-companies.query';

export const ProjectCommentsTab = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);
  const { data: projectCompanies, isFetched } = useProjectCompanies(projectId);

  const [refCommentId, setRefCommentId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const setModal = useSetRecoilState(modalState);

  const { data: comments, refetch } = useProjectComments();

  if (!projectId) return null;

  const submitComment = async (comment: string, mentionedUsers: number[]) => {
    const data = await createProjectComment({
      comment,
      projectId,
      mentionedUsers,
    });

    setRefCommentId(data.id);
    await refetch();
  };

  const openDeleteModal = async (commentId: string) => {
    setModal({
      state: 'confirmDelete',
      onConfirm: () => deleteComment(commentId),
    });
  };

  const deleteComment = async (commentId: string) => {
    setLoading(true);
    await deleteProjectComment(commentId);

    await refetch();
    setLoading(false);
  };

  const editComment = async (
    commentId: string,
    data: {
      comment: string;
      projectListingId?: string | null;
      mentionedUsers: number[];
    },
  ) => {
    setLoading(true);

    try {
      setRefCommentId(commentId);
      await updateProjectComment({
        commentId,
        comment: data.comment,
        ...(data.projectListingId !== undefined && {
          projectListingId: data.projectListingId,
        }),
        mentionedUsers: data.mentionedUsers,
      });

      await refetch();
    } catch (error) {
      notify({ message: t`unknownError` });
    } finally {
      setLoading(false);
    }
  };

  const setOptions: CustomAsyncSelectProps['setOptions'] = async search => {
    const response = await fetchProjectCompanies(projectId, {
      search,
    });

    return response.map(item => ({
      value: item.projectListingId,
      label: item.name,
      logo: item.logoUrl,
      domain: item.domain,
      organizationId: item.id,
      projectListingId: item.projectListingId,
    }));
  };

  return (
    <CommentsTab
      onSubmit={submitComment}
      comments={comments ?? []}
      onClickDelete={openDeleteModal}
      onEditSubmit={editComment}
      refCommentId={refCommentId}
      resetCommentId={() => setRefCommentId(null)}
      loading={loading}
      dropdown={{
        setOptions,
        isDisabled: isFetched && !projectCompanies?.length,
      }}
    />
  );
};
