import { selector } from 'recoil';

import { fetchProjectKPIs, type ProjectKPIs } from '@/api/v4/projects.api';

import { prepareProjectApiFilters } from './filters/project-filters-helpers';
import { appliedProjectsFiltersState } from './project.state';

export const projectsKPIsState = selector<ProjectKPIs>({
  key: 'projectsKPIsState',
  get: async ({ get }) => {
    const appliedFilters = get(appliedProjectsFiltersState);

    return fetchProjectKPIs({
      ...prepareProjectApiFilters(appliedFilters),
    });
  },
});
