import { useLogout } from '@/auth/hooks/use-logout.mutation';
import { classList } from '@/helpers/class-list';
import { useSidebars } from '@/hooks/use-sidebars';

import { DropdownSettings } from './dropdowns/dropdown-settings';
import { TooltipDropdownCreate } from './dropdowns/tooltip-dropdown-create';
import { Logo } from './logo';
import {
  NavContainer,
  NavMenuContainer,
  NavSeparator,
  NavToggle,
} from './navbar.styles';
import { NavMenu } from './navmenu';

interface NavbarProps {
  isFixed?: boolean;
}

export const Navbar = ({ isFixed = false }: NavbarProps) => {
  const logout = useLogout();
  const { isNavbarOpen, toggleNavbar } = useSidebars();

  return (
    <NavContainer
      name="app-sidebar"
      direction="column"
      justify="space-between"
      padding="19px 14px 30px 14px"
      className={classList(isNavbarOpen && 'is-open', isFixed && 'is-fixed')}
    >
      <div>
        <Logo />
        <NavMenu />
      </div>
      <div>
        <NavMenuContainer>
          <TooltipDropdownCreate />
          <DropdownSettings onLogout={logout} />
        </NavMenuContainer>
        <NavSeparator />
        <NavToggle icon="NaviChevron" onClick={toggleNavbar} />
      </div>
    </NavContainer>
  );
};
