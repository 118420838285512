import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type { CreateOrganizationCommentPayload } from '@/api/v4/organization-comments.api';
import { createOrganizationComment } from '@/api/v4/organization-comments.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { projectIdState } from '@/features/projects/project.state';
import { teamIdState } from '@/features/teams/team.state';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';

export const useCreateCompanyComment = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);
  const projectId = useRecoilValue(projectIdState);
  const teamId = useRecoilValue(teamIdState);

  const createCompanyCommentMutation = useMutation(createOrganizationComment, {
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QueryKey.CompanyComments,
        companyId,
        projectId,
        teamId,
      ]);
    },
  });

  const createComment = (payload: CreateOrganizationCommentPayload) =>
    createCompanyCommentMutation.mutateAsync(payload);

  return {
    createComment,
    isError: createCompanyCommentMutation.isError,
  };
};
