import { forwardRef, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { generatedShortDescriptionsState } from '@/features/explore/explore.state';
import { SmallText } from '@/ui/typography/widgets';

import { useCreateCompanyDescription } from './use-create-company-description-mutation';

type CompanyDescriptionLoaderProps = {
  companyName: string;
  companyWebsite?: string;
  shortDescription?: string;
  requestId: string;
  resultNumber: number;
};

export const CompanyDescriptionLoader = ({
  companyName,
  companyWebsite,
  shortDescription,
  requestId,
  resultNumber,
}: CompanyDescriptionLoaderProps) => {
  const [generatedDescriptions, setGeneratedDescriptions] = useRecoilState(
    generatedShortDescriptionsState,
  );
  const { data, isLoading, mutate } = useCreateCompanyDescription();
  useEffect(() => {
    if (!getShortDescription() && companyName) {
      mutate({
        requestId,
        resultNumber,
        companyName,
        website: companyWebsite,
      });
    }
  }, [companyName]);

  useEffect(() => {
    if (data) {
      setGeneratedDescriptions(mappings => {
        const newMappings = { ...mappings };
        newMappings[`${requestId}-${resultNumber}`] = data;
        return newMappings;
      });
    }
  }, [data]);

  const getShortDescription = () => {
    return (
      shortDescription ||
      generatedDescriptions[`${requestId}-${resultNumber}`] ||
      data
    );
  };

  if (!getShortDescription() || isLoading) {
    return (
      <ShortDescription>
        <Skeleton width="393px" height="13px" />
        <Skeleton width="292px" height="13px" />
      </ShortDescription>
    );
  }
  return <ShortDescription>{getShortDescription()}</ShortDescription>;
};

const ShortDescription = styled(SmallText, forwardRef)`
  ${({ theme }) => theme.typography.enhance.tableSmallText}
  ${({ theme }) => theme.mixins.lineClamp(2)}
  padding: 15px 0px;
`;
