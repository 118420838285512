import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { FileGrantAccessPayload, FileRemoveAccessPayload } from '@/api';
import {
  removeAccessToCompanyFile,
  shareOrganizationFile,
} from '@/api/v3/files-api';
import { MutationKey, QueryKey } from '@/config/query-client';

export const useShareCompanyFile = () => {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries([QueryKey.CompanyFiles]);
  };

  const shareCompanyFileMutation = useMutation(shareOrganizationFile, {
    mutationKey: [MutationKey.CompanyFileShare],
  });

  const shareCompanyFile = (payload: FileGrantAccessPayload) =>
    shareCompanyFileMutation.mutateAsync(payload, {
      onSuccess,
    });

  const unshareCompanyFileMutation = useMutation(removeAccessToCompanyFile, {
    mutationKey: [MutationKey.CompanyFileUnshare],
    onSuccess,
  });

  const unshareCompanyFile = (payload: FileRemoveAccessPayload) =>
    unshareCompanyFileMutation.mutateAsync(payload);

  return {
    shareCompanyFile,
    unshareCompanyFile,
  };
};
