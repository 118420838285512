import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { deleteTeamComment } from '@/api/v4/team-comments.api';
import { notify } from '@/ui/snackbar/notify';

type DeleteTeamCommentMutationParams = {
  teamId: string;
  commentId: string;
};

export const useDeleteTeamComment = () => {
  const { t } = useTranslation('default');

  return useMutation(
    async ({ teamId, commentId }: DeleteTeamCommentMutationParams) => {
      return deleteTeamComment(teamId, commentId);
    },
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
