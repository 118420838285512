import * as Sentry from '@sentry/react';
import { QueryClient } from '@tanstack/react-query';

import { isExceptionLikeObject } from '../helpers/is-exception-like-object';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (err, variables, query) => {
        if (err instanceof Error) console.error(err.message, variables, query);

        if (isExceptionLikeObject(err)) {
          Sentry.captureException(err);
        }
      },
    },
    queries: {
      onError: err => {
        if (err instanceof Error) console.error(err.message);

        if (isExceptionLikeObject(err)) {
          Sentry.captureException(err);
        }
      },
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 2,
    },
  },
});

export enum QueryKey {
  ActiveProjects = 'active-projects',
  ActivityStatuses = 'activity-statuses',
  Companies = 'companies',
  CompaniesSearchCrunchbase = 'companies-search-crunchbase',
  CompaniesSearchSavvy = 'companies-search-savvy',
  CompaniesStatistics = 'companies-statistics',
  Company = 'company',
  CompanyActionsInfo = 'company-actions-info',
  CompanyComments = 'company-comments',
  CompanyDomainAvailability = 'company-domain-availability',
  CompanyClients = 'company-clients',
  CompanyFileScanningStatus = 'company-file-scanning-status',
  CompanyInvestors = 'company-investors',
  CompanyFiles = 'company-files',
  CompanyFundings = 'company-fundings',
  CompanyMeetings = 'company-meetings',
  CompanyNews = 'company-news',
  CompanyOfficeLocations = 'company-office-locations',
  CompanyOptionsCount = 'company-options-count',
  CompanyProjectsListings = 'company-projects-listings',
  CompanyProducts = 'company-products',
  CompanyRankings = 'company-ratings',
  CompanyTags = 'company-tags',
  Compliances = 'compliances',
  CountEntitiesByTag = 'count-entities-by-tag',
  CreateProjectListingSuggestions = 'create-project-listing-suggestions',
  DeploymentModels = 'deployment-models',
  EmployeeRanges = 'employee-ranges',
  ExploreHistory = 'explore-history',
  ExploreRequestHistoryFormData = 'explore-request-history-form-data',
  ExploreRequestHistorySuggestions = 'explore-request-history-suggestions',
  ExploreSuggestions = 'explore-suggestions',
  FundingStages = 'funding-stages',
  Funnel = 'funnel',
  Integrations = 'integrations',
  HqLocations = 'hq-locations',
  InvitedUserData = 'invited-user-data',
  Languages = 'languages',
  LastActivities = 'last-activities',
  LastActivitiesCount = 'last-activities-count',
  ListingStages = 'listing-stages',
  OwnershipStatuses = 'ownership-statuses',
  PinnedItems = 'pinned-items',
  Project = 'project',
  ProjectActionsInfo = 'project-actions-info',
  ProjectActivity = 'project-activity',
  ProjectComments = 'project-comments',
  ProjectCompanies = 'project-companies',
  ProjectCreators = 'project-creators',
  ProjectFileScanningStatus = 'project-file-scanning-status',
  ProjectFiles = 'project-files',
  ProjectListings = 'project-listings',
  ProjectMeetings = 'project-meetings',
  ProjectOptions = 'project-options',
  ProjectFiltersTeamOptions = 'project-filters-team-options',
  ProjectUpdates = 'project-updates',
  ProjectSearchResults = 'project-search-results',
  Projects = 'projects',
  RecentItems = 'recent-items',
  Roles = 'roles',
  Search = 'search',
  Snapshot = 'snapshot',
  Tags = 'tags',
  Team = 'team',
  TeamActionsInfo = 'team-actions-info',
  TeamComments = 'team-comment',
  TeamContacts = 'team-contacts',
  TeamFileScanningStatus = 'team-file-scanning-status',
  TeamFiles = 'team-files',
  TeamMeetings = 'team-meetings',
  TeamOptions = 'team-options',
  TeamTags = 'team-tags',
  Teams = 'teams',
  UserRecentSearchRecords = 'user-recent-search-records',
  Users = 'users',
  UsersAll = 'users-all',
  UsersCount = 'users-count',
  UsersToMentionList = 'users-to-mention-list',
  WhoAmI = 'who-am-i',
}

export enum MutationKey {
  CreateCompanyFromCrunchbase = 'create-company-from-crunchbase',
  CompanyFileShare = 'company-file-share',
  CompanyFileUnshare = 'company-file-unshare',
  TeamFileShare = 'team-file-share',
  TeamFileUnshare = 'team-file-unshare',
}
