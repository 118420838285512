import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import type { TeamCommentPayload } from '@/api/v4/team-comments.api';
import { updateTeamComment } from '@/api/v4/team-comments.api';
import { notify } from '@/ui/snackbar/notify';

type UpdateTeamCommentMutationParams = {
  teamId: string;
  commentId: string;
  payload: TeamCommentPayload;
};

export const useUpdateTeamComment = () => {
  const { t } = useTranslation('default');

  return useMutation(
    async ({ teamId, commentId, payload }: UpdateTeamCommentMutationParams) => {
      return updateTeamComment(teamId, commentId, payload);
    },
    {
      onError: (error: Error) => {
        notify({
          message: t`unknownError`,
        });
        captureException(error);
      },
    },
  );
};
