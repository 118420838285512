import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { isEllipsis } from '@/helpers/other';
import { colors } from '@/theme/colors';
import type { InvitedUserOption, TeamBasicOption, UserOption } from '@/types';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Icon } from '@/ui/icons/icon';
import { Logo } from '@/ui/logo/logo';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

import {
  OptionsListItem,
  OptionsListItemLabel,
} from '../selected-options-list';

import { OPTION_ITEM_HEIGHT } from './selected-basic-team-option';

interface SelectedUserOptionProps {
  onRemove?: (item: UserOption | TeamBasicOption | InvitedUserOption) => void;
  isDisabled?: boolean;
  option: UserOption;
  index: number;
}

export function SelectedUserOption({
  onRemove,
  isDisabled,
  option,
  index,
}: SelectedUserOptionProps) {
  const [isReady, setIsReady] = useState(false);
  const refItemLabel = useRef<(HTMLDivElement | null)[]>([]);
  const { t } = useTranslation('default');

  useEffect(() => {
    refItemLabel.current && setIsReady(true);
  }, [refItemLabel]);

  return (
    <ListItem
      key={option.value}
      name="options-list-item"
      justify="space-between"
      alignItems="center"
    >
      <Tooltip
        placement="left"
        content={option.label + (option.team ? ` (${option.team.name})` : '')}
        visible={isReady && isEllipsis(refItemLabel.current[index])}
      >
        <Flexbox name="option-content" gap="8px" alignItems="center">
          <LogoUser
            name={option.label}
            singleLetter={!Boolean(option.firstName && option.lastName)}
            bgColor={colors.blue.primaryA}
          />
          <Label
            ref={element => {
              refItemLabel.current[index] = element;
            }}
            {...(option.team && { 'data-team': option.team.name })}
          >
            {option.label}
            {option.team ? <TeamName>{option.team.name}</TeamName> : null}
          </Label>
        </Flexbox>
      </Tooltip>
      <Actions name="actions" gap="20px" alignItems="center">
        <Role isOwner={option.isOwner}>
          {option.isOwner
            ? t('share.owner')
            : option.role
            ? option.role.name
            : null}
        </Role>
        {!isDisabled && !option.isOwner && onRemove && (
          <IconWrapper>
            <Icon
              icon="Close"
              width="10px"
              height="10px"
              color={colors.gray.c13}
              onClick={() => onRemove?.(option)}
            />
          </IconWrapper>
        )}
      </Actions>
    </ListItem>
  );
}

const Actions = styled(Flexbox)`
  font-size: 14px;
`;

const IconWrapper = styled('div')`
  visibility: hidden;
`;

const ListItem = styled(OptionsListItem)`
  min-height: ${OPTION_ITEM_HEIGHT}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c2};

  [data-name='share-modal-container'] &:hover {
    background-color: ${({ theme }) => theme.colors.blue.c8};
    ${IconWrapper} {
      visibility: visible;
    }
  }
`;

const Label = styled(OptionsListItemLabel, forwardRef)`
  ${({ theme }) => theme.typography.acrossPlatform.userName}

  [data-name='share-modal-container'] & {
    max-width: 560px;
  }

  &::after {
    content: attr(data-team);
    display: block;
    clear: left;
    margin-top: 2px;
    ${({ theme }) => theme.typography.widget.smallText}
    ${({ theme }) => theme.mixins.ellipsis}
  }
`;

const LogoUser = styled(Logo)`
  font-size: 12px;
`;

const TeamName = styled(SmallText)`
  height: 0;
  opacity: 0;
`;

const Role = styled('span')<{ isOwner?: boolean }>`
  color: ${({ isOwner, theme }) =>
    isOwner ? theme.colors.gray.c10 : theme.colors.basics.black};
`;
