import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSidebars } from '@/hooks/use-sidebars';
import { Icon } from '@/ui/icons/icon';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { NavButton } from '../navbar.styles';

import { DropdownCreate } from './dropdown-create';

export function TooltipDropdownCreate() {
  const { isNavbarOpen } = useSidebars();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation('default');

  return (
    <DropdownCreate
      triggerButton={
        <Tooltip
          visible={!isNavbarOpen && !isDropdownOpen}
          content={t`Create`}
          placement="right"
        >
          <NavButton selected={isDropdownOpen}>
            <Icon icon="Create" />
            <span>{t`Create`}</span>
          </NavButton>
        </Tooltip>
      }
      setIsDropdownOpen={setIsDropdownOpen}
    />
  );
}
