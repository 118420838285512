import type { IResponseBase, IResponseObject } from '../../api';
import type { IEntityType } from '../../types';

function getRelationshipContentAsArray(
  responseObject: IResponseObject,
  relation: IEntityType,
): IResponseBase[] {
  if (
    responseObject &&
    relation in responseObject.relationships &&
    !Array.isArray(responseObject.relationships[relation])
  ) {
    const relationship = responseObject.relationships[relation];
    if (
      relationship &&
      'data' in relationship &&
      relationship.data &&
      Array.isArray(relationship.data)
    )
      return relationship.data;
    if (
      relationship &&
      'data' in relationship &&
      relationship.data &&
      !Array.isArray(relationship.data)
    )
      return [relationship.data];
  }
  return [];
}

export function getIncludedByRelationship(
  responseObject: IResponseObject,
  included: IResponseObject[],
  relation: IEntityType,
): IResponseObject[] {
  if (!included) return [];
  const relationshipData = getRelationshipContentAsArray(
    responseObject,
    relation,
  );
  const includedIds = relationshipData
    .filter(data => !!data)
    .map(data => data.id);
  return included.filter(include => includedIds.indexOf(include.id) >= 0);
}
