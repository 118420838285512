import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { Modal as ModalComponent } from '@/ui/modal/modal';

import { Search } from '../search/global-search/search';

export const SearchModal = () => {
  const [modal, setModal] = useRecoilState(modalState);

  if (modal?.state !== 'search') {
    return null;
  }

  const closeModal = () => setModal(null);

  return (
    <Modal closeIcon={false} onClose={closeModal}>
      <Search />
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  min-height: 400px;
  max-height: 600px;
  flex-direction: row;
  max-width: 688px;
`;
