import { atom, selector, selectorFamily } from 'recoil';

import type {
  TeamMember,
  TeamMemberSortParams,
  TeamsSortParams,
} from '@/api/v4/team.api';
import { fetchTeamMembers } from '@/api/v4/team.api';
import type { TeamContact } from '@/api/v4/team-contacts.api';
import { fetchTeamContacts } from '@/api/v4/team-contacts.api';
import type { IndexParams } from '@/types';

import type { IPageState } from '../../ui/table/table.types';
import type { ISort } from '../../ui/table/table.types';
import { sortContactsByPrimaryAndFirstName } from '../contacts/helpers/sorter';
import type { CurrentUserStatus } from '@/api/v4/auth.api';
import type { OrganizationContact } from '@/api/v4/organization-contacts.api';

export const teamIdState = atom<string | null>({
  key: 'teamIdState',
  default: null,
});

type TeamMemberQuery = {
  status: CurrentUserStatus;
};

export const teamMembersByStatusState = selectorFamily<
  TeamMember[],
  TeamMemberQuery
>({
  key: 'teamMembersByStatusState',
  get:
    query =>
    async ({ get }) => {
      const teamId = get(teamIdState);
      const { sort, direction } = get(teamMembersSortState);

      if (!teamId) return [];

      const teamMembers = await fetchTeamMembers(teamId, {
        status: query.status,
        ...(sort && { sort, direction }),
      });

      return teamMembers;
    },
});

const mapTeamContacts = (data: TeamContact[]) =>
  data.map(contact => ({
    ...contact,
    synced: true,
  }));

export const teamContactsAtom = atom<OrganizationContact[]>({
  key: 'teamContactsAtom',
  default: [],
});

export const teamContactsState = selector<OrganizationContact[]>({
  key: 'teamContactsState',
  get: async ({ get }) => {
    const teamId = get(teamIdState);
    const { page, pageSize } = get(teamContactsPageState);

    if (!teamId) return [];

    const params: IndexParams = {
      page,
      items: pageSize,
    };

    const teamContacts = await fetchTeamContacts(teamId, params);
    const unsortedList = mapTeamContacts(teamContacts);
    return sortContactsByPrimaryAndFirstName(unsortedList);
  },
});

export const teamMembersSortState = atom<ISort<TeamMemberSortParams>>({
  key: 'teamMembersSortState',
  default: {
    sort: 'firstName',
    direction: 'desc',
  },
});

export interface TeamFilters {
  search: string | null;
}

export const defaultAppliedTeamsFilters: TeamFilters = {
  search: null,
};

export const appliedTeamsFiltersState = atom<TeamFilters>({
  key: 'appliedTeamsFiltersState',
  default: defaultAppliedTeamsFilters,
});

export const selectedTeamsFiltersState = atom<TeamFilters>({
  key: 'selectedTeamsFiltersState',
  default: defaultAppliedTeamsFilters,
});

export const teamsSortState = atom<ISort<TeamsSortParams>>({
  key: 'teamsSortState',
  default: {
    sort: 'name',
    direction: 'desc',
  },
});

export const teamContactsPageState = atom<IPageState>({
  key: 'teamContactsPageState',
  default: { page: 1, pageSize: 20 },
});

export const teamContactsFormSubmitState = atom<boolean>({
  key: 'teamContactsFormSubmitState',
  default: false,
});

export const editTeamContactProgressState = atom({
  key: 'editTeamContactProgressState',
  default: false,
});

export const addTeamContactCounterState = atom({
  key: 'addTeamContactCounterState',
  default: 0,
});

export const teamContactDeletedIdState = atom<string | null>({
  key: 'teamContactDeletedIdState',
  default: null,
});
