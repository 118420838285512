import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { deleteOrganizationComment } from '@/api/v4/organization-comments.api';
import { queryClient, QueryKey } from '@/config/query-client';
import { notify } from '@/ui/snackbar/notify';

import { companyIdState } from '../../company.state';

export const useDeleteCompanyComment = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);

  const deleteCompanyCommentMutation = useMutation(deleteOrganizationComment, {
    onError: (error: Error) => {
      notify({
        message: t`unknownError`,
      });
      console.error(error.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QueryKey.CompanyComments,
        companyId,
      ]);
    },
  });

  const deleteComment = (commentId: string) =>
    deleteCompanyCommentMutation.mutateAsync(commentId);

  return {
    deleteComment,
    isError: deleteCompanyCommentMutation.isError,
  };
};
