import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { fetchOrganizationFileLink } from '@/api/v4/organization-files.api';
import { queryClient, QueryKey } from '@/config/query-client';
import type {
  DraftFile,
  File,
  FileLinkDownloadHandler,
  FileModeType,
} from '@/features/action-panel/files/file.interface';
import { FilesListContainer } from '@/features/action-panel/files/files-list-container';
import type { UploadFileInputProps } from '@/features/action-panel/files/upload-file-input';
import { companyIdState } from '@/features/companies/overview/company.state';
import { downloadLinkAutomatically } from '@/helpers/download-link-automatically';
import { resolveSharingParamsCompanyFile } from '@/helpers/share-access';
import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { useCompanyFileScanningStatus } from './use-company-file-scanning-status.query';
import { useCompanyFiles } from './use-company-files.query';
import { useShareCompanyFile } from './use-share-company-file.mutation';
import { useUploadCompanyFile } from './use-upload-company-file.mutation';

export const CompanyFilesTab = () => {
  const { t } = useTranslation('default');
  const companyId = useRecoilValue(companyIdState);
  const { uploadCompanyFile } = useUploadCompanyFile();
  const { shareCompanyFile, unshareCompanyFile } = useShareCompanyFile();
  const { data } = useCompanyFiles();
  const files = data ?? [];

  const setModal = useSetRecoilState(modalState);

  if (!companyId) return null;

  const openDeleteModal = (id: string, name: string) => {
    setModal({
      state: 'deleteCompanyFile',
      file: { id, name },
      companyId,
    });
  };

  const handleSubmitFiles: UploadFileInputProps['onSubmit'] = async files => {
    if (!companyId) return;

    setDraftFiles(files);

    try {
      await uploadCompanyFile(files);
    } catch (error) {
      if (error instanceof Error) {
        notify({ message: error.message });
      }
    }
  };

  const setDraftFiles = (uploadingFiles: globalThis.File[]) => {
    queryClient.setQueryData(
      [QueryKey.CompanyFiles, companyId],
      (files: Array<File | DraftFile> | undefined) => {
        if (!files) return;

        const draftFiles: DraftFile[] = uploadingFiles.map(file => ({
          id: file.name,
          name: file.name,
          createdDate: new Date().toISOString(),
          scanningStatus: null,
          creator: null,
          public: true,
          lastEditedDate: null,
        }));
        return [...draftFiles, ...files];
      },
    );
  };

  const handleFileLinkDownload: FileLinkDownloadHandler = async (
    file: File,
    mode: FileModeType,
  ) => {
    if (!companyId) return;

    const link = await fetchOrganizationFileLink(
      {
        organizationId: companyId,
        fileId: file.id,
      },
      {
        mode,
      },
    );

    await downloadLinkAutomatically(link, file.name);
  };

  return (
    <FilesListContainer
      files={files}
      entityType="file_organization"
      onFileLinkDownload={handleFileLinkDownload}
      emptyListLabel={t('actionPanel.files.empty', { context: 'company' })}
      onSubmit={handleSubmitFiles}
      onClickDelete={openDeleteModal}
      resolveSharingParams={resolveSharingParamsCompanyFile}
      onShareFile={async payload => {
        await shareCompanyFile(payload);
      }}
      onUnshareFile={async payload => {
        await unshareCompanyFile(payload);
      }}
      useFileScanningStatus={useCompanyFileScanningStatus}
    />
  );
};
