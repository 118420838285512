import type { MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { GroupBase, Props } from 'react-select';
import type { AsyncPaginateProps } from 'react-select-async-paginate';

import {
  DROPDOWN_ITEMS_PER_PAGE,
  FETCH_BUSINESS_UNIT_DELAY,
} from '@/constants';
import { theme } from '@/theme/setupTheme';
import type {
  CompanyLocationOption,
  INumberOption,
  IOption,
  OrganizationOption,
  PersonOption,
  TeamOption,
} from '@/types';

import type { AsyncSelectProps } from './async-select';
import { AsyncSelectControl as Control } from './components/control';
import { AsyncSelectOption as Option } from './components/option/option';
import { AsyncSelectContainer as SelectContainer } from './components/select-container';
import { AsyncSelectSingleValue as SingleValue } from './components/single-value/single-value';

type AdditionalType = {
  page: number;
};

interface ICalendarOption extends Omit<IOption, 'value'> {
  value: (string | null)[];
}

export type OptionType =
  | IOption
  | INumberOption
  | ICalendarOption
  | CompanyLocationOption
  | OrganizationOption
  | PersonOption
  | TeamOption;

type CustomAsyncPaginateProps = AsyncPaginateProps<
  unknown,
  GroupBase<unknown>,
  AdditionalType,
  boolean
>;

type LabelPlacement = 'top' | 'bottom';

export type CustomOptionProps = {
  withCheckIconOnSelect?: boolean;
  hideDisabled?: boolean;
};

export interface CustomAsyncSelectProps
  extends Partial<CustomAsyncPaginateProps> {
  controlWidth?: string;
  label?: string;
  menuAlign?: 'left' | 'center' | 'right';
  labelPlacement?: LabelPlacement;
  setOptions?:
    | Props['options']
    | ((
        search: string,
        page?: number,
      ) => Promise<OptionType[] | GroupBase<OptionType>[]>);
  readOnly?: boolean;
  readOnlyValue?: string | null;
  optionProps?: CustomOptionProps;
  onClick?: (event: MouseEvent) => void;
  disableFetchMore?: boolean;
  dataTestId?: string;
}

const defaultAdditional: AdditionalType = {
  page: 1,
};

export function useSelectType(props: AsyncSelectProps) {
  const { t } = useTranslation('default');
  const {
    controlWidth,
    menuAlign,
    setOptions,
    cacheUniqs,
    styles,
    components,
    isSearchable = false,
    placeholder = t`select`,
    optionProps: customOptionProps,
    options,
    disableFetchMore,
    ...rest
  } = props;

  const OptionComponent = useMemo(
    () => (props.components?.Option ? props.components?.Option : Option),
    [props.components?.Option],
  );
  const getMenuAlign = useCallback((align: typeof menuAlign) => {
    if (!align) return {};

    if (align === 'center')
      return { marginLeft: '50%', transform: 'translate3d(-50%, 0, 0)' };

    return { [align]: 0 };
  }, []);

  const defaultProps: Props = useMemo(
    () => ({
      ...rest,
      options,
      isSearchable,
      placeholder,
      classNamePrefix: 'react-select',
      components: {
        Option: props => <OptionComponent {...props} {...customOptionProps} />,
        Control,
        SingleValue,
        SelectContainer,
        ...components,
      },
      styles: {
        control: base => ({
          ...base[':hover'],
          ...(controlWidth && { width: controlWidth }),
          '&:not(:has(.react-select__placeholder)) .react-select__indicator': {
            color: theme.colors.basics.black,
          },
        }),
        menu: ({ ...css }) => ({
          ...css,
          ...getMenuAlign(menuAlign),
          boxShadow: theme.shadow.actionsPanel,
          marginTop: '10px',
          maxWidth: '415px',
          minWidth: '150px',
        }),
        menuList: ({ ...css }) => ({
          ...css,
        }),
        valueContainer: base => ({
          ...base,
          height: '100%',
        }),
        option: (_base, props) => ({
          ...(props.isDisabled && { cursor: 'not-allowed' }),
        }),
        indicatorSeparator: () => ({}),
        placeholder: base => ({
          ...base,
          color: theme.colors.gray.c7,
        }),
        dropdownIndicator: base => ({
          ...base,
          paddingTop: '5px',
          paddingBottom: '5px',
        }),
        ...styles,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      rest,
      options,
      isSearchable,
      components,
      styles,
      controlWidth,
      getMenuAlign,
      menuAlign,
    ],
  );

  const asyncProps: CustomAsyncPaginateProps = {
    additional: { page: 1 },
    debounceTimeout: FETCH_BUSINESS_UNIT_DELAY,
    cacheUniqs: cacheUniqs,
    loadOptions: async (
      search: string,
      _: unknown,
      additional: AdditionalType | undefined,
    ) => {
      try {
        const { page } = additional ?? defaultAdditional;
        const optionsArray =
          typeof setOptions === 'function'
            ? await setOptions?.(search, page)
            : ((setOptions ?? options ?? []) as IOption[]).filter(item =>
                item.label.toLowerCase().match(search.toLowerCase()),
              );

        return {
          options: optionsArray,
          hasMore: disableFetchMore
            ? false
            : optionsArray.length >= 1 &&
              optionsArray.length === DROPDOWN_ITEMS_PER_PAGE,
          additional: {
            page: page + 1,
          },
        };
      } catch (e) {
        return {
          options: [],
          hasMore: false,
          additional,
        };
      }
    },
  };

  return {
    ...defaultProps,
    ...asyncProps,
  };
}
