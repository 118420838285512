import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { ConfirmDeleteModal } from '../confirm-delete-modal';

import { useDeleteTeam } from './use-delete-team';

export const DeleteTeamModal = () => {
  const modal = useRecoilValue(modalState);
  const { t } = useTranslation(['teams, default']);

  const deleteTeam = useDeleteTeam();

  if (!modal || modal.state !== 'deleteTeam') return null;

  return (
    <ConfirmDeleteModal
      title={t`teams:deleteTeamTitle`}
      description={t`teams:deleteTeamDescription`}
      onConfirm={async () => {
        await deleteTeam.mutateAsync(modal.teamId);
        notify({
          message: t('teams:deleteSuccessMessage', {
            name: modal.teamName,
          }),
        });
      }}
      confirmLabel={t`teams:delete`}
    />
  );
};
