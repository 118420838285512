import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentUserInitials } from '@/hooks/use-current-user-initials';
import { useSidebars } from '@/hooks/use-sidebars';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Tooltip } from '@/ui/tooltip/tooltip';

import { AccountSettingsIcon, NavButton } from '../navbar.styles';

interface DropdownSettingsProps {
  onLogout: () => void;
}

export function DropdownSettings({ onLogout }: DropdownSettingsProps) {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const { isNavbarOpen } = useSidebars();

  const navigateTo = useNavigateTo();

  const initials = useCurrentUserInitials();
  const { t } = useTranslation('settings');

  const accountSettingsItems = [
    { label: t`accountSettings`, onClick: () => navigateTo.settings() },
    { label: t`logOut`, onClick: onLogout },
  ];

  return (
    <div data-testid="user-settings">
      <WithDropdown
        items={accountSettingsItems}
        options={{
          placement: 'right-start',
          modifiers: [{ name: 'offset', options: { offset: [0, 22] } }],
        }}
        setIsDropdownOpened={setIsDropdownOpened}
      >
        <Tooltip
          visible={!isNavbarOpen && !isDropdownOpened}
          content={t`Settings`}
          placement="right"
        >
          <NavButton
            selected={isDropdownOpened}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
          >
            <AccountSettingsIcon
              label={initials}
              selected={isDropdownOpened}
              isButtonHovered={isButtonHovered}
            />
            <span>{t`Settings`}</span>
          </NavButton>
        </Tooltip>
      </WithDropdown>
    </div>
  );
}
