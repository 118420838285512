import { useQuery } from '@tanstack/react-query';

import { fetchListingStages } from '@/api/v4/project-listings.api';
import { QueryKey } from '@/config/query-client';

export const useListingStages = () => {
  const query = useQuery([QueryKey.ListingStages], () => fetchListingStages(), {
    cacheTime: 3600000,
    staleTime: 3600000,
  });

  return {
    listingStages: query.data ?? [],
    ...query,
  };
};
