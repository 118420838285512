import type {
  APIRequestPathParams,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, remove } from './base-fetch';

export type OrganizationFile =
  APIResponse<'/api/v4/organizations/{organizationId}/files'>[number];

export const fetchOrganizationFiles = async (organizationId: string) =>
  get<OrganizationFile[]>(`/v4/organizations/${organizationId}/files`);

type OrganizationFileUpload =
  APIResponse<'/api/v4/organizations/{organizationId}/files/upload'>;

export const uploadOrganizationFile = async ({
  organizationId,
  files,
}: {
  organizationId: string;
  files: File[];
}) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });

  return post<OrganizationFileUpload>(
    `/v4/organizations/${organizationId}/files/upload`,
    formData,
  );
};

type OrganizationFileLink =
  APIResponse<'/api/v4/organizations/{organizationId}/files/{fileId}/link'>;

type OrganizationFileLinkParams =
  APIRequestPathParams<'/api/v4/organizations/{organizationId}/files/{fileId}/link'>;

type OrganizationFileLinkQueries =
  APIRequestQueryParams<'/api/v4/organizations/{organizationId}/files/{fileId}/link'>;

export type OrganizationFileLinkMode = OrganizationFileLinkQueries['mode'];

export const fetchOrganizationFileLink = async (
  pathParams: OrganizationFileLinkParams,
  queryParams: OrganizationFileLinkQueries,
) =>
  get<OrganizationFileLink>(
    `/v4/organizations/${pathParams.organizationId}/files/${pathParams.fileId}/link`,
    {
      params: queryParams,
    },
  );

type OrganizationFileScanningStatusParams =
  APIRequestPathParams<'/api/v4/organizations/{organizationId}/files/{fileId}/scanning-status'>;

export type OrganizationFileScanningStatus =
  APIResponse<'/api/v4/organizations/{organizationId}/files/{fileId}/scanning-status'>;

export const fetchOrganizationFileScanningStatus = async ({
  organizationId,
  fileId,
}: OrganizationFileScanningStatusParams) =>
  get<OrganizationFileScanningStatus>(
    `/v4/organizations/${organizationId}/files/${fileId}/scanning-status`,
  );

type DeleteOrganizationFileParams =
  APIRequestPathParams<'/api/v4/organizations/{organizationId}/files/{fileId}/delete'>;

export const deleteOrganizationFile = async ({
  organizationId,
  fileId,
}: DeleteOrganizationFileParams) =>
  remove<OrganizationFile[]>(
    `/v4/organizations/${organizationId}/files/${fileId}/delete`,
  );
