import { styled } from 'goober';

import type { IAlign, IRowPadding } from '../table.types';

import { Td } from './table-row';

export interface SkeletonRowProps {
  columns: number;
  rowPadding?: IRowPadding;
  rowConfig?: {
    align?: IAlign;
  }[];
}

export const SkeletonRow = ({
  columns,
  rowConfig,
  rowPadding,
}: SkeletonRowProps) => {
  return (
    <Tr>
      {Array.from({ length: columns }).map((_, cellIndex) => (
        <Td key={cellIndex} rowPadding={rowPadding}>
          <SkeletonText align={rowConfig?.[cellIndex]?.align} />
        </Td>
      ))}
    </Tr>
  );
};

const Tr = styled('tr')`
  height: 76px;
`;

const SkeletonText = styled('div')<{ align?: IAlign }>`
  height: 16px;
  border-radius: 4px;
  width: 50%;
  ${({ theme }) => theme.animations.skeletonAnimation()};
  ${({ align }) =>
    align === 'right'
      ? 'margin-left: auto;'
      : align === 'center'
      ? 'margin: 0 auto;'
      : ''}
`;
