import { useQuery, useQueryClient } from '@tanstack/react-query';
import { orderBy } from 'lodash-es';
import { useRecoilValue } from 'recoil';

import type { CreateOrganizationSuggestionPayload } from '@/api/v4/organization-suggestions.api';
import { createOrganizationSuggestions } from '@/api/v4/organization-suggestions.api';
import { QueryKey } from '@/config/query-client';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';

import { useExploreForm } from './form/use-explore-form';
import { isRegeneratePanelOpenState } from './regenerate/regenerate-explore.state';
import { exploreResultsSortState } from './explore.state';

export const useExploreSuggestions = () => {
  const queryClient = useQueryClient();
  const { description, exampleCompanies, locations, isValid } =
    useExploreForm();
  const navigateTo = useNavigateTo();

  const isRegeneratePanelOpen = useRecoilValue(isRegeneratePanelOpenState);
  const sort = useRecoilValue(exploreResultsSortState);

  const payload: CreateOrganizationSuggestionPayload = {
    problemDescription: description,
    ...(locations.length && {
      locations: locations.map(option => JSON.parse(option.value)),
    }),
    ...(exampleCompanies.length && {
      companies: exampleCompanies.map(company => ({
        crunchbaseId: company.id,
        name: company.name,
        website: company.website,
        logoUrl: company.logoUrl,
      })),
    }),
  };

  const generateSuggestions = async () =>
    await createOrganizationSuggestions(payload);

  return useQuery([QueryKey.ExploreSuggestions, payload], generateSuggestions, {
    onSuccess: async data => {
      await queryClient.invalidateQueries([QueryKey.ExploreHistory]);
      navigateTo.exploreResults({ requestId: data.requestId });
    },
    select: data => ({
      requestId: data.requestId,
      results: orderBy(data.results, data => data.name, sort.direction),
    }),
    staleTime: 1000 * 60 * 3,
    enabled: isValid && !isRegeneratePanelOpen,
    retry: false,
  });
};
