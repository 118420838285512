import { useCallback, useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { deleteOrganizationContact } from '@/api/v4/organization-contacts.api';
import type { ContactFormItem } from '@/features/contacts/contacts';
import { useLoadableResults } from '@/hooks/use-loadable-results';

import {
  companyContactDeletedIdState,
  companyContactsAtomState,
  companyContactsEditModeState,
  companyContactsPageState,
  companyContactsState,
  companyIdState,
  firstSixCompanyContactsAtomState,
  isMoreContactsThanMaxVisibleState,
} from '../company.state';

export const MAX_VISIBLE_CONTACTS_NUMBER = 6;

export const useCompanyContacts = () => {
  const data = useLoadableResults(companyContactsState);
  const [allContactsVisible, setAllContactsVisible] = useState(false);

  const resetCompanyContactsState =
    useRecoilRefresher_UNSTABLE(companyContactsState);
  const companyId = useRecoilValue(companyIdState);
  const isMoreContactsThanMaxVisible = useRecoilValue(
    isMoreContactsThanMaxVisibleState,
  );
  const [contacts, setContacts] = useRecoilState(companyContactsAtomState);
  const firstSixContacts = useRecoilValue(firstSixCompanyContactsAtomState);

  const [visibleContacts, setVisibleContacts] =
    useState<ContactFormItem[]>(firstSixContacts);
  const { pageSize } = useRecoilValue(companyContactsPageState);
  const setDeletedContact = useSetRecoilState(companyContactDeletedIdState);
  const resetPagination = useResetRecoilState(companyContactsPageState);
  const resetEditContactState = useResetRecoilState(
    companyContactsEditModeState,
  );
  const [resetContacts, setResetContacts] = useState(false);

  useEffect(() => {
    resetPagination();
    setContacts([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (resetContacts) return;

    if (!data || data.contacts.length === 0) return;
    setContacts(old =>
      uniqBy([...old, ...data.contacts], contact => contact.id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.contacts, setContacts, resetContacts]);

  useEffect(() => {
    if (!firstSixContacts.length) return;
    setVisibleContacts(firstSixContacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  useEffect(() => {
    if (!visibleContacts.length) return;

    setVisibleContacts(allContactsVisible ? contacts : firstSixContacts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleContacts, allContactsVisible]);

  useEffect(() => {
    if (!resetContacts) return;

    setContacts(uniqBy(data?.contacts || [], contact => contact.id));
    resetPagination();

    setResetContacts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data?.contacts, setContacts]);

  const reset = useCallback(() => {
    resetEditContactState();
    resetCompanyContactsState();
    setResetContacts(true);
  }, [resetCompanyContactsState, resetEditContactState]);

  const addContact = useCallback(
    async (contact: ContactFormItem) => {
      setContacts(contacts => [...contacts, contact]);
    },
    [setContacts],
  );

  const updateContact = useCallback(
    (updatedContact: ContactFormItem) => {
      setContacts(contacts =>
        contacts.map(oldContact => {
          if (oldContact.id !== updatedContact.id) {
            return oldContact;
          }

          return updatedContact;
        }),
      );
    },
    [setContacts],
  );

  const deleteContact = useCallback(
    async (contactId: string, synced: boolean) => {
      if (synced && companyId) {
        await deleteOrganizationContact(companyId, contactId);
      }

      setDeletedContact(contactId);
      setContacts(contacts =>
        contacts.filter(oldContact => {
          return oldContact.id !== contactId;
        }),
      );
    },
    [companyId, setContacts, setDeletedContact],
  );

  const hasNextPage = data?.contacts && data?.contacts.length >= pageSize;
  const poweredByCrunchbase = data?.poweredBy === 'crunchbase';

  const toggleAllContacts = () => setAllContactsVisible(val => !val);

  return {
    allContacts: contacts,
    visibleContacts,
    isLoading: !data,
    hasNextPage,
    addContact,
    updateContact,
    deleteContact,
    reset,
    isMoreContactsThanMaxVisible,
    toggleAllContacts,
    allContactsVisible,
    poweredByCrunchbase,
  };
};
