import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import {
  type EnhanceProjectListingSuggestion,
  rejectProjectListingSuggestions,
  undoRejectProjectListingSuggestions,
} from '@/api/v4/project-enhance-listing-suggestions.api';
import { Loader } from '@/components/loader/loader';
import { useCompanies } from '@/features/companies/hooks/use-companies.query';
import { useCreateProjectListings } from '@/features/projects/project-listings/use-create-project-listings.mutation';
import { openNewTab } from '@/helpers/open-new-tab';
import { useCreateCompany } from '@/hooks/queries/use-create-company.mutation';
import { paths } from '@/routes/helpers/paths';
import { colors } from '@/theme/colors';
import { Button } from '@/ui/button/button';
import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { LinkIcon } from '@/ui/link/link-icon';
import { Logo } from '@/ui/logo/logo';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import type { IRow } from '@/ui/table/table.types';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';

import { useProjectListingSuggestionState } from '../use-project-listing-suggestion.state';

import { CompanyDescriptionLoader } from './company-description-loader';

export const useProjectResultsTableConfig = (
  suggestions: EnhanceProjectListingSuggestion[],
) => {
  const { refetch: refreshCompanies } = useCompanies();

  const { t } = useTranslation('projects');
  const state = useProjectListingSuggestionState();
  const projectId = state.projectId;
  const { createProjectListing } = useCreateProjectListings();
  const { createCompanyFromCrunchbase } = useCreateCompany();

  const hasNoSource = (suggestion: EnhanceProjectListingSuggestion) => {
    return !suggestion.organizationId && !suggestion.crunchbaseId;
  };

  const handleAddCompany = async (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    try {
      if (
        !projectId ||
        hasNoSource(suggestion) ||
        state.isSuggestionAdded(suggestion)
      )
        return;

      state.addSuggestionToInProgress(suggestion);
      if (!suggestion.organizationId && suggestion.crunchbaseId) {
        const result = await createCompanyFromCrunchbase({
          cbId: suggestion.crunchbaseId,
          source: 'Savvy - OpenAI - Enhance',
        });
        suggestion = {
          ...suggestion,
          organizationId: result.id,
        };
      }
      const listing = await createProjectListing(projectId, {
        companyIds: [suggestion.organizationId as string],
        source: 'enhance',
      });
      if (listing[0]?.error) throw new Error(listing[0]?.error);
      state.addSuggestionToAdded(suggestion);
      await refreshCompanies();
    } catch (err) {
      if (err instanceof Error) console.error(err.message);
    } finally {
      state.removeSuggestionFromInProgress(suggestion);
    }
  };

  const handleRejectSuggestion = async (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    try {
      if (!projectId || state.isSuggestionRejected(suggestion)) return;

      state.addSuggestionToInProgress(suggestion);
      await rejectProjectListingSuggestions(projectId, {
        name: suggestion.name,
        permalink: suggestion.permalink,
        website: suggestion.website,
        crunchbaseId: suggestion.crunchbaseId,
        organizationId: suggestion.organizationId,
      });
      state.addSuggestionToRejected(suggestion);
    } catch (err) {
      if (err instanceof Error) console.error(err.message);
    } finally {
      state.removeSuggestionFromInProgress(suggestion);
    }
  };

  const handleUndoRejectSuggestion = async (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    try {
      if (!projectId || !state.isSuggestionRejected(suggestion)) return;

      state.addSuggestionToInProgress(suggestion);
      await undoRejectProjectListingSuggestions(projectId, suggestion.name);
      state.removeSuggestionFromRejected(suggestion);
    } catch (err) {
      if (err instanceof Error) console.error(err.message);
    } finally {
      state.removeSuggestionFromInProgress(suggestion);
    }
  };

  const rows: IRow[] = useMemo<IRow[]>(
    () =>
      suggestions.map((suggestion, index) => {
        const isAdded = state.isSuggestionAdded(suggestion);
        const isRejected = state.isSuggestionRejected(suggestion);
        const isBusy = state.isSuggestionInProgress(suggestion);
        const sayyyPath = suggestion.organizationId
          ? paths.company({
              companyId: suggestion.organizationId,
            })
          : '';
        return {
          id:
            suggestion.organizationId ||
            suggestion.crunchbaseId ||
            suggestion.permalink ||
            suggestion.name,
          rowHover: true,
          rowPadding: {
            left: '20px',
            right: '20px',
          },
          cells: [
            {
              width: '217px',
              padding: '0px',
              value: (
                <CompanyDetailSection>
                  <Inline
                    gap="8px"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Logo
                      name={suggestion.name}
                      logoUrl={suggestion.logoUrl}
                      singleLetter={true}
                      bgColor={
                        suggestion.logoUrl
                          ? 'transparent'
                          : colors.accent.green.c3
                      }
                      border={
                        suggestion.logoUrl
                          ? `2px solid ${colors.gray.c3}`
                          : undefined
                      }
                    />
                    <Inline
                      gap="10px"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <EllipsisTextTooltip
                        text={suggestion.name}
                        Component={<CompanyName />}
                      />
                      {suggestion.organizationId || isAdded ? (
                        <IconButton
                          icon="VectorUp"
                          variant="ghost"
                          color={colors.basics.black}
                          onClick={() => {
                            openNewTab(sayyyPath);
                          }}
                        />
                      ) : (
                        suggestion.website && (
                          <Tooltip content={suggestion.website}>
                            <LinkIcon
                              disableTooltip
                              url={suggestion.website}
                              icon="Website"
                              size="14px"
                            />
                          </Tooltip>
                        )
                      )}
                    </Inline>
                  </Inline>
                </CompanyDetailSection>
              ),
            },
            {
              align: 'left',
              value: (
                <CompanyDescriptionLoader
                  key={suggestion.name}
                  requestId="6aecca94-2820-4656-9706-2160cb778433" // alv todo: this is a placeholder for now while we do not apply history to enhance
                  resultNumber={index}
                  companyName={suggestion.name}
                  companyWebsite={suggestion.website}
                  shortDescription={
                    suggestion.reason || suggestion.shortDescription
                  }
                />
              ),
            },
            {
              align: 'center',
              width: '155px',
              padding: '0px',
              hoverOnly: !isBusy && !(isAdded || isRejected), //this causes an effect that the items do not disappear on certain stages
              value: (
                <Flexbox justify="right" name={`${suggestion.name} Actions`}>
                  {!isBusy && (
                    <Flexbox
                      name="enhance-project-suggestion-actions"
                      gap="11px"
                      alignItems="center"
                    >
                      {isRejected && (
                        <Button
                          data-testid="undo-reject-suggestion-button"
                          key={`undo-reject-suggestion-button-${suggestion.permalink}`}
                          textCenter
                          variant="secondary"
                          startIcon="ArrowGoBack"
                          height={32}
                          width={32}
                          contentPadding="sm"
                          onClick={() => handleUndoRejectSuggestion(suggestion)}
                        />
                      )}
                      {!isAdded && (
                        <RejectButton
                          data-testid="reject-suggestion-button"
                          key={`reject-suggestion-button-${suggestion.permalink}`}
                          textCenter
                          rejected={false}
                          variant="danger"
                          startIcon="Reject"
                          height={32}
                          width={!isRejected ? 32 : undefined}
                          contentPadding="sm"
                          onClick={async () => {
                            if (isRejected || isBusy) return;
                            await handleRejectSuggestion(suggestion);
                          }}
                        >
                          {isRejected && t`addCompany.rejected`}
                        </RejectButton>
                      )}
                      {!isRejected && (
                        <Tooltip
                          disabled={!hasNoSource(suggestion)}
                          content={t`addCompany.suggestionHasNoSource`}
                        >
                          <AddButton
                            data-testid="add-suggestion-button"
                            key={`add-suggestion-button-${suggestion.permalink}`}
                            textCenter
                            added={isAdded}
                            variant="primary"
                            startIcon={isAdded ? 'Check' : 'Plus'}
                            height={32}
                            width={!isAdded ? 32 : undefined}
                            disabled={hasNoSource(suggestion)}
                            contentPadding="sm"
                            onClick={async () => {
                              if (isAdded || isBusy) return;
                              await handleAddCompany(suggestion);
                            }}
                            color={isAdded ? colors.accent.green.c3 : undefined}
                          >
                            {isAdded && t`addCompany.added`}
                          </AddButton>
                        </Tooltip>
                      )}
                    </Flexbox>
                  )}
                  {isBusy && <Loader size="small" />}
                </Flexbox>
              ),
            },
          ],
        };
      }),
    [state.inProgressSuggestions, suggestions, t],
  );

  return { rows };
};

const CompanyDetailSection = styled(Stack)`
  padding: 20px 0px;
`;

const CompanyName = styled(SmallText, forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 130px;
`;

const RejectButton = styled(Button)<{ rejected: boolean }>`
  ${({ rejected }) => rejected && `pointer-events: none;`}
`;

const AddButton = styled(Button)<{ added: boolean }>`
  ${({ added, theme }) =>
    added &&
    `pointer-events: none; background-color: ${theme.colors.accent.green.c1};`}
`;
