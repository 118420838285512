import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface RouteLinkProps extends LinkProps {
  className?: string;
}

export const RouteLink = (props: RouteLinkProps) => {
  return (
    <Link to={props.to} className={props.className}>
      {props.children}
    </Link>
  );
};
